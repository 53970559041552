import  Upload from '@/utils/slice_upload';
import {advertisingList, advertisingInfo,
    operationsOperationsSettings, uploadMaterial,
    mergeSlice, operationProblemDelMaterial,
    operationsGetOpInfo} from '@/utils/apis'

export default {
    name: "MobileMarketingAndOperations",
    props: ['practiceForm', 'editData'],
    data() {
        return {
            //实操题id
            opId: this.$route.query.id || '',
            //投放位置名称下拉列表
            placementLists: [],
            placementValue: '',
            //媒体类型
            mediaType: 1,
            mediaTypeScore: void 0,
            //推广计划类型
            promotionPlanType: 1,
            promotionPlanTypeScore: void 0,
            //出价方式-京东定向推广计划
            biddingType: 1,
            biddingTypeScore: void 0,
            //投放日期-腾讯
            deliveryTime: 1,
            deliveryTimeValue: '',
            deliveryTimeValueScore: void 0,
            //投放日期-头条
            deliveryTime2: 1,
            //每日投放时间
            deliveryTimeType: 1,
            deliveryTimeTypeScore: void 0,
            //特定时间投放列表
            timeList: [
                {
                    day: '星期一',
                    val: 'day1',
                    data: [
                        {
                            val: 'day1',
                            num: 0,
                            is_click: false,
                        },
                        {
                            val: 'day1',
                            num: 1,
                            is_click: false,
                        },
                        {
                            val: 'day1',
                            num: 2,
                            is_click: false,
                        },
                        {
                            val: 'day1',
                            num: 3,
                            is_click: false,
                        },
                        {
                            val: 'day1',
                            num: 4,
                            is_click: false,
                        },
                        {
                            val: 'day1',
                            num: 5,
                            is_click: false,
                        },
                        {
                            val: 'day1',
                            num: 6,
                            is_click: false,
                        },
                        {
                            val: 'day1',
                            num: 7,
                            is_click: false,
                        },
                        {
                            val: 'day1',
                            num: 8,
                            is_click: false,
                        },
                        {
                            val: 'day1',
                            num: 9,
                            is_click: false,
                        },
                        {
                            val: 'day1',
                            num: 10,
                            is_click: false,
                        },
                        {
                            val: 'day1',
                            num: 11,
                            is_click: false,
                        },
                        {
                            val: 'day1',
                            num: 12,
                            is_click: false,
                        },
                        {
                            val: 'day1',
                            num: 13,
                            is_click: false,
                        },
                        {
                            val: 'day1',
                            num: 14,
                            is_click: false,
                        },
                        {
                            val: 'day1',
                            num: 15,
                            is_click: false,
                        },
                        {
                            val: 'day1',
                            num: 16,
                            is_click: false,
                        },
                        {
                            val: 'day1',
                            num: 17,
                            is_click: false,
                        },
                        {
                            val: 'day1',
                            num: 18,
                            is_click: false,
                        },
                        {
                            val: 'day1',
                            num: 19,
                            is_click: false,
                        },
                        {
                            val: 'day1',
                            num: 20,
                            is_click: false,
                        },
                        {
                            val: 'day1',
                            num: 21,
                            is_click: false,
                        },
                        {
                            val: 'day1',
                            num: 22,
                            is_click: false,
                        },
                        {
                            val: 'day1',
                            num: 23,
                            is_click: false,
                        }
                    ]
                },
                {
                    day: '星期二',
                    val: 'day2',
                    data: [
                        {
                            val: 'day2',
                            num: 0,
                            is_click: false,
                        },
                        {
                            val: 'day2',
                            num: 1,
                            is_click: false,
                        },
                        {
                            val: 'day2',
                            num: 2,
                            is_click: false,
                        },
                        {
                            val: 'day2',
                            num: 3,
                            is_click: false,
                        },
                        {
                            val: 'day2',
                            num: 4,
                            is_click: false,
                        },
                        {
                            val: 'day2',
                            num: 5,
                            is_click: false,
                        },
                        {
                            val: 'day2',
                            num: 6,
                            is_click: false,
                        },
                        {
                            val: 'day2',
                            num: 7,
                            is_click: false,
                        },
                        {
                            val: 'day2',
                            num: 8,
                            is_click: false,
                        },
                        {
                            val: 'day2',
                            num: 9,
                            is_click: false,
                        },
                        {
                            val: 'day2',
                            num: 10,
                            is_click: false,
                        },
                        {
                            val: 'day2',
                            num: 11,
                            is_click: false,
                        },
                        {
                            val: 'day2',
                            num: 12,
                            is_click: false,
                        },
                        {
                            val: 'day2',
                            num: 13,
                            is_click: false,
                        },
                        {
                            val: 'day2',
                            num: 14,
                            is_click: false,
                        },
                        {
                            val: 'day2',
                            num: 15,
                            is_click: false,
                        },
                        {
                            val: 'day2',
                            num: 16,
                            is_click: false,
                        },
                        {
                            val: 'day2',
                            num: 17,
                            is_click: false,
                        },
                        {
                            val: 'day2',
                            num: 18,
                            is_click: false,
                        },
                        {
                            val: 'day2',
                            num: 19,
                            is_click: false,
                        },
                        {
                            val: 'day2',
                            num: 20,
                            is_click: false,
                        },
                        {
                            val: 'day2',
                            num: 21,
                            is_click: false,
                        },
                        {
                            val: 'day2',
                            num: 22,
                            is_click: false,
                        },
                        {
                            val: 'day2',
                            num: 23,
                            is_click: false,
                        }
                    ]
                },
                {
                    day: '星期三',
                    val: 'day3',
                    data: [
                        {
                            val: 'day3',
                            num: 0,
                            is_click: false,
                        },
                        {
                            val: 'day3',
                            num: 1,
                            is_click: false,
                        },
                        {
                            val: 'day3',
                            num: 2,
                            is_click: false,
                        },
                        {
                            val: 'day3',
                            num: 3,
                            is_click: false,
                        },
                        {
                            val: 'day3',
                            num: 4,
                            is_click: false,
                        },
                        {
                            val: 'day3',
                            num: 5,
                            is_click: false,
                        },
                        {
                            val: 'day3',
                            num: 6,
                            is_click: false,
                        },
                        {
                            val: 'day3',
                            num: 7,
                            is_click: false,
                        },
                        {
                            val: 'day3',
                            num: 8,
                            is_click: false,
                        },
                        {
                            val: 'day3',
                            num: 9,
                            is_click: false,
                        },
                        {
                            val: 'day3',
                            num: 10,
                            is_click: false,
                        },
                        {
                            val: 'day3',
                            num: 11,
                            is_click: false,
                        },
                        {
                            val: 'day3',
                            num: 12,
                            is_click: false,
                        },
                        {
                            val: 'day3',
                            num: 13,
                            is_click: false,
                        },
                        {
                            val: 'day3',
                            num: 14,
                            is_click: false,
                        },
                        {
                            val: 'day3',
                            num: 15,
                            is_click: false,
                        },
                        {
                            val: 'day3',
                            num: 16,
                            is_click: false,
                        },
                        {
                            val: 'day3',
                            num: 17,
                            is_click: false,
                        },
                        {
                            val: 'day3',
                            num: 18,
                            is_click: false,
                        },
                        {
                            val: 'day3',
                            num: 19,
                            is_click: false,
                        },
                        {
                            val: 'day3',
                            num: 20,
                            is_click: false,
                        },
                        {
                            val: 'day3',
                            num: 21,
                            is_click: false,
                        },
                        {
                            val: 'day3',
                            num: 22,
                            is_click: false,
                        },
                        {
                            val: 'day3',
                            num: 23,
                            is_click: false,
                        }
                    ]
                },
                {
                    day: '星期四',
                    val: 'day4',
                    data: [
                        {
                            val: 'day4',
                            num: 0,
                            is_click: false,
                        },
                        {
                            val: 'day4',
                            num: 1,
                            is_click: false,
                        },
                        {
                            val: 'day4',
                            num: 2,
                            is_click: false,
                        },
                        {
                            val: 'day4',
                            num: 3,
                            is_click: false,
                        },
                        {
                            val: 'day4',
                            num: 4,
                            is_click: false,
                        },
                        {
                            val: 'day4',
                            num: 5,
                            is_click: false,
                        },
                        {
                            val: 'day4',
                            num: 6,
                            is_click: false,
                        },
                        {
                            val: 'day4',
                            num: 7,
                            is_click: false,
                        },
                        {
                            val: 'day4',
                            num: 8,
                            is_click: false,
                        },
                        {
                            val: 'day4',
                            num: 9,
                            is_click: false,
                        },
                        {
                            val: 'day4',
                            num: 10,
                            is_click: false,
                        },
                        {
                            val: 'day4',
                            num: 11,
                            is_click: false,
                        },
                        {
                            val: 'day4',
                            num: 12,
                            is_click: false,
                        },
                        {
                            val: 'day4',
                            num: 13,
                            is_click: false,
                        },
                        {
                            val: 'day4',
                            num: 14,
                            is_click: false,
                        },
                        {
                            val: 'day4',
                            num: 15,
                            is_click: false,
                        },
                        {
                            val: 'day4',
                            num: 16,
                            is_click: false,
                        },
                        {
                            val: 'day4',
                            num: 17,
                            is_click: false,
                        },
                        {
                            val: 'day4',
                            num: 18,
                            is_click: false,
                        },
                        {
                            val: 'day4',
                            num: 19,
                            is_click: false,
                        },
                        {
                            val: 'day4',
                            num: 20,
                            is_click: false,
                        },
                        {
                            val: 'day4',
                            num: 21,
                            is_click: false,
                        },
                        {
                            val: 'day4',
                            num: 22,
                            is_click: false,
                        },
                        {
                            val: 'day4',
                            num: 23,
                            is_click: false,
                        }
                    ]
                },
                {
                    day: '星期五',
                    val: 'day5',
                    data: [
                        {
                            val: 'day5',
                            num: 0,
                            is_click: false,
                        },
                        {
                            val: 'day5',
                            num: 1,
                            is_click: false,
                        },
                        {
                            val: 'day5',
                            num: 2,
                            is_click: false,
                        },
                        {
                            val: 'day5',
                            num: 3,
                            is_click: false,
                        },
                        {
                            val: 'day5',
                            num: 4,
                            is_click: false,
                        },
                        {
                            val: 'day5',
                            num: 5,
                            is_click: false,
                        },
                        {
                            val: 'day5',
                            num: 6,
                            is_click: false,
                        },
                        {
                            val: 'day5',
                            num: 7,
                            is_click: false,
                        },
                        {
                            val: 'day5',
                            num: 8,
                            is_click: false,
                        },
                        {
                            val: 'day5',
                            num: 9,
                            is_click: false,
                        },
                        {
                            val: 'day5',
                            num: 10,
                            is_click: false,
                        },
                        {
                            val: 'day5',
                            num: 11,
                            is_click: false,
                        },
                        {
                            val: 'day5',
                            num: 12,
                            is_click: false,
                        },
                        {
                            val: 'day5',
                            num: 13,
                            is_click: false,
                        },
                        {
                            val: 'day5',
                            num: 14,
                            is_click: false,
                        },
                        {
                            val: 'day5',
                            num: 15,
                            is_click: false,
                        },
                        {
                            val: 'day5',
                            num: 16,
                            is_click: false,
                        },
                        {
                            val: 'day5',
                            num: 17,
                            is_click: false,
                        },
                        {
                            val: 'day5',
                            num: 18,
                            is_click: false,
                        },
                        {
                            val: 'day5',
                            num: 19,
                            is_click: false,
                        },
                        {
                            val: 'day5',
                            num: 20,
                            is_click: false,
                        },
                        {
                            val: 'day5',
                            num: 21,
                            is_click: false,
                        },
                        {
                            val: 'day5',
                            num: 22,
                            is_click: false,
                        },
                        {
                            val: 'day5',
                            num: 23,
                            is_click: false,
                        }
                    ]
                },
                {
                    day: '星期六',
                    val: 'day6',
                    data: [
                        {
                            val: 'day6',
                            num: 0,
                            is_click: false,
                        },
                        {
                            val: 'day6',
                            num: 1,
                            is_click: false,
                        },
                        {
                            val: 'day6',
                            num: 2,
                            is_click: false,
                        },
                        {
                            val: 'day6',
                            num: 3,
                            is_click: false,
                        },
                        {
                            val: 'day6',
                            num: 4,
                            is_click: false,
                        },
                        {
                            val: 'day6',
                            num: 5,
                            is_click: false,
                        },
                        {
                            val: 'day6',
                            num: 6,
                            is_click: false,
                        },
                        {
                            val: 'day6',
                            num: 7,
                            is_click: false,
                        },
                        {
                            val: 'day6',
                            num: 8,
                            is_click: false,
                        },
                        {
                            val: 'day6',
                            num: 9,
                            is_click: false,
                        },
                        {
                            val: 'day6',
                            num: 10,
                            is_click: false,
                        },
                        {
                            val: 'day6',
                            num: 11,
                            is_click: false,
                        },
                        {
                            val: 'day6',
                            num: 12,
                            is_click: false,
                        },
                        {
                            val: 'day6',
                            num: 13,
                            is_click: false,
                        },
                        {
                            val: 'day6',
                            num: 14,
                            is_click: false,
                        },
                        {
                            val: 'day6',
                            num: 15,
                            is_click: false,
                        },
                        {
                            val: 'day6',
                            num: 16,
                            is_click: false,
                        },
                        {
                            val: 'day6',
                            num: 17,
                            is_click: false,
                        },
                        {
                            val: 'day6',
                            num: 18,
                            is_click: false,
                        },
                        {
                            val: 'day6',
                            num: 19,
                            is_click: false,
                        },
                        {
                            val: 'day6',
                            num: 20,
                            is_click: false,
                        },
                        {
                            val: 'day6',
                            num: 21,
                            is_click: false,
                        },
                        {
                            val: 'day6',
                            num: 22,
                            is_click: false,
                        },
                        {
                            val: 'day6',
                            num: 23,
                            is_click: false,
                        }
                    ]
                },
                {
                    day: '星期日',
                    val: 'day7',
                    data: [
                        {
                            val: 'day7',
                            num: 0,
                            is_click: false,
                        },
                        {
                            val: 'day7',
                            num: 1,
                            is_click: false,
                        },
                        {
                            val: 'day7',
                            num: 2,
                            is_click: false,
                        },
                        {
                            val: 'day7',
                            num: 3,
                            is_click: false,
                        },
                        {
                            val: 'day7',
                            num: 4,
                            is_click: false,
                        },
                        {
                            val: 'day7',
                            num: 5,
                            is_click: false,
                        },
                        {
                            val: 'day7',
                            num: 6,
                            is_click: false,
                        },
                        {
                            val: 'day7',
                            num: 7,
                            is_click: false,
                        },
                        {
                            val: 'day7',
                            num: 8,
                            is_click: false,
                        },
                        {
                            val: 'day7',
                            num: 9,
                            is_click: false,
                        },
                        {
                            val: 'day7',
                            num: 10,
                            is_click: false,
                        },
                        {
                            val: 'day7',
                            num: 11,
                            is_click: false,
                        },
                        {
                            val: 'day7',
                            num: 12,
                            is_click: false,
                        },
                        {
                            val: 'day7',
                            num: 13,
                            is_click: false,
                        },
                        {
                            val: 'day7',
                            num: 14,
                            is_click: false,
                        },
                        {
                            val: 'day7',
                            num: 15,
                            is_click: false,
                        },
                        {
                            val: 'day7',
                            num: 16,
                            is_click: false,
                        },
                        {
                            val: 'day7',
                            num: 17,
                            is_click: false,
                        },
                        {
                            val: 'day7',
                            num: 18,
                            is_click: false,
                        },
                        {
                            val: 'day7',
                            num: 19,
                            is_click: false,
                        },
                        {
                            val: 'day7',
                            num: 20,
                            is_click: false,
                        },
                        {
                            val: 'day7',
                            num: 21,
                            is_click: false,
                        },
                        {
                            val: 'day7',
                            num: 22,
                            is_click: false,
                        },
                        {
                            val: 'day7',
                            num: 23,
                            is_click: false,
                        }
                    ]
                }
            ],
            //选择的特定投放时间段
            put_time_obj: {
                day1: [],
                day2: [],
                day3: [],
                day4: [],
                day5: [],
                day6: [],
                day7: []
            },
            //每日限额
            dailyLimitType: 1,
            dailyLimitTypeScore: void 0,
            //设置每日限额
            quota: void 0,
            //高级规则
            advancedRules: 1,
            advancedRulesScore: void 0,
            //推广计划名称
            promotionPlanName: '',
            promotionPlanNameScore: void 0,
            //投放位置
            placementScore: void 0,
            placementTableList: [],
            //全部投放位置列表
            allPlacementTableList: [],
            templateSelection: {},
            flagValue: null,
            //定向名称
            orientationTitle: '',
            orientationTitleScore: void 0,
            //选择区域
            areaTypeScore: void 0,
            //区域列表
            areaLists: ["A  安徽", "H  河南", "N  内蒙古", "X  新疆", "B  北京", "H  海南", "N  宁夏", "X  西藏", "C  重庆", "H  湖北", "Q 青海", "Y  云南", "F  福建", "H  湖南", "S  上海", "Z  浙江", "G  广东", "H  黑龙江", "S  四川", "G  广西", "J  吉林", "S  山东", "G  甘肃", "J  江苏", "S  山西", "G  贵州", "J  江西", "S  陕西", "H  河北", "L  辽宁", "T  天津"],
            checkAreaList: [],
            checkAll: false,
            isIndeterminate: true,
            //点击出价
            clickToBid: void 0,
            clickToBidScore: void 0,
            //广告名称
            adTitle: '',
            adTitleScore: void 0,
            //广告备注
            adRemarks: '',
            adRemarksScore: void 0,
            //创意-媒体类型
            creativityMediaType: 1,
            creativityMediaTypeScore: void 0,
            //推广链接
            sponsoredLinks: '',
            sponsoredLinksScore: void 0,
            //主推SKUID
            sponsoredSKUID: '',
            sponsoredSKUIDScore: void 0,
            //素材标题
            materialTitle: '',
            materialTitleScore: void 0,
            //素材上传
            materialUploadScore: void 0,
            //上传素材
            material_file: [],
            //素材列表
            materialList: [],
            //分片长传
            uploadInstance: null,
            //素材进度条
            percent: 0,
        }
    },
    created() {
        this.getPlacementLists();
        if (this.opId) {
            this.getOperationsGetOpInfo();
        }
    },
    methods: {
        //获取详情
        getOperationsGetOpInfo() {
            let param = {
                op_id: this.opId
            }
            operationsGetOpInfo(param).then(res => {
                //媒体类型
                this.mediaType = res.data.plan.media_type;
                this.mediaTypeScore = res.data.plan.media_type_score;
                //推广计划类型
                this.promotionPlanType = res.data.plan.promote_type;
                this.promotionPlanTypeScore = res.data.plan.promote_type_score;
                //出价方式
                this.biddingType = res.data.plan.offer_type;
                this.biddingTypeScore = res.data.plan.offer_type_score;
                //投放日期
                this.deliveryTimeValueScore = res.data.plan.put_date_score;
                if ((this.mediaType === 1 && this.promotionPlanType === 1)) {
                    this.deliveryTime = 1;
                    this.deliveryTimeValue = res.data.plan.put_date.split('#');
                } else {
                    if (res.data.plan.put_date === 'all') {
                        this.deliveryTime2 = 1;
                    } else {
                        this.deliveryTime2 = 2;
                        this.deliveryTimeValue = res.data.plan.put_date.split('#');
                    }
                }
                this.deliveryTimeValueScore = res.data.plan.put_date_score;
                //每日投放时间
                if (res.data.plan.put_time === 'all') {
                    this.deliveryTimeType = 1;
                } else {
                    this.deliveryTimeType = 2;
                    if (res.data.plan.put_time) {
                        let arr = JSON.parse(res.data.plan.put_time);
                        let day1 = this.timeList[0];
                        let day2 = this.timeList[1];
                        let day3 = this.timeList[2];
                        let day4 = this.timeList[3];
                        let day5 = this.timeList[4];
                        let day6 = this.timeList[5];
                        let day7 = this.timeList[6];
                        arr.day1.forEach(select_item => {
                            day1.data.forEach(item => {
                                if (select_item === item.num) {
                                    item.is_click = true;
                                }
                            })
                        });
                        arr.day2.forEach(select_item => {
                            day2.data.forEach(item => {
                                if (select_item === item.num) {
                                    item.is_click = true;
                                }
                            })
                        });
                        arr.day3.forEach(select_item => {
                            day3.data.forEach(item => {
                                if (select_item === item.num) {
                                    item.is_click = true;
                                }
                            })
                        });
                        arr.day4.forEach(select_item => {
                            day4.data.forEach(item => {
                                if (select_item === item.num) {
                                    item.is_click = true;
                                }
                            })
                        });
                        arr.day5.forEach(select_item => {
                            day5.data.forEach(item => {
                                if (select_item === item.num) {
                                    item.is_click = true;
                                }
                            })
                        });
                        arr.day6.forEach(select_item => {
                            day6.data.forEach(item => {
                                if (select_item === item.num) {
                                    item.is_click = true;
                                }
                            })
                        });
                        arr.day7.forEach(select_item => {
                            day7.data.forEach(item => {
                                if (select_item === item.num) {
                                    item.is_click = true;
                                }
                            })
                        });
                    }
                }
                this.deliveryTimeTypeScore = res.data.plan.put_time_score;
                //每日限额
                if ((this.mediaType === 1 && this.promotionPlanType !== 3) ||
                    (this.mediaType === 2 && this.promotionPlanType === 1) ||
                    (this.mediaType === 3 && this.promotionPlanType === 3) ||
                    (this.mediaType === 4 && this.promotionPlanType === 1)) {
                    if (res.data.plan.limit === '0') {
                        this.dailyLimitType = 1;
                    } else {
                        this.dailyLimitType = 2;
                        this.quota = res.data.plan.limit;
                    }
                }
                if ((this.mediaType === 2 && this.promotionPlanType === 2) ||
                    (this.mediaType === 3 && this.promotionPlanType === 1) ||
                    (this.mediaType === 3 && this.promotionPlanType === 2) ||
                    (this.mediaType === 4 && this.promotionPlanType === 2)) {
                    if (res.data.plan.limit !== '0') {
                        this.dailyLimitType = 1;
                        this.quota = res.data.plan.limit;
                    }
                }
                this.dailyLimitTypeScore = res.data.plan.limit_score;
                //高级规则
                this.advancedRules = res.data.plan.advanced_rules;
                this.advancedRulesScore = res.data.plan.advanced_rules_score;
                //推广计划名称
                this.promotionPlanName = res.data.plan.plan_name;
                this.promotionPlanNameScore = res.data.plan.plan_name_score;
                //投放位置
                this.placementScore = res.data.directional.put_position_score;
                if (res.data.directional.advertising_id) {
                    this.placementValue = res.data.directional.advertising_id;
                    this.getPlacementDetails(this.placementValue);
                    this.templateSelection = JSON.parse(res.data.directional.put_position);
                    if (this.mediaType === 1) {
                        this.flagValue = JSON.parse(res.data.directional.put_position).id;
                    }
                    if (this.mediaType === 2) {
                        this.flagValue = (JSON.parse(res.data.directional.put_position).id) - 15;
                    }
                    if (this.mediaType === 3) {
                        this.flagValue = (JSON.parse(res.data.directional.put_position).id) - 46;
                    }
                    if (this.mediaType === 4) {
                        this.flagValue = (JSON.parse(res.data.directional.put_position).id) - 61;
                    }
                }
                //定向名称
                this.orientationTitle = res.data.directional.directional_name;
                this.orientationTitleScore = res.data.directional.directional_name_score;
                //区域
                if (res.data.directional.area === 'all') {
                    this.checkAll = true;
                    this.isIndeterminate = false;
                    this.checkAreaList = this.areaLists;
                } else {
                    this.checkAreaList = res.data.directional.area.split(',');
                }
                this.areaTypeScore = res.data.directional.area_score;
                //点击出价
                this.clickToBid = res.data.directional.click_unit_price;
                this.clickToBidScore = res.data.directional.click_unit_price_score;
                //广告名称
                this.adTitle = res.data.directional.advertising_name;
                this.adTitleScore = res.data.directional.advertising_name_score;
                //广告备注
                this.adRemarks = res.data.directional.mark;
                this.adRemarksScore = res.data.directional.mark_score;
                //创意-媒体类型
                this.creativityMediaType = res.data.creative.media_type;
                this.creativityMediaTypeScore = res.data.creative.media_type_score;
                //推广链接
                this.sponsoredLinks = res.data.creative.promote_link;
                this.sponsoredLinksScore = res.data.creative.promote_link_score;
                //主推SKUID
                this.sponsoredSKUID = res.data.creative.sku_id;
                this.sponsoredSKUIDScore = res.data.creative.sku_id_score;
                //素材标题
                this.materialTitle = res.data.creative.material_title;
                this.materialTitleScore = res.data.creative.material_title_score;
                //素材上传
                this.materialUploadScore = res.data.creative.material_score;
                //上传素材
                this.materialList = res.data.creative.material;
                this.material_file = res.data.creative.material;
                this.percent = 100;
            }).catch(err => {
                console.log(err);
            })
        },
        //获取投放位置列表
        getPlacementLists() {
            let param = {
                status: "0"
            }
            advertisingList(param).then(res => {
                this.placementLists = res.data;
            }).catch(err => {
                console.log(err);
            })
        },
        //选中的投放位置名称
        selectPlacement(val) {
            this.getPlacementDetails(val);
        },
        //获取投放位置详情
        getPlacementDetails(id) {
            let param = {
                id: id
            }
            advertisingInfo(param).then(res => {
                this.placementTableList = [];
                this.allPlacementTableList = JSON.parse(res.data.advertising_data);
                this.filterPlacement();
            }).catch(err => {
                console.log(err);
            })
        },
        //选中的媒体类型--创建计划
        selectMediaType(val) {
            this.mediaType = val;
            this.promotionPlanType = 1;
            this.deliveryTimeValue = '';
            this.biddingType = 1;
            this.deliveryTime = 1;
            this.deliveryTime2 = 1;
            this.dailyLimitType = 1;
            this.quota = void 0;
            this.placementTableList = [];
            this.flagValue = null;
            this.deliveryTimeType = 1;
            this.advancedRules = 1;
            this.timeList.forEach(item => {
                item.data.forEach(dataItem => {
                    dataItem.is_click = false;
                })})
            this.filterPlacement();
        },
        //选中的推广计划类型
        selectPromotionPlan(val) {
            this.dailyLimitType = 1;
            this.quota = void 0;
            this.deliveryTimeValue = '';
            this.biddingType = 1;
            this.deliveryTime = 1;
            this.deliveryTime2 = 1;
            this.deliveryTimeType = 1;
            this.advancedRules = 1;
            this.timeList.forEach(item => {
                item.data.forEach(dataItem => {
                    dataItem.is_click = false;
                })})
        },
        //筛选投放位置列表
        filterPlacement() {
            this.allPlacementTableList.forEach(item => {
                if (this.mediaType === item.type) {
                    this.placementTableList.push(item);
                }
            })
        },
        //全选
        handleCheckAllChange(val) {
            this.checkAreaList = val ? this.areaLists : [];
            this.isIndeterminate = false;
        },
        //选择区域
        handleCheckedAreaChange(value) {
            let checkedCount = value.length;
            this.checkAll = checkedCount === this.areaLists.length;
            this.isIndeterminate = checkedCount > 0 && checkedCount < this.areaLists.length;
        },
        getTemplateRow(index,row){
            //获取选中数据
            this.templateSelection = row;
        },
        //上传素材
        changeFile(event) {
            if (event.target.files[0].size > 1024 * 1024 * 1024) {
                this.$message.warning('文件超过1G');
                return;
            } else {
                let chunkSize = 1024 * 1024 * 8;
                let file = event.target.files[0];
                this.uploadInstance = new Upload(file, chunkSize, uploadMaterial);
                let obj = {
                    name: file.name,
                    url: ''
                }
                this.materialList = []
                this.material_file = [];
                this.materialList.push(obj);
                this.material_file.push(obj);
                let index = 0;
                this.uploadSlice(index);
                this.percent = 0
            }
        },
        uploadSlice(index) {
            let num = this.uploadInstance.total_block_num;
            if (index === num && num !== 1) {
                //合并分片
                mergeSlice({
                    flag: this.uploadInstance.file_tmp,
                    slice_size: this.uploadInstance.block_size
                }).then(res => {
                    if (res.code === 200) {
                        this.percent = 100;
                        this.material_file[0].url = res.data.src
                        this.materialList[0].url = res.data.src
                        this.$refs.uploadFile.value = null
                        this.$message.success(res.msg);
                        console.log(res,'998')
                    }
                });
                return;
            }
            this.uploadInstance.uploadSlice(index).then(res => {
                if (res.code === 200) {
                    if (res.data.is_slice) { //is_slice 为true则为分片上传，后面需要合并分片,若为false则无须分片，或者文件大小<分片尺寸，直接上传就行
                        this.percent = Math.ceil((index / num) * 100);
                        this.uploadSlice(++index);
                    } else {
                        this.percent = 100;
                        this.material_file[0].url = res.data.src
                        this.materialList[0].url = res.data.src
                        this.$refs.uploadFile.value = null
                        this.$message.success(res.msg);
                        console.log(res,'999')
                    }
                }
            });
        },
        //删除素材
        delMaterial(item, index) {
            this.$confirm('是否删除', '提示消息', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                customClass: 'green-message-box',
                type: 'warning'
            }).then(() => {
                let param = {
                    file_url: item.url
                }
                operationProblemDelMaterial(param).then((res) => {
                    if (res.code == 200) {
                        this.$message.success(res.msg);
                        this.material_file.splice(index, 1);
                        this.materialList.splice(index, 1);
                    }
                }).catch((err) => {
                    console.log(err);
                })
            }).catch(() => {
                this.$message.error('已取消删除');
            })
        },
        //保存
        savePlacementTemp() {
            if (!this.practiceForm.name || this.practiceForm.name === '') {
                this.$message.warning('请填写名称！');
                return;
            }
            if (this.practiceForm.name.length < 3 || this.practiceForm.name.length > 50) {
                this.$message.warning('名称必须大于3个字符且小于50个字符！');
                return;
            }
            if (this.practiceForm.classValue.length === 0) {
                this.$message.warning('请选择分类！');
                return;
            }
            if (this.practiceForm.taskList.length === 0) {
                this.$message.warning('请添加任务目标！');
                return;
            }
            if (!this.practiceForm.taskBackground) {
                this.$message.warning('请填写操作要求！');
                return;
            }
            if (!this.practiceForm.taskAnalysis) {
                this.$message.warning('请填写任务分析！');
                return;
            }
            if (!this.practiceForm.taskOperation) {
                this.$message.warning('请填写任务操作！');
                return;
            }
            if (this.practiceForm.scoreList.length === 0) {
                this.$message.warning('请添加评分标准！');
                return;
            }
            if (!this.placementValue) {
                this.$message.warning('请选择投放位置名称！');
                return;
            }
            if (!this.mediaTypeScore) {
                this.$message.warning('请填写媒体类型的分值！');
                return;
            }
            if (!this.promotionPlanTypeScore) {
                this.$message.warning('请填写推广计划类型的分值！');
                return;
            }
            if ((this.mediaType === 1 && this.promotionPlanType === 1) ||
                (this.mediaType === 2 && this.promotionPlanType === 1) ||
                (this.mediaType === 2 && this.promotionPlanType === 2) ||
                (this.mediaType === 3 && this.promotionPlanType === 1) ||
                (this.mediaType === 3 && this.promotionPlanType === 2) ||
                (this.mediaType === 3 && this.promotionPlanType === 3) ||
                (this.mediaType === 4 && this.promotionPlanType === 1) ||
                (this.mediaType === 4 && this.promotionPlanType === 2) ) {
                if (!this.biddingTypeScore) {
                    this.$message.warning('请填写出价方式的分值！');
                    return;
                }

                if (!this.deliveryTimeValueScore) {
                    this.$message.warning('请填写投放日期的分值！');
                    return;
                }
                if (!this.deliveryTimeTypeScore) {
                    this.$message.warning('请填写每日投放时间的分值！');
                    return;
                }
            }
            if ((this.mediaType === 2 && this.promotionPlanType === 1 && this.deliveryTime2 === 2) ||
                (this.mediaType === 2 && this.promotionPlanType === 2 && this.deliveryTime2 === 2) ||
                (this.mediaType === 3 && this.promotionPlanType === 1 && this.deliveryTime2 === 2) ||
                (this.mediaType === 3 && this.promotionPlanType === 2 && this.deliveryTime2 === 2) ||
                (this.mediaType === 3 && this.promotionPlanType === 3 && this.deliveryTime2 === 2) ||
                (this.mediaType === 4 && this.promotionPlanType === 1 && this.deliveryTime2 === 2) ||
                (this.mediaType === 4 && this.promotionPlanType === 2 && this.deliveryTime2 === 2) ||
                (this.mediaType === 1 && this.promotionPlanType === 1 && this.deliveryTime === 1)) {
                if (!this.deliveryTimeValue) {
                    this.$message.warning('请填写投放日期！');
                    return;
                }
            }
            if ((this.mediaType === 1 && this.promotionPlanType !== 3) ||
                (this.mediaType === 2 && this.promotionPlanType === 1) ||
                (this.mediaType === 2 && this.promotionPlanType === 2) ||
                (this.mediaType === 3 && this.promotionPlanType === 1) ||
                (this.mediaType === 3 && this.promotionPlanType === 2) ||
                (this.mediaType === 3 && this.promotionPlanType === 3) ||
                (this.mediaType === 4 && this.promotionPlanType === 1) ||
                (this.mediaType === 4 && this.promotionPlanType === 2)) {
                if (!this.dailyLimitTypeScore) {
                    this.$message.warning('请填写每日限额的分值！');
                    return;
                }
            }
            if (!this.promotionPlanName) {
                this.$message.warning('请填写推广计划名称！');
                return;
            }
            if (!this.promotionPlanNameScore) {
                this.$message.warning('请填写推广计划名称的分值！');
                return;
            }
            if (!this.placementScore) {
                this.$message.warning('请填写投放位置的分值！');
                return;
            }
            if (!this.orientationTitle) {
                this.$message.warning('请填写定向名称！');
                return;
            }
            if (!this.orientationTitleScore) {
                this.$message.warning('请填写定向名称的分值！');
                return;
            }
            if (!this.areaTypeScore) {
                this.$message.warning('请填写区域的分值！');
                return;
            }
            if (!this.clickToBid) {
                this.$message.warning('请填写点击出价！');
                return;
            }
            if (!this.clickToBidScore) {
                this.$message.warning('请填写点击出价的分值！');
                return;
            }
            if (!this.adTitle) {
                this.$message.warning('请填写广告名称！');
                return;
            }
            if (!this.adTitleScore) {
                this.$message.warning('请填写广告名称的分值！');
                return;
            }
            if (!this.adRemarks) {
                this.$message.warning('请填写广告备注！');
                return;
            }
            if (!this.creativityMediaTypeScore) {
                this.$message.warning('请填写创建创意-媒体类型的分值！');
                return;
            }
            if (!this.sponsoredLinks) {
                this.$message.warning('请填写推广链接！');
                return;
            } else {
                let strRegex= '(https?|ftp|file)://[-A-Za-z0-9+&@#/%?=~_|!:,.;]+[-A-Za-z0-9+&@#/%=~_|]';
                let re = new RegExp(strRegex)
                if (!re.test(this.sponsoredLinks)) {
                    this.$message.warning('首页链接不是以http://https://开头，或者不是网址！');
                    return;
                }
            }
            if (!this.sponsoredLinksScore) {
                this.$message.warning('请填写推广链接的分值！');
                return;
            }
            if (!this.sponsoredSKUID) {
                this.$message.warning('请填写主推SKUID！');
                return;
            }
            if (!this.sponsoredSKUIDScore) {
                this.$message.warning('请填写主推SKUID的分值！');
                return;
            }
            if (!this.materialTitle) {
                this.$message.warning('请填写素材标题！');
                return;
            }
            if (!this.materialTitleScore) {
                this.$message.warning('请填写素材标题的分值！');
                return;
            }
            if (!this.materialUploadScore) {
                this.$message.warning('请填写素材上传的分值！');
                return;
            }
            let param = {
                op_name: this.practiceForm.name,
                exam_module_id: this.practiceForm.module_level_three_value,
                category_id: this.practiceForm.classValue[this.practiceForm.classValue.length - 1],
                assess_time: this.practiceForm.time,
                task_goal: this.practiceForm.taskList,
                task_background: this.practiceForm.taskBackground,
                task_analysis: this.practiceForm.taskAnalysis,
                task_operation: this.practiceForm.taskOperation,
                scoring_criteria: this.practiceForm.scoreList,
                op_creative: {
                    media_type: this.creativityMediaType,
                    media_type_score: this.creativityMediaTypeScore,
                    promote_link: this.sponsoredLinks,
                    promote_link_score: this.sponsoredLinksScore,
                    sku_id: this.sponsoredSKUID,
                    sku_id_score: this.sponsoredSKUIDScore,
                    material_title: this.materialTitle,
                    material_title_score: this.materialTitleScore,
                    material_score: this.materialUploadScore,
                },
                op_plan: {
                    media_type: this.mediaType,
                    media_type_score: this.mediaTypeScore,
                    promote_type: this.promotionPlanType,
                    promote_type_score: this.promotionPlanTypeScore,
                    offer_type: this.biddingType,
                    offer_type_score: this.biddingTypeScore,
                    put_date_score: this.deliveryTimeValueScore,
                    put_time_score: this.deliveryTimeTypeScore,
                    limit_score: this.dailyLimitTypeScore,
                    plan_name: this.promotionPlanName,
                    plan_name_score: this.promotionPlanNameScore,
                },
                op_directional: {
                    advertising_id: this.placementValue,
                    put_position_score: this.placementScore,
                    directional_name: this.orientationTitle,
                    directional_name_score: this.orientationTitleScore,
                    area_score: this.areaTypeScore,
                    click_unit_price: this.clickToBid,
                    click_unit_price_score: this.clickToBidScore,
                    advertising_name: this.adTitle,
                    advertising_name_score: this.adTitleScore,
                }
            }
            if (this.mediaType === 1 && this.promotionPlanType === 1 && this.deliveryTime === 1) {
                param.op_plan.put_date = `${this.deliveryTimeValue[0]}#${this.deliveryTimeValue[1]}`;
            }
            if ((this.mediaType === 2 && this.promotionPlanType === 1 && this.deliveryTime2 === 1) ||
                (this.mediaType === 2 && this.promotionPlanType === 2 && this.deliveryTime2 === 1) ||
                (this.mediaType === 3 && this.promotionPlanType === 1 && this.deliveryTime2 === 1) ||
                (this.mediaType === 3 && this.promotionPlanType === 2 && this.deliveryTime2 === 1) ||
                (this.mediaType === 3 && this.promotionPlanType === 3 && this.deliveryTime2 === 1) ||
                (this.mediaType === 4 && this.promotionPlanType === 1 && this.deliveryTime2 === 1) ||
                (this.mediaType === 4 && this.promotionPlanType === 2 && this.deliveryTime2 === 1)) {
                param.op_plan.put_date = 'all';
            }
            if ((this.mediaType === 2 && this.promotionPlanType === 1 && this.deliveryTime2 === 2) ||
                (this.mediaType === 2 && this.promotionPlanType === 2 && this.deliveryTime2 === 2) ||
                (this.mediaType === 3 && this.promotionPlanType === 1 && this.deliveryTime2 === 2) ||
                (this.mediaType === 3 && this.promotionPlanType === 2 && this.deliveryTime2 === 2) ||
                (this.mediaType === 3 && this.promotionPlanType === 3 && this.deliveryTime2 === 2) ||
                (this.mediaType === 4 && this.promotionPlanType === 1 && this.deliveryTime2 === 2) ||
                (this.mediaType === 4 && this.promotionPlanType === 2 && this.deliveryTime2 === 2)) {
                param.op_plan.put_date = `${this.deliveryTimeValue[0]}#${this.deliveryTimeValue[1]}`;
            }
            if (this.templateSelection.click_rate) {
                param.op_directional.put_position = JSON.stringify(this.templateSelection);
            } else {
                this.$message.warning('请选择投放位置！');
                return
            }
            if (!this.flagValue) {
                this.$message.warning('请选择投放位置！');
                return
            }
            if (this.material_file.length > 0) {
                param.op_creative.material = this.material_file;
                param.material_file = this.material_file
            } else {
                this.$message.warning('请上传素材！');
                return
            }
            if ((this.mediaType === 1 && this.promotionPlanType === 2) ||
                (this.mediaType === 1 && this.promotionPlanType === 4) ||
                (this.mediaType === 2 && this.promotionPlanType === 2)) {
                if (!this.advancedRulesScore) {
                    this.$message.warning('请填写高级规则的分值！');
                    return;
                } else {
                    param.op_plan.advanced_rules = this.advancedRules;
                    param.op_plan.advanced_rules_score = this.advancedRulesScore;
                }
            }
            if ((this.mediaType === 1 && this.promotionPlanType === 1) ||
                (this.mediaType === 2 && this.promotionPlanType === 1) ||
                (this.mediaType === 2 && this.promotionPlanType === 2) ||
                (this.mediaType === 3 && this.promotionPlanType === 1) ||
                (this.mediaType === 3 && this.promotionPlanType === 2) ||
                (this.mediaType === 3 && this.promotionPlanType === 3) ||
                (this.mediaType === 4 && this.promotionPlanType === 1) ||
                (this.mediaType === 4 && this.promotionPlanType === 2)) {
                if (this.deliveryTimeType === 1) {
                    param.op_plan.put_time = 'all';
                } else {
                    this.timeList.forEach(item => {
                        item.data.forEach(dataItem => {
                            if (dataItem.is_click === true) {
                                if (dataItem.val === 'day1') {
                                    this.put_time_obj.day1.push(dataItem.num);
                                }
                                if (dataItem.val === 'day2') {
                                    this.put_time_obj.day2.push(dataItem.num);
                                }
                                if (dataItem.val === 'day3') {
                                    this.put_time_obj.day3.push(dataItem.num);
                                }
                                if (dataItem.val === 'day4') {
                                    this.put_time_obj.day4.push(dataItem.num);
                                }
                                if (dataItem.val === 'day5') {
                                    this.put_time_obj.day5.push(dataItem.num);
                                }
                                if (dataItem.val === 'day6') {
                                    this.put_time_obj.day6.push(dataItem.num);
                                }
                                if (dataItem.val === 'day7') {
                                    this.put_time_obj.day7.push(dataItem.num);
                                }
                            }
                        })
                    })
                    if (this.put_time_obj.day1.length === 0 &&
                        this.put_time_obj.day2.length === 0 &&
                        this.put_time_obj.day3.length === 0 &&
                        this.put_time_obj.day4.length === 0 &&
                        this.put_time_obj.day5.length === 0 &&
                        this.put_time_obj.day6.length === 0 &&
                        this.put_time_obj.day7.length === 0) {
                        this.$message.warning('请至少选择一个时间段！');
                        return;
                    } else {
                        param.op_plan.put_time = JSON.stringify(this.put_time_obj);
                    }
                }
            }
            if ((this.mediaType === 1 && this.promotionPlanType !== 3)) {
                if (this.dailyLimitType === 1) {
                    param.op_plan.limit = 0;
                } else {
                    if (this.quota) {
                        param.op_plan.limit = this.quota;
                    } else {
                        this.$message.warning('请设置每日限额！');
                        return;
                    }
                }
            }
            if (this.mediaType === 2 && this.promotionPlanType === 1) {
                if (this.dailyLimitType === 1) {
                    param.op_plan.limit = 0;
                } else {
                    if (this.quota) {
                        param.op_plan.limit = this.quota;
                    } else {
                        this.$message.warning('请设置每日限额！');
                        return;
                    }
                }
            }
            if (this.mediaType === 2 && this.promotionPlanType === 2) {
                if (this.quota) {
                    param.op_plan.limit = this.quota;
                } else {
                    this.$message.warning('请设置每日限额！');
                    return;
                }
            }
            if ((this.mediaType === 3 && this.promotionPlanType === 1) ||
                (this.mediaType === 3 && this.promotionPlanType === 2)) {
                if (this.quota) {
                    param.op_plan.limit = this.quota;
                } else {
                    this.$message.warning('请设置每日限额！');
                    return;
                }
            }
            if (this.mediaType === 3 && this.promotionPlanType === 3) {
                if (this.dailyLimitType === 1) {
                    param.op_plan.limit = 0;
                } else {
                    if (this.quota) {
                        param.op_plan.limit = this.quota;
                    } else {
                        this.$message.warning('请设置每日限额！');
                        return;
                    }
                }
            }
            if (this.mediaType === 4 && this.promotionPlanType === 1) {
                if (this.dailyLimitType === 1) {
                    param.op_plan.limit = 0;
                } else {
                    if (this.quota) {
                        param.op_plan.limit = this.quota;
                    } else {
                        this.$message.warning('请设置每日限额！');
                        return;
                    }
                }
            }
            if (this.mediaType === 4 && this.promotionPlanType === 2) {
                if (this.quota) {
                    param.op_plan.limit = this.quota;
                } else {
                    this.$message.warning('请设置每日限额！');
                    return;
                }
                if (!this.advancedRulesScore) {
                    this.$message.warning('请填写高级规则的分值！');
                    return;
                } else {
                    param.op_plan.advanced_rules = this.advancedRules;
                    param.op_plan.advanced_rules_score = this.advancedRulesScore;
                }
            }
            if (this.checkAreaList.length === 0) {
                this.$message.warning('请至少选择一个区域！');
                return;
            }
            if (this.checkAreaList.length === 31) {
                param.op_directional.area = 'all';
            } else {
                param.op_directional.area = this.checkAreaList.join(',');
            }
            if (this.adRemarks) {
                param.op_directional.mark = this.adRemarks;
                param.op_directional.mark_score = this.adRemarksScore;
            }
            if (this.$route.query.id) {
                param.id = this.$route.query.id;
            }
            operationsOperationsSettings(param).then(res => {
                this.$message.success(res.msg);
                this.$router.push({
                    path: '/practice/index'
                })
            }).catch(err => {
                console.log(err);
            })
        },
        //取消
        cancelPlacementTemp() {
            this.$router.push({
                path: '/practice/index'
            })
        }
    }
}