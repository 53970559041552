<template>
    <div class="promote-content">
        <div class="content-item">
            <div class="content-title">直播间管理</div>
            <div class="item-content">
                <div class="item-title">标题</div>
                <el-input class="item-input max" ref="title" v-model="formData.title" show-word-limit
                          maxlength="17"></el-input>
                <span class="input-text front">分值</span>
                <el-input-number class="item-input tiny" ref="title_score" v-model="formData.title_score"
                                 :min="0.1"
                                 :max="99999"
                                 :controls="false"
                                 :precision="0"></el-input-number>
                <span class="input-text">分</span>
            </div>
            <div class="item-content">
                <div class="item-title">开播时间</div>
                <el-date-picker class="item-input medium" ref="live_broadcast_begin_time" type="datetime"
                                placeholder="选择日期"
                                v-model="formData.live_broadcast_begin_time"
                                value-format="yyyy-MM-dd HH:mm:ss"></el-date-picker>
                <div class="divide"></div>
                <el-date-picker class="item-input medium" ref="live_broadcast_end_time" placeholder="选择日期"
                                v-model="formData.live_broadcast_end_time"
                                value-format="yyyy-MM-dd HH:mm:ss"
                                @change="selectOverTime"
                                type="datetime"></el-date-picker>
                <span class="input-text front">分值</span>
                <el-input-number class="item-input tiny" ref="live_broadcast_time_score"
                                 v-model="formData.live_broadcast_time_score"
                                 :min="0.1"
                                 :max="99999"
                                 :controls="false"
                                 :precision="0"></el-input-number>
                <span class="input-text">分</span>
            </div>
            <div class="item-content">
                <div class="item-title">主播昵称</div>
                <el-input class="item-input max" ref="anchor_name" v-model="formData.anchor_name" show-word-limit
                          maxlength="15"></el-input>
                <span class="input-text front">分值</span>
                <el-input-number class="item-input tiny" ref="anchor_name_score"
                                 v-model="formData.anchor_name_score"
                                 :min="0.1"
                                 :max="99999"
                                 :controls="false"
                                 :precision="0"></el-input-number>
                <span class="input-text">分</span>
            </div>
            <div class="item-content">
                <div class="item-title">主播微信号</div>
                <el-input class="item-input max" ref="anchor_wechat" v-model="formData.anchor_wechat"></el-input>
                <span class="input-text front">分值</span>
                <el-input-number class="item-input tiny" ref="anchor_wechat_score"
                                 v-model="formData.anchor_wechat_score"
                                 :min="0.1"
                                 :max="99999"
                                 :controls="false"
                                 :precision="0"></el-input-number>
                <span class="input-text">分</span>
            </div>
            <div class="item-content">
                <div class="item-title">允许评论</div>
                <el-radio v-model="formData.is_allow_comments" label="1">是</el-radio>
                <el-radio v-model="formData.is_allow_comments" label="0">否</el-radio>
            </div>
        </div>
        <div class="content-item">
            <div class="content-head">
                <div class="content-title">商品库</div>
                <el-button type="primary" @click="addProduct">添加商品</el-button>
            </div>
            <div class="item-content"> 
                <el-table :data="formData.goods_library" size="medium"
                          class="customTable"
                          :header-cell-style="{fontWeight: 'normal', height: '60px', color: '#666666', background: '#F6F6F6',fontSize: '16px'}"
                          :cell-style="{fontSize: '16px',color: '#333333',height: '70px'}">
                    <el-table-column prop="goods_name" align="left" label="商品标题"></el-table-column>
                    <el-table-column prop="points" align="center" label="价格形势">
                        <template slot-scope="scope">
                            <div class="price-wrapper" v-if="scope.row.price_type == 0">
                                <span class="price">{{scope.row.price_begin}}</span>
                            </div>
                            <div class="price-wrapper" v-if="scope.row.price_type == 1">
                                <span class="price">{{scope.row.price_begin}}</span>
                                <span>-</span>
                                <span class="price">{{scope.row.price_end}}</span>
                            </div>
                            <div class="price-wrapper" v-if="scope.row.price_type == 2">
                                <span class="price-before">原价</span>
                                <span class="price type3">{{scope.row.price_begin}}</span>
                                <span class="price-before">现价</span>
                                <span class="price type3">{{scope.row.price_end}}</span>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="goods_url" align="center" label="商品链接"></el-table-column>
                    <el-table-column prop="score" align="center" label="分值"></el-table-column>
                    <el-table-column align="center" label="操作" width="200">
                        <template slot-scope="scope">
                            <div class="link-box">
                                <el-link type="success" @click="editProduct(scope.row,scope.$index)" :underline="false">
                                    编辑
                                </el-link>
                                <el-link type="danger" @click="delProduct(scope.row,scope.$index)" :underline="false">
                                    删除
                                </el-link>
                            </div>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
        </div>
        <div class="content-item">
            <div class="content-head">
                <div class="content-title">商品管理</div>
                <el-button type="primary" @click="productManage">添加商品</el-button>
            </div>
            <div class="item-content">
                <el-table :data="formData.goods_management" size="medium"
                          class="customTable"
                          :header-cell-style="{fontWeight: 'normal', height: '60px', color: '#666666', background: '#F6F6F6',fontSize: '16px'}"
                          :cell-style="{fontSize: '16px',color: '#333333',height: '70px'}">
                    <el-table-column align="left" label="商品标题">
                        <template slot-scope="scope">
                            <el-select v-model="scope.row.goods_name" placeholder="请选择"
                                       @change="((val)=>{selectProduct(val, scope.$index, scope.row)})">
                                <el-option
                                        v-for="(item,index) in formData.goods_library"
                                        :key="index"
                                        :label="item.goods_name"
                                        :value="index">
                                </el-option>
                            </el-select>
                        </template>
                    </el-table-column>
                    <el-table-column prop="points" align="center" label="价格形势">
                        <template slot-scope="scope">
                            <div class="price-wrapper" v-if="scope.row.price_type == 0">
                                <span class="price">{{scope.row.price_begin}}</span>
                            </div>
                            <div class="price-wrapper" v-if="scope.row.price_type == 1">
                                <span class="price">{{scope.row.price_begin}}</span>
                                <span>-</span>
                                <span class="price">{{scope.row.price_end}}</span>
                            </div>
                            <div class="price-wrapper" v-if="scope.row.price_type == 2">
                                <span class="price-before">原价</span>
                                <span class="price type3">{{scope.row.price_begin}}</span>
                                <span class="price-before">现价</span>
                                <span class="price type3">{{scope.row.price_end}}</span>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="goods_url" align="center" label="商品链接"></el-table-column>
                    <el-table-column prop="score" align="center" label="分值"></el-table-column>
                    <el-table-column align="center" label="操作" width="200">
                        <template slot-scope="scope">
                            <div class="link-box">
                                <el-link type="danger" @click="delProductManage(scope.$index)" :underline="false">删除
                                </el-link>
                            </div>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
        </div>
        <div class="content-item">
            <div class="content-head">
                <div class="content-title">抽奖池</div>
                <el-button type="primary" @click="addLottery">创建抽奖</el-button>
            </div>
            <div class="item-content">
                <el-table :data="formData.lottery_pool" size="medium"
                          class="customTable"
                          :header-cell-style="{fontWeight: 'normal', height: '60px', color: '#666666', background: '#F6F6F6',fontSize: '16px'}"
                          :cell-style="{fontSize: '16px',color: '#333333',height: '70px'}">
                    <el-table-column prop="name" align="left" width="410" label="名称"></el-table-column>
                    <el-table-column prop="type" align="center" label="抽奖方式">
                        <template slot-scope="scope">
                            <span v-if="scope.row.type == 0">评论抽奖</span>
                            <span v-if="scope.row.type == 1">点赞抽奖</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="people_num" align="center" label="抽奖人数"></el-table-column>
                    <el-table-column prop="scoring_operation" align="center" label="得分操作">
                              <template slot-scope="scope">
                                  <el-select  v-model="scope.row.scoring_operation"
                                       placeholder="请选择" style="width: 200px;" @change="changeControl">
                                <el-option
                                        v-for="item in optionList"
                                        :key="item.id"
                                        :label="item.title"
                                        :value="item.id">
                                </el-option>
                            </el-select>
                              </template>
                    </el-table-column>
                    <el-table-column prop="score" align="center" label="分值"></el-table-column>
                    <el-table-column align="center" label="操作" width="200">
                        <template slot-scope="scope">
                            <div class="link-box">
                                <el-link type="success" @click="editLottery(scope.row,scope.$index)" :underline="false">
                                    编辑
                                </el-link>
                                <el-link type="danger" @click="delLottery(scope.row,scope.$index)" :underline="false">
                                    删除
                                </el-link>
                            </div>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
        </div>
        <div class="content-item">
            <div class="content-title">直播控制台</div>
            <div class="item-content">
                <div class="item-title">当前在线人数</div>
                <el-input-number class="item-input medium"
                                 ref="currently_online_num"
                                 :min="0.1"
                                 :max="99999"
                                 :controls="false"
                                 :precision="0" v-model="formData.currently_online_num "></el-input-number>
            </div>
            <div class="item-content">
                <div class="item-title">观看人数</div>
                <el-input-number class="item-input medium"
                                 ref="watch_num"
                                 :min="0.1"
                                 :max="99999"
                                 :controls="false"
                                 :precision="0" v-model="formData.watch_num"></el-input-number>
            </div>
            <div class="item-content">
                <div class="item-title">观看次数</div>
                <el-input-number class="item-input medium"
                                 ref="watch_frequency"
                                 :min="0.1"
                                 :max="99999"
                                 :controls="false"
                                 :precision="0" v-model="formData.watch_frequency"></el-input-number>
            </div>
            <div class="item-content">
                <div class="item-title">平均观看时长</div>
                <el-input-number class="item-input medium"
                                 ref="average_watch_time"
                                 :min="0.1"
                                 :max="99999"
                                 :controls="false"
                                 :precision="0" v-model="formData.average_watch_time"></el-input-number>
                <span class="input-text">分钟</span>
            </div>
        </div>
        <div class="content-item">
            <div class="content-head">
                <div class="content-title">商品控制</div>
                <el-button type="primary" @click="productContro">添加商品</el-button>
            </div>
            <div class="item-content">
                <el-table :data="formData.goods_control" size="medium"
                          :key="itemKey"
                          class="customTable"
                          :header-cell-style="{fontWeight: 'normal', height: '60px', color: '#666666', background: '#F6F6F6',fontSize: '16px'}"
                          :cell-style="{fontSize: '16px',color: '#333333',height: '70px'}">
                    <el-table-column prop="goods_name" align="left" label="名称">
                        <template slot-scope="scope">
                            <el-select v-model="scope.row.goods_name" placeholder="请选择" @change="((val) => {goodsControlEvt(val, scope.row.id, scope.$index)})">
                                <el-option
                                    v-for="item in formData.goods_management"
                                    :key="item.id"
                                    :label="item.goods_name"
                                    :value="item">
                                </el-option>
                            </el-select>
                        </template>
                    </el-table-column>
                    <el-table-column prop="points" align="center" label="价格形势">
                        <template slot-scope="scope">
                            <div class="price-wrapper" v-if="scope.row.price_type == 0">
                                <span class="price">{{scope.row.price_begin}}</span>
                            </div>
                            <div class="price-wrapper" v-if="scope.row.price_type == 1">
                                <span class="price">{{scope.row.price_begin}}</span>
                                <span>-</span>
                                <span class="price">{{scope.row.price_end}}</span>
                            </div>
                            <div class="price-wrapper" v-if="scope.row.price_type == 2">
                                <span class="price-before">原价</span>
                                <span class="price type3">{{scope.row.price_begin}}</span>
                                <span class="price-before">现价</span>
                                <span class="price type3">{{scope.row.price_end}}</span>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="standard" align="center" label="得分操作">
                        <template slot-scope="scope">
                            <el-select  v-model="scope.row.scoring_operation"
                                       placeholder="请选择" style="width: 200px;" @change="changeControl">
                                <el-option
                                        v-for="item in optionList"
                                        :key="item.id"
                                        :label="item.title"
                                        :value="item.id">
                                </el-option>
                            </el-select>
<!--                            <span v-show="scope.row.isEdit  == false">{{scope.row.scoring_operation == 0?'不推送':'推送'}}</span>-->
                        </template>
                    </el-table-column>
                    <el-table-column prop="score" align="center" label="分值">
                        <template slot-scope="scope">
                            <div class="inner-box">
                                <el-input-number style="width: 150px;" size="medium"
                                                 v-model="scope.row.score" @change="changeControl"
                                                 class="item-input medium"
                                                 :min="0.1"
                                                 :max="99999"
                                                 :controls="false"
                                                 :precision="0"/>
<!--                                <span v-show="scope.row.isEdit  == false">{{scope.row.score}}</span>-->
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column align="center" label="操作" width="200">
                        <template slot-scope="scope">
                            <div class="link-box">
<!--                                <el-link type="success" @click="editGoodsControl(scope.row)" :underline="false">编辑
                                </el-link>-->
                                <el-link type="danger" @click="delGoodsControl(scope.$index)" :underline="false">删除
                                </el-link>
                            </div>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
        </div>
        <div class="content-item">
            <div class="content-title">上传素材</div>
            <div class="item-content">
                <el-button type="primary" plain onclick="uploadFile.click()">点击上传</el-button>
                <input class="file-upload-btn" ref="uploadFile" id="uploadFile" type="file"
                       accept="application/zip, .rar" title=""
                       @change="changeFile($event)">
                <span class="upload-text">文件支持格式为zip和rar，文件大小不超过1G 拷贝</span>
            </div>
        </div>
        <div class="content-item">
            <div class="item-content">
                <div class="upload-file-list">
                    <div class="file-item" v-for="(item, index) in materialList">
                        <i class="iconfont">&#xe60b;</i>
                        <span class="file-name" :title="item.name">{{item.name}}</span>
                        <el-progress :percentage="percent" v-show="percent < 100"></el-progress>
                        <i class="iconfont finish-icon" v-show="percent == 100">&#xe654;</i>
                        <i class="iconfont del-icon" @click="delMaterial(item, index)" v-show="percent == 100">&#xe651;</i>
                    </div>
                    <download-btn :status="isFinish" :list="materialList"></download-btn>
                </div>
            </div>
        </div>
        <div class="footer-wrapper">
            <el-button type="primary" @click="submit">保存</el-button>
            <el-button @click="reset">取消</el-button>
        </div>
        <addProductDialog v-if="addProductDialog" :addProductDialog="addProductDialog" @closeDialog="closeDialog"
                          @saveProduct="saveProduct" :editProduct="editProductObj"/>
        <addLotteryDialog v-if="addLotteryDialog" :addLotteryDialog="addLotteryDialog" @closeDialog="closeDialog"
                          @saveLottery="saveLottery" :editLottery="editLotteryObj" :isCreate="isCreate"/>
    </div>
</template>

<script>
    import oneNumber from "../../../../directive/index";
    import {
        uploadMaterial,
        SaveLiveBroadcast,
        operationProblemDelMaterial,
        mergeSlice,
        operationProblemOpDetail
    } from '@/utils/apis'
    import addProductDialog from "./addProductDialog";
    import addLotteryDialog from "./addLotteryDialog";
    import Upload from '@/utils/slice_upload';
    import {randomStr} from "../../../../utils/common";
    import downloadBtn from "../../../util/downloadBtn.vue";
    import _ from "underscore"

    export default {
        name: "livePromote",
        directives: {
            oneNumber
        },
        props: ['practiceForm'],
        components: {
            addProductDialog,
            addLotteryDialog,
            downloadBtn
        },
        computed: {
            isFinish() {
                return Boolean(this.materialList.length) && this.materialList[0].url.length > 0
            }
        },
        data() {
            return {
                addProductDialog: false,
                addLotteryDialog: false,
                editProductObj: {},
                editLotteryObj: {},
                itemKey: "",
                formData: {
                    goods_library: [],
                    goods_management: [],
                    lottery_pool: [],
                    goods_control: [],
                    material_file: [],
                    live_broadcast_begin_time: '',
                    live_broadcast_end_time: '',
                    title: "",
                    title_score: "",
                    live_broadcast_time_score: "",
                    anchor_name: "",
                    anchor_name_score: "",
                    anchor_wechat: "",
                    anchor_wechat_score: "",
                    is_allow_comments: "",
                    currently_online_num: "",
                    watch_num: "",
                    watch_frequency: "",
                    average_watch_time: "",
                    isCreate:true
                },
                //素材列表
                materialList: [],
                goods_selectList: [],
                optionList: [
                    {
                        id: 0,
                        title: '不推送'
                    },
                    {
                        id: 1,
                        title: '推送'
                    },
                ],
                radio: '',
                percent: 0,
                id: "",
                uploadInstance: null,
                pickerOptions: {
                    disabledDate(time) {
                        return time.getTime() < Date.now() - 3600 * 1000 * 24;
                    }
                },
            }
        },
        created() {
            this.id = this.$route.query.id;
            if (this.id !== undefined) {
                this.getLivePromotion()
            }
        },
        methods: {
            goodsControlEvt(value, id, index) {
                let tmp = Object.assign({}, value)
                let res = _.find(this.formData.goods_control, {id: tmp.id})
                if (res && id !== tmp.id) {
                    this.$message.warning('商品已选择')
                    if (!this.formData.goods_control[index]['id']) {
                        this.formData.goods_control[index] = {};
                    }
                    return
                }
                tmp.scoring_operation = 0 //默认不推送
                tmp.score = 0 //默认分值为0
                this.$set(this.formData.goods_control, index, tmp);
            },
            productContro() {
                if (!this.formData.goods_management.length) {
                    return this.$message.warning('商品管理列表请添加商品');
                }
                this.formData.goods_control.push({});
            },
            getLivePromotion() {    
                let param = {
                    id: this.id
                }
                operationProblemOpDetail(param).then((res) => {
                    this.materialList = res.data.material_file;
                    this.formData.material_file = res.data.material_file;
                    this.formData.title = res.data.title;
                    this.formData.title_score = res.data.title_score;
                    this.formData.live_broadcast_begin_time = res.data.live_broadcast_begin_time;
                    this.formData.live_broadcast_end_time = res.data.live_broadcast_end_time;
                    this.formData.live_broadcast_time_score = res.data.live_broadcast_time_score;
                    this.formData.anchor_name = res.data.anchor_name;
                    this.formData.anchor_name_score = res.data.anchor_name_score;
                    this.formData.anchor_wechat = res.data.anchor_wechat;
                    this.formData.anchor_wechat_score = res.data.anchor_wechat_score;
                    this.formData.is_allow_comments = (res.data.is_allow_comments).toString();
                    this.formData.currently_online_num = res.data.currently_online_num;
                    this.formData.watch_num = res.data.watch_num;
                    this.formData.watch_frequency = res.data.watch_frequency;
                    this.formData.average_watch_time = res.data.average_watch_time;
                    this.formData.goods_library = res.data.goods_library;
                    this.goods_selectList = res.data.goods_library;
                    this.formData.goods_management = res.data.goods_management;
                    this.formData.goods_control = res.data.goods_control.map(item => {
                        item.isEdit = false
                        return item
                    });
                    this.formData.lottery_pool = res.data.lottery_pool;
                    this.percent = 100;
                }).catch((err) => {
                    console.log(err);
                })
            },
            selectOverTime(val) {
                if (!this.formData.live_broadcast_begin_time) {
                    this.$message.warning('请先选择开始时间！');
                    this.formData.live_broadcast_end_time = ''
                } else {
                    if (this.formData.live_broadcast_end_time < this.formData.live_broadcast_begin_time) {
                        this.$message.warning('请选择开始时间之后的日期！');
                        this.formData.live_broadcast_end_time = ''
                    }
                }
            },
            addProduct() {
                this.addProductDialog = true
            },
            productManage() {
                if (this.formData.goods_library.length === 0) {
                    this.$message.warning('请先添加商品库')
                    return false
                }
                let obj = {}
                this.formData.goods_management.push(obj)
            },
            selectProduct(val, index, row) {
                let obj = Object.assign({}, this.formData.goods_library[val]);
                // this.goods_selectList[index] = this.formData.goods_library[val]
                this.formatList(index, obj);
                /*this.formData.goods_control[index] = {}
                this.formData.goods_control[index] = this.formData.goods_management[index]
                this.formData.goods_control[index].isEdit = false;
                this.formData.goods_control[index].scoring_operation = 0;
                this.formData.goods_control[index].score = this.goods_selectList[index].score;
                this.itemKey = Math.random()*/
            },
            formatList(index, obj) {
                this.formData.goods_management[index].id = obj.id;
                this.formData.goods_management[index].goods_name = obj.goods_name;
                this.formData.goods_management[index].goods_img = obj.goods_img;
                this.formData.goods_management[index].goods_url = obj.goods_url;
                this.formData.goods_management[index].price_type = obj.price_type;
                this.formData.goods_management[index].score = obj.score;
                this.formData.goods_management[index].price_begin = obj.price_begin;
                this.formData.goods_management[index].price_end = obj.price_end;
            },
            isSelectProduct(item) {
                if (this.goods_selectList.indexOf(item) != -1) {
                    return false
                } else {
                    return true
                }
            },
            addLottery() {
                this.addLotteryDialog = true
                this.isCreate=true
            },
            saveProduct(val) {
                console.log(val,'66666')
                val.id = randomStr();
                if (val.index != undefined) {
                    this.formData.goods_library[val.index] = val
                } else {
                    this.formData.goods_library.push(val)
                }
            },
            editProduct(row, index) {
                this.editProductObj = row;
                this.editProductObj.index = index
                this.addProduct();
            },
            delProduct(row, index) {
                this.$confirm('是否删除', '提示消息', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    customClass: 'green-message-box',
                    type: 'warning'
                }).then(() => {
                    this.formData.goods_library.splice(index, 1)
                    this.goods_selectList.splice(index, 1)
                }).catch(() => {
                    this.$message.error('已取消删除');
                })
            },
            delProductManage(index) {
                this.$confirm('是否删除', '提示消息', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    customClass: 'green-message-box',
                    type: 'warning'
                }).then(() => {
                    this.formData.goods_management.splice(index, 1)
                }).catch(() => {
                    this.$message.error('已取消删除');
                })
            },
            saveLottery(val) {
                console.log(val,'999')
                if (val.index != undefined) {
                    this.formData.lottery_pool.splice(val.index,1,val)
                    // [val.index] = val
                } else {
                    this.formData.lottery_pool.push(val)
                }
            },
            editLottery(row, index) {
                this.editLotteryObj = row;
                this.editLotteryObj.index = index
                this.isCreate=false
                this.addLotteryDialog = true
            },
            delLottery(row, index) {
                this.$confirm('是否删除', '提示消息', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    customClass: 'green-message-box',
                    type: 'warning'
                }).then(() => {
                    this.formData.lottery_pool.splice(index, 1)
                }).catch(() => {
                    this.$message.error('已取消删除');
                })
            },
            changeControl() {
                this.itemKey = Math.random()
            },
            editGoodsControl(row) {
                row.isEdit = true;
                this.itemKey = Math.random()
            },
            delGoodsControl(index) {
                this.formData.goods_control.splice(index, 1)
                this.itemKey = Math.random()
            },
            closeDialog(val) {
                this.addProductDialog = val
                this.addLotteryDialog = val
                this.editProductObj = {}
            },
            changeFile(event) {
                let chunkSize = 1024 * 1024;
                let file = event.target.files[0];
                this.uploadInstance = new Upload(file, chunkSize, uploadMaterial);
                let obj = {
                    name: file.name,
                    url: ''
                }
                this.materialList = []
                this.formData.material_file = [];
                this.materialList.push(obj);
                this.formData.material_file.push(obj);
                let index = 0;
                this.uploadSlice(index);
                this.$refs.uploadFile.value = null
                this.percent = 0
            },
            uploadSlice(index) {
                let num = this.uploadInstance.total_block_num;
                if (index === num && num !== 1) {
                    //合并分片
                    mergeSlice({
                        flag: this.uploadInstance.file_tmp,
                        slice_size: this.uploadInstance.block_size
                    }).then(res => {
                        if (res.code === 200) {
                            this.percent = 100;
                            this.formData.material_file[0].url = res.data.src
                            this.materialList[0].url = res.data.src
                            this.$message.success(res.msg);
                        }
                    });
                    return;
                }
                this.uploadInstance.uploadSlice(index).then(res => {
                    if (res.code === 200) {
                        if (res.data.is_slice) { //is_slice 为true则为分片上传，后面需要合并分片,若为false则无须分片，或者文件大小<分片尺寸，直接上传就行
                            this.percent = Math.ceil((index / num) * 100);
                            this.uploadSlice(++index);
                        } else {
                            this.percent = 100;
                            this.formData.material_file[0].url = res.data
                            this.materialList[0].url = res.data
                            this.$message.success(res.msg);
                        }
                    }
                });
            },
            //删除素材
            delMaterial(item, index) {
                this.$confirm('是否删除', '提示消息', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    customClass: 'green-message-box',
                    type: 'warning'
                }).then(() => {
                    let param = {
                        file_url: item.url
                    }
                    operationProblemDelMaterial(param).then((res) => {
                        if (res.code == 200) {
                            this.$message.success(res.msg);
                            this.formData.material_file.splice(index, 1);
                            this.materialList.splice(index, 1);
                        }
                    }).catch((err) => {
                        console.log(err);
                    })
                }).catch(() => {
                    this.$message.error('已取消删除');
                })
            },
            submit() {
                let isErrNum = 0;
                if (!this.formData.title) {
                    this.$refs.title.$el.classList.add('isError');
                    isErrNum = 1;
                } else {
                    this.$refs.title.$el.classList.remove('isError');
                }
                if (!this.formData.title_score) {
                    this.$refs.title_score.$el.classList.add('isError');
                    isErrNum = 1;
                } else {
                    this.$refs.title_score.$el.classList.remove('isError');
                }
                if (!this.formData.live_broadcast_begin_time) {
                    this.$refs.live_broadcast_begin_time.$el.classList.add('isError');
                    isErrNum = 1;
                } else {
                    this.$refs.live_broadcast_begin_time.$el.classList.remove('isError');
                }
                if (!this.formData.live_broadcast_end_time) {
                    this.$refs.live_broadcast_end_time.$el.classList.add('isError');
                    isErrNum = 1;
                } else {
                    this.$refs.live_broadcast_end_time.$el.classList.remove('isError');
                }
                if (!this.formData.live_broadcast_time_score) {
                    this.$refs.live_broadcast_time_score.$el.classList.add('isError');
                    isErrNum = 1;
                } else {
                    this.$refs.live_broadcast_time_score.$el.classList.remove('isError');
                }
                if (!this.formData.anchor_name) {
                    this.$refs.anchor_name.$el.classList.add('isError');
                    isErrNum = 1;
                } else {
                    this.$refs.anchor_name.$el.classList.remove('isError');
                }
                if (!this.formData.anchor_name_score) {
                    this.$refs.anchor_name_score.$el.classList.add('isError');
                    isErrNum = 1;
                } else {
                    this.$refs.anchor_name_score.$el.classList.remove('isError');
                }
                if (!this.formData.anchor_wechat) {
                    this.$refs.anchor_wechat.$el.classList.add('isError');
                    isErrNum = 1;
                } else {
                    this.$refs.anchor_wechat.$el.classList.remove('isError');
                }
                if (this.formData.material_file.length === 0) {
                    // this.$message.warning('请上传素材！');
                    isErrNum = 1;
                }
                if (!this.formData.is_allow_comments) {
                    // this.$message.warning('请选择是否评论')
                    isErrNum = 1;
                }
                if (this.formData.goods_library.length == 0) {
                    // this.$message.warning('请添加商品库')
                    isErrNum = 1;
                }
                if (this.formData.goods_management.length == 0) {
                    // this.$message.warning('请添加商品管理')
                    isErrNum = 1;
                }
                if (this.formData.lottery_pool.length == 0) {
                    // this.$message.warning('请添加抽奖池')
                    isErrNum = 1;
                }
                if (this.formData.goods_control.length == 0) {
                    // this.$message.warning('请添加商品控制')
                    isErrNum = 1;
                }
                if (!this.formData.currently_online_num) {
                    this.$refs.currently_online_num.$el.classList.add('isError');
                    isErrNum = 1;
                } else {
                    this.$refs.currently_online_num.$el.classList.remove('isError');
                }
                if (!this.formData.watch_num) {
                    this.$refs.watch_num.$el.classList.add('isError');
                    isErrNum = 1;
                } else {
                    this.$refs.watch_num.$el.classList.remove('isError');
                }
                if (!this.formData.watch_frequency) {
                    this.$refs.watch_frequency.$el.classList.add('isError');
                    isErrNum = 1;
                } else {
                    this.$refs.watch_frequency.$el.classList.remove('isError');
                }
                if (!this.formData.average_watch_time) {
                    this.$refs.average_watch_time.$el.classList.add('isError');
                    isErrNum = 1;
                } else {
                    this.$refs.average_watch_time.$el.classList.remove('isError');
                }
                this.formData.goods_management.map(item => {
                    if (!item.goods_name) {
                        isErrNum = 1;
                    }
                })
                this.formData.goods_control.map(item => {
                    if (!item.goods_name) {
                        isErrNum = 1;
                    }
                })
                if (isErrNum === 1) {
                    this.$message.warning('每项为必填！')
                    return
                }
                this.formData.op_name = this.practiceForm.name
                this.formData.exam_module_id = this.practiceForm.module_level_three_value
                this.formData.assess_time = this.practiceForm.time
                this.formData.task_goal = this.practiceForm.taskList
                this.formData.task_background = this.practiceForm.taskBackground
                this.formData.task_analysis = this.practiceForm.taskAnalysis
                this.formData.task_operation = this.practiceForm.taskOperation
                this.formData.scoring_criteria = this.practiceForm.scoreList
                this.formData.category_id = this.practiceForm.classValue[this.practiceForm.classValue.length - 1]
                if (this.practiceForm.id != null) {
                    this.formData.id = this.practiceForm.id
                }
                SaveLiveBroadcast(this.formData).then((res) => {
                    if (res.code == 200) {
                        this.$message.success(res.msg);
                        this.reset();
                    } else {
                        this.$message.warning(res.msg)
                    }
                }).catch((err) => {
                    console.log('err', err)
                })
            },
            reset() {
                this.formData.goods_library = [];
                this.formData.goods_management = [];
                this.formData.goods_control = [];
                this.formData.lottery_pool = [];
                this.formData.material_file = [];
                this.$router.push({
                    path: '/practice/index'
                })
            },
        }
    }
</script>

<style scoped lang="scss">
    .promote-content {
        margin-top: 20px;
        font-size: 16px;
        color: #333333;

        ::v-deep .el-input-number .el-input__inner {
            text-align: left;
        }

        .item-input {
            &.max {
                width: 360px;
            }

            &.medium {
                width: 200px;
            }

            &.mini {
                width: 150px;
            }

            &.sex {
                width: 130px;
            }

            &.tiny {
                width: 120px;
            }
        }

        .input-text {
            margin: 0 10px;

            &.sex {
                margin: 0 17px;
            }

            &.front {
                margin: 0 12px 0 22px;
            }
        }

        .content-item {

            .content-title {
                position: relative;
                padding-left: 10px;
                margin-bottom: 22px;

                &:before {
                    content: '';
                    width: 4px;
                    height: 16px;
                    background-color: #2DC079;
                    border-radius: 2px;
                    position: absolute;
                    left: 0;
                    top: 3px;
                }
            }

            .item-content {
                display: flex;
                align-items: center;
                margin-bottom: 16px;

                .item-title {
                    width: 120px;
                    text-align: right;
                    margin-right: 10px;
                }

                .divide {
                    width: 24px;
                    height: 2px;
                    background: #D2D2D2;
                    margin: 0 18px;
                }

                .file-upload-btn {
                    display: none;
                }

                .upload-text {
                    margin-left: 11px;
                    font-size: 14px;
                    color: #999999;
                }

                ::v-deep.el-radio__inner::after {
                    box-sizing: content-box;
                    content: "";
                    border: 1px solid #fff;
                    border-left: 0;
                    border-top: 0;
                    height: 7px;
                    left: 4px;
                    position: absolute;
                    top: 1px;
                    transform: rotate(45deg) scaleY(0);
                    width: 3px;
                    transition: transform 0.15s ease-in 0.05s;
                    transform-origin: center;
                    border-radius: unset;
                    background-color: transparent;
                }

                ::v-deep.el-radio__input.is-checked .el-radio__inner:after {
                    transform: rotate(45deg) scaleY(1);
                }

                .price-wrapper {
                    .price.type3 {
                        margin: 0 5px;
                    }
                }
            }

            .content-head {
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-bottom: 22px;

                .content-title {
                    margin-bottom: 0;
                }
            }

            .upload-file-list {
                display: flex;
                flex-wrap: wrap;
                margin-top: 20px;
                align-items: center;
                .file-item {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    height: 40px;
                    padding: 10px;
                    box-sizing: border-box;
                    color: #333333;
                    font-size: 14px;
                    &:hover {
                        cursor: pointer;
                        background-color: #F5F7FA;

                        .del-icon {
                            display: block;
                        }

                        .finish-icon {
                            display: none;
                        }
                    }

                    .file-name {
                        display: inline-block;
                        width: 1%;
                        flex: 1;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                    }

                    .el-progress {
                        margin-left: 20px;
                        width: 160px;
                    }

                    i {
                        color: #2461EF;
                        margin: 0 10px 0 0;
                    }

                    .finish-icon {
                        color: #2DC079;
                        margin: 0 0 0 10px;
                    }

                    .del-icon {
                        display: none;
                        color: #FF0000;
                        margin: 0 0 0 10px;
                    }
                }
            }
        }

        .footer-wrapper {
            text-align: center;
            margin-top: 50px;
        }

        ::v-deep.el-dialog__footer {
            text-align: center;
        }
    }
</style>