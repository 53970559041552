<template>
    <el-dialog :title="editProduct.price_type?'编辑商品':'添加商品'" :visible.sync="addProductDialog" width="500px"
               :before-close="closeDialog"
               custom-class="green-dialog add-task-dialog">
        <div class="upload-file-content">
            <el-form :model="productForm" :rules="rules" ref="productForm" label-width="80px" class="demo-ruleForm">
                <el-form-item label="商品封面" prop="logo">
                    <span class="upload-text">尺寸要求200px*200px</span>
                    <div class="upload-box">
                        <div class="upload-main" onclick="UploadInput.click()">
                            <i class="el-icon-plus"></i>
                        </div>
                        <input
                                ref="fileInput"
                                type="file"
                                id="UploadInput"
                                @change="uploadFile($event)"
                                style="display:none"
                        />
                        <div class="img-box" v-if="productForm.goods_img !==''">
                            <img class="logo-box" :src="productForm.goods_img" alt="">
                            <i class="el-icon-error" @click="delPic"></i>
                        </div>
                    </div>
                </el-form-item>
                <el-form-item label="商品标题" prop="points">
                    <el-input v-model="productForm.goods_name" maxlength="14" show-word-limit></el-input>
                </el-form-item>
                <el-form-item label="价格形势" prop="points">
                    <div class="radio-box">
                        <div class="radio-item">
                            <el-radio v-model="productForm.price_type" @change="changePrice" label="0">一口价</el-radio>
                            <div class="item-right">
                                <div class="right-text">价格</div>
                                <el-input class="self-input max" v-model="productForm.price"
                                          :disabled="productForm.price_type != 0"
                                          @change="validPrice(productForm.price_type)">
                                    <span slot="suffix">元</span>
                                </el-input>
                            </div>
                        </div>
                        <div class="radio-item">
                            <el-radio v-model="productForm.price_type" @change="changePrice" label="1">价格区间</el-radio>
                            <div class="item-right">
                                <div class="right-text">价格</div>
                                <el-input class="self-input medium" v-model="productForm.price_begin"
                                          :disabled="productForm.price_type !=1"
                                          @change="validPrice(productForm.price_type)">
                                    <span slot="suffix">元</span>
                                </el-input>
                                <div class="divide"></div>
                                <el-input class="self-input medium" v-model="productForm.price_end"
                                          :disabled="productForm.price_type != 1"
                                          @change="validPrice(productForm.price_type)">
                                    <span slot="suffix">元</span>
                                </el-input>
                            </div>
                        </div>
                        <div class="radio-item">
                            <el-radio v-model="productForm.price_type" @change="changePrice" label="2">显示折扣价</el-radio>
                            <div class="item-right">
                                <div class="right-text">原价</div>
                                <el-input class="self-input mini" v-model="productForm.price_pre"
                                          :disabled="productForm.price_type != 2"
                                          @change="validPrice(productForm.price_type)">
                                    <span slot="suffix">元</span>
                                </el-input>
                                <div class="right-text">现价</div>
                                <el-input class="self-input mini" v-model="productForm.price_now"
                                          :disabled="productForm.price_type != 2"
                                          @change="validPrice(productForm.price_type)">
                                    <span slot="suffix">元</span>
                                </el-input>
                            </div>
                        </div>
                    </div>
                </el-form-item>
                <el-form-item label="商品链接" prop="goods_url">
                    <el-input v-model="productForm.goods_url"></el-input>
                </el-form-item>
                <el-form-item label="分值">
                    <el-input v-model="productForm.score" v-one-number>
                        <span slot="suffix">分</span>
                    </el-input>
                </el-form-item>
            </el-form>
        </div>
        <span slot="footer">
		        <el-button @click="closeDialog()">取 消</el-button>
		        <el-button style="margin-left: 30px;" type="primary" @click="save('productForm')">确 定</el-button>
		    </span>
    </el-dialog>
</template>

<script>
    import oneNumber from "../../../../directive/index";
    import {operationProblemUploadLbGoodsImg} from '@/utils/apis'

    export default {
        name: "addProductDialog",
        props: ['addProductDialog', 'editProduct'],
        directives: {
            oneNumber
        },
        data() {
            const reg = /^(https?|ftp):\/\/([a-zA-Z0-9.-]+(:[a-zA-Z0-9.&%$-]+)*@)*((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9][0-9]?)(\.(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9]?[0-9])){3}|([a-zA-Z0-9-]+\.)*[a-zA-Z0-9-]+\.(com|edu|gov|int|mil|net|org|biz|arpa|info|name|pro|aero|coop|museum|[a-zA-Z]{2}))(:[0-9]+)*(\/($|[a-zA-Z0-9.,?'\\+&%$#=~_-]+))*$/
            const validateSourceUri = (rule, value, callback) => {
                if (value) {
                    if (reg.test(value)) {
                        callback()
                    } else {
                        this.$message({
                            message: '商品链接填写不正确',
                            type: 'error'
                        })
                        callback(new Error('商品链接填写不正确'))
                    }
                } else {
                    callback()
                }
            }
            return {
                productForm: {
                    goods_name: '',
                    goods_img: '',
                    price_type: '',
                    price: '',
                    price_begin: '',
                    price_end: '',
                    price_pre: '',
                    price_now: '',
                    goods_url: '',
                    score: ''
                },
                rules: {
                    goods_url: [{validator: validateSourceUri, trigger: 'blur'}]
                },
            }
        },
        created() {
            if (this.editProduct.index != undefined) {
                switch (Number(this.editProduct.price_type)) {
                    case 0:
                        this.productForm.price = this.editProduct.price_begin
                        break;
                    case 1:
                        this.productForm.price_begin = this.editProduct.price_begin
                        this.productForm.price_end = this.editProduct.price_end
                        break;
                    case 2:
                        this.productForm.price_pre = this.editProduct.price_begin
                        this.productForm.price_now = this.editProduct.price_end
                        break;
                }
                this.productForm.goods_name = this.editProduct.goods_name;
                this.productForm.goods_img = this.editProduct.goods_img;
                this.productForm.price_type = JSON.stringify(Number(this.editProduct.price_type));
                this.productForm.goods_url = this.editProduct.goods_url;
                this.productForm.score = this.editProduct.score;
                this.productForm.index = this.editProduct.index;
            }
        },
        methods: {
            closeDialog() {
                this.productForm.goods_name = '';
                this.productForm.goods_img = '';
                this.productForm.price_type = '';
                this.productForm.price = '';
                this.productForm.price_begin = '';
                this.productForm.price_end = '';
                this.productForm.price_pre = '';
                this.productForm.price_now = '';
                this.productForm.goods_url = '';
                this.productForm.score = '';
                this.$emit('closeDialog', false)
            },
            validPrice(val) {
                const priceValid = /(^[1-9]([0-9]+)?(\.[0-9]{1,2})?$)|(^(0){1}$)|(^[0-9]\.[0-9]([0-9])?$)/
                switch (Number(val)) {
                    case 0:
                        if (!priceValid.test(this.productForm.price)) {
                            this.productForm.price = ''
                        }
                        break;
                    case 1:
                        if (!priceValid.test(this.productForm.price_begin)) {
                            this.productForm.price_begin = ''
                        }
                        if (!priceValid.test(this.productForm.price_end)) {
                            this.productForm.price_end = ''
                        } else {
                            if (Number(this.productForm.price_end) < Number(this.productForm.price_begin)) {
                                this.productForm.price_end = ''
                                this.$message.warning('必须大于前一个数')
                            }
                        }
                        break;
                    case 2:
                        if (!priceValid.test(this.productForm.price_pre)) {
                            this.productForm.price_pre = ''
                        }
                        if (!priceValid.test(this.productForm.price_now)) {
                            this.productForm.price_now = ''
                        }
                        break;
                }
            },
            changePrice(val) {
                switch (Number(val)) {
                    case 0:
                        this.productForm.price_begin = '';
                        this.productForm.price_end = '';
                        this.productForm.price_pre = '';
                        this.productForm.price_now = '';
                        break;
                    case 1:
                        this.productForm.price = '';
                        this.productForm.price_pre = '';
                        this.productForm.price_now = '';
                        break;
                    case 2:
                        this.productForm.price = '';
                        this.productForm.price_begin = '';
                        this.productForm.price_end = '';
                        break;
                }
            },
            uploadFile(event) {
                let formData = new FormData();
                let fileName = event.target.files[0].name;
                let file = event.target.files[0];
                const isSize = new Promise(function (resolve, reject) {
                    let width = 200
                    let height = 200
                    let _URL = window.URL || window.webkitURL
                    let img = new Image()
                    img.onload = function () {
                        let valid = img.width == width && img.height == height
                        valid ? resolve() : reject();
                    }
                    img.src = _URL.createObjectURL(file)
                }).then(() => {
                    formData.append("img", file);
                    operationProblemUploadLbGoodsImg(formData).then((res) => {
                        if (res.code == 200) {
                            this.productForm.goods_img = res.data;
                            this.$refs.fileInput.value = null
                        } else {
                            this.$message.warning(res.msg)
                        }
                    }).catch((err) => {
                        console.log(err);
                    });
                }, () => {
                    this.$message.error('上传图片尺寸限制为200*200!');
                    return Promise.reject();
                }).catch((e) => {
                })
            },
            delPic() {
                this.productForm.goods_img = ''
            },
            save(formName) {
                let result = 0
                if (this.productForm.goods_img == '') {
                    result = 1
                }
                if (this.productForm.goods_name == '') {
                    result = 1
                }
                if (this.productForm.price_type == '') {
                    result = 1
                } else {
                    switch (Number(this.productForm.price_type)) {
                        case 0:
                            if (this.productForm.price == '') {
                                result = 1
                            }
                            break;
                        case 1:
                            if (this.productForm.price_begin == '') {
                                result = 1
                            }
                            if (this.productForm.price_end == '') {
                                result = 1
                            }
                            break;
                        case 2:
                            if (this.productForm.price_pre == '') {
                                result = 1
                            }
                            if (this.productForm.price_now == '') {
                                result = 1
                            }
                            break;
                    }
                }
                if (this.productForm.goods_url == '') {
                    result = 1
                }
                if (this.productForm.score == '') {
                    result = 1
                }
                if(result == 1){
                    this.$message.warning('每项为必填')
                    return false
                }
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        let obj = {};
                        switch (Number(this.productForm.price_type)) {
                            case 0:
                                obj.price_begin = this.productForm.price
                                break;
                            case 1:
                                obj.price_begin = this.productForm.price_begin
                                obj.price_end = this.productForm.price_end
                                break;
                            case 2:
                                obj.price_begin = this.productForm.price_pre
                                obj.price_end = this.productForm.price_now
                                break;
                        }
                        obj.goods_img = this.productForm.goods_img;
                        obj.goods_name = this.productForm.goods_name;
                        obj.price_type = this.productForm.price_type;
                        obj.goods_url = this.productForm.goods_url;
                        obj.score = this.productForm.score;
                        if (this.productForm.index != undefined) {
                            obj.index = this.productForm.index
                        }
                        this.$emit('saveProduct', obj);
                        this.closeDialog()
                    }
                })
            }
        }
    }
</script>

<style scoped lang="scss">
    .add-task-dialog {
        .demo-ruleForm {
            .el-form-item {
                display: flex;
                margin-bottom: 15px;

                ::v-deep .el-form-item__label {
                    margin-bottom: 0;
                }

                ::v-deep .el-form-item__content {
                    width: 1%;
                    flex: 1;
                    margin-left: 10px !important;

                    .el-input-number {
                        width: 100%;
                    }

                    .el-input-number.is-without-controls .el-input__inner {
                        text-align: left;
                    }
                }
            }

            .upload-text {
                color: #999999;
            }

            .upload-box {
                display: flex;
                height: 80px;

                .upload-main {
                    width: 80px;
                    height: 80px;
                    cursor: pointer;
                    background: #EEEEEE;
                    border-radius: 2px;
                    color: #4D5AFF;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;

                    i {
                        font-size: 32px;
                        color: #A0A0A0;
                    }
                }

                .img-box {
                    margin-left: 40px;
                    position: relative;

                    &:nth-child(3) {
                        margin-left: 20px;
                    }

                    .logo-box {
                        width: 80px;
                        height: 80px;
                    }

                    .el-icon-error {
                        width: 14px;
                        height: 14px;
                        color: #FF0000;
                        cursor: pointer;
                        position: absolute;
                        right: -20px;
                        top: 0;
                    }
                }
            }

            .radio-box {
                .radio-item {
                    display: flex;
                    justify-content: space-between;
                    margin-top: 16px;

                    &:first-child {
                        margin-top: 0;
                    }

                    .divide {
                        width: 12px;
                        height: 2px;
                        background: #D2D2D2;
                        margin: 0 4px;
                    }

                    .el-radio {
                        width: 80px;
                        margin-top: 13px;

                        ::v-deep.el-radio__label {
                            font-size: 16px;
                        }
                    }

                    .item-right {
                        display: flex;
                        align-items: center;
                    }

                    .self-input {
                        &.max {
                            width: 200px;
                        }

                        &.medium {
                            width: 90px;
                        }

                        &.mini {
                            width: 80px;
                        }
                    }

                    .right-text {
                        font-size: 16px;
                        width: 32px;
                        margin: 0 4px;
                    }
                }
            }
        }
    }
</style>