<template>
  <div class="product-service">
    <div class="title-box">
      <div class="title">
        <div class="dot"></div>
        <div>商品榜单</div>
      </div>
      <el-button type="primary" @click="addGoodsMeal('添加商品')">添加商品</el-button>
    </div>
    <!-- 商品榜单 -->
    <el-table :data="goods_table.goods_list.slice((goods_page_config.page - 1) * goods_page_config.page_size, goods_page_config.page * goods_page_config.page_size)" row-key="goods_id" default-expand-all :tree-props="{ children: 'goods_bind', hasChildren: 'hasChildren' }" :header-cell-style="{ fontWeight: 'normal', height: '60px', color: '#666', background: '#F6F6F6', fontSize: '16px' }" :cell-style="{ fontSize: '16px', color: '#333', height: '70px' }">
      <el-table-column prop="goods_name" label="商品信息">
        <template slot-scope="scope">
          <div>{{ scope.row.goods_name && scope.row.goods_name.length > 10 ? scope.row.goods_name.slice(0, 10) + "..." : scope.row.goods_name }}</div>
        </template>
      </el-table-column>
      <el-table-column prop="page_view" label="浏览量">
        <template slot-scope="scope" v-if="scope.row.goods_name">
          <el-input-number v-model="scope.row.page_view" :controls="false" :min="1" :max="999999999" :step="1" step-strictly style="width: 160px"></el-input-number>
        </template>
      </el-table-column>
      <el-table-column prop="click_through_rate" label="点击率(%)">
        <template slot-scope="scope" v-if="scope.row.goods_name">
          <el-input-number v-model="scope.row.click_through_rate" :controls="false" :min="1" :max="100" :step="0.01" step-strictly style="width: 100px"></el-input-number>
        </template>
      </el-table-column>
      <el-table-column prop="conversion_rate" label="转化率(%)">
        <template slot-scope="scope" v-if="scope.row.goods_name">
          <el-input-number v-model="scope.row.conversion_rate" :controls="false" :min="1" :max="100" :step="0.01" step-strictly style="width: 100px"></el-input-number>
        </template>
      </el-table-column>
      <el-table-column prop="goods_bind_name" label="套餐商品">
        <template slot-scope="scope">
          <div>{{ scope.row.goods_bind_name && scope.row.goods_bind_name.length > 10 ? scope.row.goods_bind_name.slice(0, 10) + "..." : scope.row.goods_bind_name }}</div>
        </template>
      </el-table-column>
      <el-table-column align="right" label="操作" width="200">
        <template slot-scope="scope">
          <div class="link-box">
            <el-link type="success" :underline="false" @click="addGoodsMeal('新增商品套餐', scope.row)" v-if="scope.row.goods_name">新增商品套餐</el-link>
            <el-link type="danger" :underline="false" @click="delGoods(scope.row, scope.$index)">删除</el-link>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <div style="width: 100%; margin-top: 20px; display: flex; justify-content: space-between; align-items: center">
      <div>总共{{ goods_table.goods_list.length }}条数据，当前第{{ goods_page_config.page }}页</div>
      <el-pagination small background layout="prev, pager, next" :current-page="goods_page_config.page" :page-size="goods_page_config.page_size" :total="goods_table.goods_list.length" @current-change="goodsPageChange" />
    </div>
    <div class="title-box">
      <div class="title">
        <div class="dot"></div>
        <div>正确答案</div>
      </div>
      <el-button type="primary" @click="addGoodsMeal('添加套餐')">添加套餐</el-button>
    </div>
    <!-- 正确答案 -->
    <el-table :data="goods_table.answer_list.slice((answer_page_config.page - 1) * answer_page_config.page_size, answer_page_config.page * answer_page_config.page_size)" row-key="goods_id" default-expand-all :tree-props="{ children: 'goods_ids', hasChildren: 'hasChildren' }" :header-cell-style="{ fontWeight: 'normal', height: '60px', color: '#666', background: '#F6F6F6', fontSize: '16px' }" :cell-style="{ fontSize: '16px', color: '#333', height: '70px' }">
      <el-table-column prop="goods_name" label="套餐名称">
        <template slot-scope="scope">
          <div>{{ scope.row.goods_name && scope.row.goods_name.length > 10 ? scope.row.goods_name.slice(0, 10) + "..." : scope.row.goods_name }}</div>
        </template>
      </el-table-column>
      <el-table-column prop="score" label="分值">
        <template slot-scope="scope" v-if="scope.row.goods_ids">
          <el-input-number v-model="scope.row.score" :controls="false" :min="0.5" :max="100" :step="0.1" step-strictly style="width: 100px">分</el-input-number>
        </template>
      </el-table-column>
      <el-table-column align="right" label="操作" width="200">
        <template slot-scope="scope">
          <div class="link-box">
            <el-link type="success" :underline="false" @click="addGoodsMeal('编辑套餐', scope.row)" v-if="scope.row.goods_ids">编辑</el-link>
            <el-link type="danger" :underline="false" @click="delMeal(scope.row, scope.$index)">删除</el-link>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <div style="width: 100%; margin-top: 20px; display: flex; justify-content: space-between; align-items: center">
      <div>总共{{ goods_table.answer_list.length }}条数据，当前第{{ answer_page_config.page }}页</div>
      <el-pagination small background layout="prev, pager, next" :current-page="answer_page_config.page" :page-size="answer_page_config.page_size" :total="goods_table.answer_list.length" @current-change="answerPageChange" />
    </div>
    <div class="footer-btn" style="margin-top: 40px; text-align: center">
      <el-button type="primary" @click="sureSave">保 存</el-button>
      <el-button @click="cancelSave">取 消</el-button>
    </div>
    <!-- 弹窗 -->
    <el-dialog :title="dialog_title" :close-on-click-modal="false" :visible.sync="dialog_val" width="650px" @close="closeDialog" custom-class="green-dialog add-task-dialog assign-goods-dialog">
      <div class="assign-search">
        <span class="text">选择分类</span>
        <el-cascader v-model="category_val" :options="category_list" :props="key_option" @change="selectCategory" clearable class="practice-cascader"> </el-cascader>
      </div>
      <el-scrollbar class="upload-file-content">
        <el-checkbox-group v-model="checked_goods" class="goods-checkbox-group">
          <el-checkbox :label="item.id" v-for="item in all_goods_list" class="goods-checkbox" :disabled="dialog_title === '添加套餐' || dialog_title === '编辑套餐' ? selected_goods.answer_val.includes(item.id) : selected_goods.goods_val.includes(item.id)">{{ item.good_title }}</el-checkbox>
        </el-checkbox-group>
      </el-scrollbar>
      <div slot="footer" class="dialog-footer" style="text-align: center">
        <el-button @click="closeDialog">取 消</el-button>
        <el-button type="primary" @click="sureGoods">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { adminGoodsList, adminCategoryList, operateProductService, opDetail } from "@/utils/productService";

export default {
  name: "ProductService",
  components: {},
  props: {
    practiceForm: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      key_option: {
        label: "name",
        value: "id",
        children: "children",
      },
      goods_table: {
        goods_list: [],
        answer_list: [],
      },
      goods_page_config: {
        page: 1,
        page_size: 5,
      },
      answer_page_config: {
        page: 1,
        page_size: 5,
      },
      category_val: void 0,
      category_list: [],
      all_goods_list: [],
      selected_goods: {
        goods_val: [],
        answer_val: [],
      },
      checked_goods: [],
      dialog_title: void 0,
      dialog_val: false,
      current_goods_id: void 0,
      current_parent_id: void 0,
    };
  },
  computed: {},
  methods: {
    selectCategory(val) {
      this.category_val = val?.length ? val[val.length - 1] : void 0;
      this.getGoodsList();
    },
    getGoodsList() {
      let params = {
        category_id: this.category_val,
      };
      adminGoodsList(params)
        .then((res) => {
          this.all_goods_list = res.data;
        })
        .catch((err) => {
          console.log({ err });
        });
    },
    goodsPageChange(val) {
      this.goods_page_config.page = val;
    },
    answerPageChange(val) {
      this.answer_page_config.page = val;
    },
    getCategorysList() {
      adminCategoryList()
        .then((res) => {
          this.category_list = res.data;
          if (this.category_val) {
            this.getGoodsList();
          }
        })
        .catch((err) => {
          console.log({ err });
        });
    },
    addGoodsMeal(title, data) {
      this.dialog_title = title;
      this.dialog_val = true;
      if (data) this.current_goods_id = data.goods_id;
      if (title === "添加商品") {
        this.selected_goods.goods_val = [];
        this.selected_goods.goods_val = this.goods_table.goods_list.map((item) => item.goods_id);
      }
      if (title === "新增商品套餐") {
        this.selected_goods.goods_val = [];
        this.selected_goods.goods_val.push(data.goods_id);
        data.goods_bind.map((item) => {
          this.selected_goods.goods_val.push(item.goods_id);
        });
      }
      if (title === "添加套餐" || title === "编辑套餐") {
        let res = title === "编辑套餐" ? this.goods_table.answer_list.filter((item) => item.goods_id !== data.goods_id) : this.goods_table.answer_list;
        this.selected_goods.answer_val = [];
        res.map((item) => {
          this.selected_goods.answer_val.push(item.goods_id);
          item.goods_ids.map((data) => {
            this.selected_goods.answer_val.push(data.goods_id);
          });
        });
        if (title === "编辑套餐") {
          this.checked_goods = [];
          this.checked_goods.push(data.goods_id);
          data.goods_ids.map((item) => this.checked_goods.push(item.goods_id));
        }
      }
    },
    delGoods(data, index) {
      this.$confirm(`${data.parent_id ? "确定要删除当前商品吗？" : "确定要删除当前商品吗？删除当前商品后，该商品下的套餐商品也会被删除"}`, "删除商品提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        customClass: "green-message-box",
        type: "warning",
      }).then(() => {
        if (data.parent_id) {
          let index = this.goods_table.goods_list.findIndex((item) => item.goods_id === data.parent_id);
          this.goods_table.goods_list[index].goods_bind = this.goods_table.goods_list[index].goods_bind.filter((item) => item.goods_id !== data.goods_id);
        } else {
          this.goods_table.goods_list = this.goods_table.goods_list.filter((item) => item.goods_id !== data.goods_id);
        }
        this.selected_goods.goods_val = [];
        this.goods_table.goods_list.map((item) => {
          this.selected_goods.goods_val.push(item.goods_id);
          item.goods_bind.map((data) => {
            this.selected_goods.goods_val.push(data.goods_id);
          });
        });
        this.goods_page_config.page = 1;
      });
    },
    delMeal(data, index) {
      this.$confirm(`${data.goods_ids ? "确定要删除当前商品吗？删除当前商品后，该商品下的套餐商品也会被删除" : "确定要删除当前商品吗？"} `, "删除商品提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        customClass: "green-message-box",
        type: "warning",
      }).then(() => {
        if (data.goods_ids) {
          this.goods_table.answer_list = this.goods_table.answer_list.filter((item) => item.goods_id !== data.goods_id);
        } else {
          let index = this.goods_table.answer_list.findIndex((item) => item.goods_id === data.parent_id);
          this.goods_table.answer_list[index].goods_ids = this.goods_table.answer_list[index].goods_ids.filter((item) => item.goods_id !== data.goods_id);
        }
        this.selected_goods.answer_val = [];
        this.goods_table.answer_list.map((item) => {
          this.selected_goods.answer_val.push(item.goods_id);
          item.goods_ids.map((data) => {
            this.selected_goods.answer_val.push(data.goods_id);
          });
        });
        this.answer_page_config.page = 1;
      });
    },
    closeDialog() {
      this.dialog_val = false;
      this.checked_goods = [];
    },
    sureGoods() {
      if (!this.checked_goods.length) return (this.dialog_val = false);
      if (this.dialog_title === "添加商品" || this.dialog_title === "新增商品套餐") {
        this.selected_goods.goods_val = [...this.selected_goods.goods_val, ...this.checked_goods];
        this.selected_goods.goods_val = Array.from(new Set(this.selected_goods.goods_val));
        if (this.dialog_title === "添加商品") {
          this.checked_goods.map((id) => {
            let sign = this.goods_table.goods_list.find((data) => data.goods_id === id);
            let goods = this.all_goods_list.find((goods) => goods.id === id);
            if (!sign) this.goods_table.goods_list.push({ goods_id: id, goods_name: goods.good_title, page_view: void 0, click_through_rate: void 0, conversion_rate: void 0, goods_bind: [] });
          });
        } else {
          let index = this.goods_table.goods_list.findIndex((item) => item.goods_id === this.current_goods_id);
          this.checked_goods.map((id) => {
            let sign = this.goods_table.goods_list[index].goods_bind.find((data) => data.goods_bind_id === id);
            let goods = this.all_goods_list.find((goods) => goods.id === id);
            if (!sign) this.goods_table.goods_list[index].goods_bind.push({ parent_id: this.current_goods_id, goods_id: id, goods_bind_name: goods.good_title });
          });
        }
      }
      if (this.dialog_title === "添加套餐" || this.dialog_title === "编辑套餐") {
        this.selected_goods.answer_val = [...this.selected_goods.answer_val, ...this.checked_goods];
        this.selected_goods.answer_val = Array.from(new Set(this.selected_goods.answer_val));
        if (this.dialog_title === "编辑套餐") {
          this.checked_goods.map((id, index) => {
            let $index = this.goods_table.answer_list.findIndex((item) => this.current_goods_id === item.goods_id);
            let goods = this.all_goods_list.find((goods) => goods.id === id);
            if (!index) {
              this.current_parent_id = id;
              this.goods_table.answer_list[$index].goods_id = id;
              this.goods_table.answer_list[$index].goods_name = goods.good_title;
              this.goods_table.answer_list[$index].goods_ids = [];
            } else {
              let sort = this.goods_table.answer_list.findIndex((item) => this.current_parent_id === item.goods_id);
              let sign = this.goods_table.answer_list[sort].goods_ids.findIndex((data) => data.goods_id === id);
              if (sign === -1) this.goods_table.answer_list[sort].goods_ids.push({ parent_id: this.current_parent_id, goods_id: id, goods_name: goods.good_title });
            }
          });
        } else {
          this.checked_goods.map((id, index) => {
            let sign = this.goods_table.answer_list.find((data) => data.goods_id === id);
            let goods = this.all_goods_list.find((goods) => goods.id === id);
            if (!sign) {
              if (!index) {
                this.current_parent_id = id;
                this.goods_table.answer_list.push({ goods_id: id, goods_name: goods.good_title, score: void 0, goods_ids: [] });
              } else {
                let index = this.goods_table.answer_list.findIndex((item) => this.current_parent_id === item.goods_id);
                this.goods_table.answer_list[index].goods_ids.push({ parent_id: this.current_parent_id, goods_id: id, goods_name: goods.good_title });
              }
            }
          });
        }
      }
      this.closeDialog();
    },
    cancelSave() {
      this.$router.push({
        path: "/practice/index",
      });
    },
    sureSave() {
      let params = {
        op_name: this.practiceForm.name,
        exam_module_id: this.practiceForm.module_level_three_value,
        category_id: this.practiceForm.classValue[this.practiceForm.classValue.length - 1],
        assess_time: this.practiceForm.time,
        task_background: this.practiceForm.taskBackground,
        scoring_criteria: this.practiceForm.scoreList,
        goods_list: JSON.parse(JSON.stringify(this.goods_table.goods_list)),
        answer_goods: JSON.parse(JSON.stringify(this.goods_table.answer_list)),
      };
      params.goods_list.map((data) => {
        data.goods_bind = data.goods_bind.map((item) => item.goods_id);
      });
      params.answer_goods.map((data) => {
        data.goods_ids.unshift({ goods_id: data.goods_id });
        data.goods_ids = data.goods_ids.map((item) => item.goods_id);
        delete data.goods_id;
        delete data.goods_name;
      });
      if (this.$route.query.id) {
        params.id = this.$route.query.id;
      }
      operateProductService(params).then((res) => {
       
        if (res.code === 200) {
          this.$message.success(res.msg);
          this.$router.push({ path: "/practice/index" });
        }
      });
    },
    getOpDetail() {
      opDetail({ id: this.$route.query.id }).then((res) => {
        if (res.code === 200) {
          this.goods_table.goods_list = res.data.goods_list;
          this.goods_table.goods_list.map((data) => {
            data.goods_bind.map((item) => {
              item.parent_id = data.goods_id;
              item.goods_id = item.id;
              item.goods_bind_name = item.goods_name;
              if (item.goods_bind_name) delete item.goods_name;
            });
          });
          this.goods_table.answer_list = res.data.answer_goods;
          this.goods_table.answer_list.map((data) => {
            data.goods_id = data.goods_ids[0].id;
            data.goods_name = data.goods_ids[0].goods_name;
            if (data.goods_id && data.goods_name) data.goods_ids.splice(0, 1);
            data.goods_ids.map((item) => {
              item.parent_id = data.goods_id;
              item.goods_id = item.id;
            });
          });
        }
      });
    },
  },

  mounted() {
    this.getGoodsList();
    this.getCategorysList();
    if (this.$route.query.id) this.getOpDetail();
  },
};
</script>

<style scoped lang="scss">
.product-service {
  box-sizing: border-box;
  height: 100%;
  .title-box {
    margin: 20px 0 15px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #333;
    font-size: 16px;
    .title {
      display: flex;
      align-items: center;
      .dot {
        width: 4px;
        height: 16px;
        background: #2dc079;
        border-radius: 2px;
        margin-right: 6px;
      }
    }
  }
  ::v-deep .el-checkbox__label {
    position: absolute;
    width: 160px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  ::v-deep .el-table__expand-icon {
    display: none;
  }
}
</style>
