<template>
    <div class="goods-text-content">
        <div class="goods-item score-item" style="height: 40px;margin-bottom: 10px;">
            <div class="title">
                <span class="text">考试内容</span>
            </div>
            <el-radio-group v-model="examContentType" class="placement-radio-group">
                <el-radio :label="1">软文营销</el-radio>
                <el-radio :label="2">短视频营销</el-radio>
                <el-radio :label="3">直播营销</el-radio>
            </el-radio-group>
        </div>
        <div class="goods-item score-item" style="margin-bottom: 15px;">
            <div class="title hot-goods" style="width: 209px;">
                <div class="title">
                    <span class="text">在售商品</span>
                </div>
                <el-button type="primary" @click="distributionGoods" class="distribution-goods">分配商品</el-button>
            </div>
        </div>
        <div class="goods-item score-item">
            <div class="title">
                <span class="text">社交电商分值</span>
            </div>
            <el-input-number
                    v-model="socialECommerceScore"
                    :min="0.1"
                    :max="100"
                    :controls="false"
                    :precision="1"
                    class="name-input">
            </el-input-number>分
        </div>
        <div class="content-item goods-item">
            <div class="title" style="justify-content: flex-start;margin-top: 20px;">
                <div style="display: flex;align-items: center;">
                    <span class="left-icon"></span>
                    <span class="text">上传素材</span>
                </div>
            </div>
            <div class="item-content">
                <el-button type="primary" plain onclick="uploadFile.click()">点击上传</el-button>
                <input class="file-upload-btn" ref="uploadFile" id="uploadFile" type="file"
                       accept="application/zip, .rar" title=""
                       @change="changeFile($event)">
                <span class="upload-text">文件支持格式为zip和rar，文件大小不超过1G</span>
            </div>
        </div>
        <div class="content-item">
            <div class="item-content">
                <div class="upload-file-list">
                    <div class="file-item" v-for="(item, index) in materialList">
                        <i class="iconfont">&#xe60b;</i>
                        <span class="file-name" :title="item.name">{{item.name}}</span>
                        <el-progress :percentage="percent" v-show="percent < 100"></el-progress>
                        <i class="iconfont finish-icon" v-show="percent === 100">&#xe654;</i>
                        <i class="iconfont del-icon" v-show="percent === 100" @click="delMaterial(item, index)">&#xe651;</i>
                    </div>
                </div>
            </div>
        </div>
        <div class="content-item" style="margin-top: 20px;text-align: center;">
            <el-button type="primary" @click="saveGoodsText">保 存</el-button>
            <el-button @click="cancelSave">取 消</el-button>
        </div>
        <el-dialog title="分配商品" :visible.sync="assignGoodsDialog" width="650px" @close="handleCloseAssignGoodsDialog" custom-class="green-dialog add-task-dialog assign-goods-dialog">
            <div class="assign-search">
                <span class="text">选择分类</span>
                <el-cascader
                        v-model="searchGoodsClassValue"
                        :options="goodsClassList"
                        :props="keyObj"
                        @change="searchGoods"
                        clearable
                        class="practice-cascader">
                </el-cascader>
            </div>
            <el-scrollbar class="upload-file-content">
                <el-checkbox-group v-model="checkGoodsList" class="goods-checkbox-group">
                    <el-checkbox :label="item.id" v-for="item in GoodsList" class="goods-checkbox">{{item.good_title}}</el-checkbox>
                </el-checkbox-group>
                <!--<div class="none-goods">暂无商品</div>-->
            </el-scrollbar>
            <span slot="footer" class="dialog-footer">
		        <el-button @click="handleCloseAssignGoodsDialog">取 消</el-button>
		        <el-button type="primary" @click="saveAssignGoods">确 定</el-button>
		    </span>
        </el-dialog>
    </div>
</template>

<script>
    import  Upload from '@/utils/slice_upload';
    import {operationProblemSaveSocial, uploadMaterial,
        operationProblemDelMaterial, mergeSlice,
        operationProblemOpDetail, adminGoodListGood,
        adminGoodGetCategory, operationProblemGetSocialInfo} from '@/utils/apis'

    export default {
        name: "SocialECommerce",
        props: ['practiceForm'],
        data() {
            return {
                //考试内容
                examContentType: 1,
                //分配商品弹窗
                assignGoodsDialog: false,
                //商品列表
                GoodsList: [],
                checkGoodsList: [],
                //根据商品类目搜索商品
                searchGoodsClassValue: [],
                //商品类目列表
                goodsClassList: [],
                goodsClassValue: [],
                keyObj: {
                    label: 'name',
                    value: 'id',
                    children: 'children'
                },
                //社交电商分值
                socialECommerceScore: void 0,
                //上传素材
                material_file: [],
                //素材列表
                materialList: [],
                //分片长传
                uploadInstance: null,
                //素材进度条
                percent: 0,
            }
        },
        mounted() {
            if (this.$route.query.id) {
                this.getPracticeSocialECommerceDetail();
                this.getOperationProblemGetSocialInfo(this.$route.query.id);
            }
        },
        methods: {
            //获取社交电商详情
            getPracticeSocialECommerceDetail() {
                let param = {
                    id: this.$route.query.id
                }
                operationProblemOpDetail(param).then((res) => {
                    this.materialList = res.data.material_file;
                    this.material_file = res.data.material_file;
                    this.percent = 100;
                }).catch((err) => {
                    console.log(err);
                })
            },
            getOperationProblemGetSocialInfo(id) {
                operationProblemGetSocialInfo(id).then(res => {
                    this.examContentType = res.data.detail.type;
                    this.checkGoodsList = res.data.detail.goods_id;
                    this.socialECommerceScore = res.data.op_main.score;
                }).catch(err => {
                    console.log(err);
                })
            },
            //分配商品
            distributionGoods() {
                this.assignGoodsDialog = true;
                this.getGoodsClassList();
                this.getAdminGoodListGood();
            },
            //获取商品列表
            getAdminGoodListGood() {
                let param = {}
                if (this.searchGoodsClassValue.length > 0) {
                    param.category_id = this.searchGoodsClassValue[this.searchGoodsClassValue.length - 1];
                }
                adminGoodListGood(param).then((res) => {
                    this.GoodsList = res.data;
                }).catch((err) => {
                    console.log(err);
                });
            },
            //获取商品类目
            getGoodsClassList() {
                adminGoodGetCategory().then((res) => {
                    this.goodsClassList = res.data;
                }).catch((err) => {
                    console.log(err);
                })
            },
            //搜索商品
            searchGoods() {
                this.getAdminGoodListGood();
            },
            //关闭分配商品弹窗
            handleCloseAssignGoodsDialog() {
                this.assignGoodsDialog = false;
            },
            //保存商品分配
            saveAssignGoods() {
                this.assignGoodsDialog = false;
            },
            //上传素材
            changeFile(event) {
                if (event.target.files[0].size > 1024 * 1024 * 1024) {
                    this.$message.warning('文件超过1G');
                    return;
                } else {
                    let chunkSize = 1024 * 1024 * 8;
                    let file = event.target.files[0];
                    this.uploadInstance = new Upload(file, chunkSize, uploadMaterial);
                    let obj = {
                        name: file.name,
                        url: ''
                    }
                    this.materialList = []
                    this.material_file = [];
                    this.materialList.push(obj);
                    this.material_file.push(obj);
                    let index = 0;
                    this.uploadSlice(index);
                    this.percent = 0
                }
            },
            uploadSlice(index) {
                let num = this.uploadInstance.total_block_num;
                if (index === num && num !== 1) {
                    //合并分片
                    mergeSlice({
                        flag: this.uploadInstance.file_tmp,
                        slice_size: this.uploadInstance.block_size
                    }).then(res => {
                        if (res.code === 200) {
                            this.percent = 100;
                            this.material_file[0].url = res.data.src
                            this.materialList[0].url = res.data.src
                            this.$refs.uploadFile.value = null
                            this.$message.success(res.msg);
                        }
                    });
                    return;
                }
                this.uploadInstance.uploadSlice(index).then(res => {
                    if (res.code === 200) {
                        if (res.data.is_slice) { //is_slice 为true则为分片上传，后面需要合并分片,若为false则无须分片，或者文件大小<分片尺寸，直接上传就行
                            this.percent = Math.ceil((index / num) * 100);
                            this.uploadSlice(++index);
                        } else {
                            this.percent = 100;
                            this.material_file[0].url = res.data.src
                            this.materialList[0].url = res.data.src
                            this.$refs.uploadFile.value = null
                            this.$message.success(res.msg);
                        }
                    }
                });
            },
            //删除素材
            delMaterial(item, index) {
                this.$confirm('是否删除', '提示消息', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    customClass:'green-message-box',
                    type: 'warning'
                }).then(() => {
                    let param = {
                        file_url: item.url
                    }
                    operationProblemDelMaterial(param).then((res) => {
                        this.$message.success('删除成功');
                        this.materialList.splice(index, 1);
                        this.material_file.splice(index, 1);
                    }).catch((err) => {
                        console.log(err);
                    })
                }).catch(() => {
                    this.$message.error('已取消删除');
                })
            },
            //最后的保存
            saveGoodsText() {
                if (!this.practiceForm.name || this.practiceForm.name === '') {
                    this.$message.warning('请填写名称！');
                    return;
                }
                if (this.practiceForm.name.length < 3 || this.practiceForm.name.length > 50) {
                    this.$message.warning('名称必须大于3个字符且小于50个字符！');
                    return;
                }
                if (this.practiceForm.classValue.length === 0) {
                    this.$message.warning('请选择分类！');
                    return;
                }
                if (this.practiceForm.taskList.length === 0) {
                    this.$message.warning('请添加任务目标！');
                    return;
                }
                if (!this.practiceForm.taskBackground) {
                    this.$message.warning('请填写任务背景！');
                    return;
                }
                if (!this.practiceForm.taskAnalysis) {
                    this.$message.warning('请填写任务分析！');
                    return;
                }
                if (!this.practiceForm.taskOperation) {
                    this.$message.warning('请填写任务操作！');
                    return;
                }
                if (this.practiceForm.scoreList.length === 0) {
                    this.$message.warning('请添加评分标准！');
                    return;
                }
                if (this.checkGoodsList.length === 0) {
                    this.$message.warning('请分配商品！');
                    return;
                }
                if (!this.socialECommerceScore) {
                    this.$message.warning('请填写社交电商分值！');
                    return;
                }
                if (this.material_file.length === 0) {
                    this.$message.warning('请上传素材！');
                    return;
                }
                let param = {
                    op_name: this.practiceForm.name,
                    exam_module_id: this.practiceForm.module_level_three_value,
                    category_id: this.practiceForm.classValue[this.practiceForm.classValue.length - 1],
                    assess_time: this.practiceForm.time,
                    task_goal: this.practiceForm.taskList,
                    task_background: this.practiceForm.taskBackground,
                    task_analysis: this.practiceForm.taskAnalysis,
                    task_operation: this.practiceForm.taskOperation,
                    scoring_criteria: this.practiceForm.scoreList,
                    type:this.examContentType,
                    goods_id: this.checkGoodsList,
                    score: this.socialECommerceScore,
                    material_file: this.material_file
                }
                if (this.$route.query.id) {
                    param.id = this.$route.query.id;
                }
                operationProblemSaveSocial(param).then((res) => {
                    this.$message.success(res.msg);
                    this.$router.push({
                        path: '/practice/index'
                    })
                }).catch((err) => {
                    console.log(err);
                });
            },
            //取消保存
            cancelSave() {
                this.$router.push({
                    path: '/practice/index'
                })
            }
        }
    }
</script>

<style scoped lang="scss">
    .goods-text-content {
        margin: 20px 0;
        .score-item {
            justify-content: flex-start !important;
            flex-direction: inherit !important;
            align-items: center;
            font-size: 16px;
            color: #333333;
            .title {
                margin-bottom: 0 !important;
                margin-right: 15px;
            }
            .name-input {
                width: 120px;
                margin-right: 10px;
                ::v-deep .el-input__inner {
                    border-radius: 0;
                    text-align: left;
                }
            }
        }
        .goods-item {
            display: flex;
            flex-direction: column;
            .title {
                display: flex;
                align-items: center;
                justify-content: flex-end;
                width: 96px;
                margin-bottom: 15px;
                font-size: 16px;
                .left-icon {
                    display: inline-block;
                    width: 4px;
                    height: 16px;
                    background-color: #2DC079;
                    margin-right: 6px;
                    border-radius: 2px;
                }
            }
            .content-item {
                .item {
                    margin-bottom: 16px;
                    .text {
                        display: inline-block;
                        width: 100px;
                        text-align: right;
                        font-size: 16px;
                        color: #333333;
                        margin-right: 10px;
                    }
                    .name-input {
                        width: 360px;
                        ::v-deep .el-input__inner {
                            border-radius: 0;
                            text-align: left;
                        }
                    }
                }
            }
            .upload-btn {
                position: relative;
                display: flex;
                align-items: center;
                .btn {
                    width: 88px;
                    height: 38px;
                    background: #E7F6EF;
                    border: 1px solid #2DC079;
                    border-radius: 4px;
                    line-height: 38px;
                    text-align: center;
                    color: #2DC079;
                }
                input {
                    width: 88px;
                    height: 40px;
                    position: absolute;
                    top: 0;
                    cursor: pointer;
                    opacity: 0;
                }
                .text {
                    color: #999999;
                    font-size: 14px;
                    margin-left: 15px;
                }
            }
        }
    }
    .upload-file-list {
        display: flex;
        flex-wrap: wrap;
        margin-top: 20px;

        .file-item {
            display: flex;
            align-items: center;
            justify-content: space-between;
            height: 40px;
            padding: 10px;
            box-sizing: border-box;
            color: #333333;
            font-size: 14px;
            margin: 0 20px 15px 0;

            &:hover {
                cursor: pointer;
                background-color: #F5F7FA;

                .del-icon {
                    display: block;
                }

                .finish-icon {
                    display: none;
                }
            }

            .file-name {
                display: inline-block;
                width: 1%;
                flex: 1;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }

            .el-progress {
                margin-left: 20px;
                width: 160px;
            }

            i {
                color: #2461EF;
                margin: 0 10px 0 0;
            }

            .finish-icon {
                color: #2DC079;
                margin: 0 0 0 10px;
            }

            .del-icon {
                display: none;
                color: #FF0000;
                margin: 0 0 0 10px;
            }
        }
    }
    .file-upload-btn {
        display: none;
    }
    .upload-text {
        font-size: 14px;
        color: #999;
        margin-left: 10px;
    }
    .assign-goods-dialog {
        .assign-search {
            margin-bottom: 15px;
            .practice-cascader {
                width: 273px;
                margin-left: 10px;
                ::v-deep .el-input__inner {
                    border-radius: 2px;
                }
            }
        }
        .upload-file-content {
            height: 287px;
            border: 1px solid #E5E5E5;
            box-sizing: border-box;
            ::v-deep .el-scrollbar__wrap {
                overflow-x: hidden;
                .el-scrollbar__view {
                    height: 100%;
                }
            }
            .goods-checkbox-group {
                position: relative;
                height: 100%;
                padding: 12px;
                box-sizing: border-box;
                .goods-checkbox {
                    width: 172px;
                    padding-bottom: 10px;
                    &:nth-child(3n) {
                        margin-right: 0;
                    }
                    ::v-deep .el-checkbox__label {
                        position: absolute;
                        width: 158px;
                        overflow: hidden;
                        text-overflow:ellipsis;
                        white-space: nowrap;
                    }
                }

            }
        }
        .class-content {
            height: 287px;
            border: 1px solid #E5E5E5;
            box-sizing: border-box;
            .class-cascader {
                height: 100%;
                border: none;
            }
        }
        ::v-deep .el-dialog__footer {
            text-align: center;
        }
    }
</style>
