<template>
    <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="120px" :hide-required-asterisk="true" class="demo-ruleForm" style="margin-top: 30px">
        <el-form-item label="粉丝特征名称" prop="fans_characteristics_id">
            <el-select filterable v-model="ruleForm.fans_characteristics_id" placeholder="请选择粉丝特征名称" style="width: 360px" @change="changeCustomer">
                <el-option v-for="(item, index) in fansList" :key="`fansList_${index}`" :label="item.name" :value="item.id"></el-option>
            </el-select>
        </el-form-item>
        <el-form-item label="分值" prop="score">
            <el-input-number class="number-input" v-model="ruleForm.score" :precision="1" :controls="false" :min="0.1" :max="100.0" style="width: 250px"></el-input-number>
            <span style="font-size: 16px; color: #333; margin-left: 10px">分</span>
        </el-form-item>
        <el-form-item style="text-align: center; margin-top: 40px">
            <el-button type="primary" @click="submitForm('ruleForm')">保存</el-button>
            <el-button @click="resetForm('ruleForm')">取消</el-button>
        </el-form-item>
    </el-form>
</template>

<script>
    import { fanList, operationProblemSaveDataClean, operationProblemOpDetail } from '@/utils/apis.js'
    export default {
        props: {
            practiceForm: {
                type: Object,
                default:() => {}
            }
        },
        data() {
            return {
                ruleForm: {
                    fans_characteristics_id: null,
                    score: void 0,
                },
                rules: {
                    // fans_characteristics_id: [
                    //     { required: true, message: '请选择粉丝特征名称', trigger: 'change' }
                    // ],
                    // score: [
                    //     { required: true, message: '请选择分值', trigger: 'change' }
                    // ],
                },
                fansList: [],
            }
        },
        mounted() {
            this.getFansList()
            if (this.$route.query.id) {
                this.initDetail();
            }
        },
        methods: {
            // 编辑获取详情
            initDetail() {
                operationProblemOpDetail({id: this.$route.query.id}).then(res => {
                    this.ruleForm = {
                        fans_characteristics_id: res.data.fans_characteristics_id,
                        score: res.data.score,
                    }
                })
            },
            // 获取粉丝特征列表
            getFansList() {
                fanList().then((res) => {
                    this.fansList = res.data.data
                })
            },
            changeCustomer() {

            },
            // 保存试题
            submitForm(formName) {
                if (!this.practiceForm.name) {
                    return this.$message.warning('请填写名称！');
                }
                if (!this.practiceForm.time) {
                    return this.$message.warning('请输入考核时间')
                }
                if (this.practiceForm.classValue.length === 0) {
                    return this.$message.warning('请选择分类！');
                }
                if (this.practiceForm.taskList.length === 0) {
                    return this.$message.warning('请添加任务目标！');
                }
                if (!this.practiceForm.taskBackground) {
                    return this.$message.warning('请填写任务背景！');
                }
                if (!this.practiceForm.taskAnalysis) {
                    return this.$message.warning('请填写任务分析！');
                }
                if (!this.practiceForm.taskOperation) {
                    return this.$message.warning('请填写任务操作！');
                }
                if (this.practiceForm.scoreList.length === 0) {
                    return this.$message.warning('请添加评分标准！');
                }
                if (!this.ruleForm.fans_characteristics_id) {
                    return this.$message.warning('请选择粉丝特征名称！');
                }
                if (!this.ruleForm.score) {
                    return this.$message.warning('请输入分值！');
                }
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        let params = {
                            op_name: this.practiceForm.name,
                            exam_module_id: this.practiceForm.module_level_three_value,
                            category_id: this.practiceForm.classValue[this.practiceForm.classValue.length - 1],
                            assess_time: this.practiceForm.time,
                            task_goal: this.practiceForm.taskList,
                            task_background: this.practiceForm.taskBackground,
                            task_analysis: this.practiceForm.taskAnalysis,
                            task_operation: this.practiceForm.taskOperation,
                            scoring_criteria: this.practiceForm.scoreList,
                            fans_characteristics_id: this.ruleForm.fans_characteristics_id,
                            score: this.ruleForm.score,
                        }
                        if (this.$route.query.id) {
                            params.id = Number(this.$route.query.id);
                        }
                        operationProblemSaveDataClean(params).then((res) => {
                           if(res.code==200){
                            this.$message.success(res.msg)
                            this.$router.push('/practice/index')
                           }
                        })
                    } else {
                        return false;
                    }
                });
            },
            // 重置试题
            resetForm(formName) {
                // this.$refs[formName].resetFields();
                this.ruleForm = {
                    fans_characteristics_id: null,
                    score: void 0,
                }
                this.$emit('update:practiceForm', {
                    ...this.practiceForm,
                    name: '',
                    time: '',
                    module_level_one_value: '',
                    module_level_tow_value: '',
                    module_level_three_value: '',
                    classValue: [],
                    taskList: [],
                    taskBackground: '',
                    taskAnalysis: '',
                    taskOperation: '',
                    scoreList: [],
                })
                this.$router.push({
                    path: '/practice/index'
                })
            }
        }
    }
</script>

<style scoped lang="scss">
    .demo-ruleForm {
        ::v-deep {
            .el-form-item__label {
                color: #333;
                font-size: 16px;
            }
        }
    }
    .number-input {
        ::v-deep .el-input__inner {
            text-align: left;
        }
    }
</style>