<template>
    <div class="distribution-management">
        <div>
<!--            <div class="title-info">-->
<!--                <div class="item">-->
<!--                    <span class="text">粉丝特征名称</span>-->
<!--                    <el-select v-model="fansName" placeholder="请选择" clearable @change="fansChange"-->
<!--                               style="width: 420px;">-->
<!--                        <el-option v-for="fansItem in fansList" :key="fansItem.id" :label="fansItem.name"-->
<!--                                   :value="fansItem.id"></el-option>-->
<!--                    </el-select>-->
<!--                </div>-->
<!--            </div>-->
            <div class="title-info">
                <div class="optimize-title-top">
                    <div class="optimize-title01">发货地址管理</div>
                    <el-button type="primary" @click="scoringOperationBtn(1)">添加得分操作</el-button>
                </div>
                <el-table :data="addressList" style="width: 100%; flex: 1" size="medium" border
                          :header-cell-style="{fontWeight: 'normal', color: '#666', background: '#F6F6F6',fontSize: '16px',height:'60px'}"
                          :cell-style="{fontSize: '12px',color: '#333',height: '70px'}">
                    <el-table-column prop="where_address" label="所在地址"></el-table-column>
                    <el-table-column prop="detailed_address" label="详细地址" align="center"></el-table-column>
                    <el-table-column prop="postcode" label="邮编 " align="center">
                        <template slot-scope="scope">
                              <span>{{scope.row.postcode?scope.row.postcode:'未填写'}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="phone" label="联系电话 " align="center"></el-table-column>
                    <el-table-column prop="ship_name" label="发货人姓名 " align="center"></el-table-column>
                    <el-table-column prop="defualt" label="默认 " align="center">
                        <template slot-scope="scope">
                                 <span>{{scope.row.defualt===1?'是':'否'}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="score" label="分值 " align="center"></el-table-column>
                    <el-table-column label="操作" align="center" width="220px">
                        <template slot-scope="scope">
                            <div class="link-list">
                                <el-link type="success" :underline="false" @click="editAddress(scope.row,scope.$index, 1)">编辑</el-link>
                                <el-link type="danger" :underline="false" @click="deleteAddress(scope.$index)">删除</el-link>
                            </div>
                        </template>
                    </el-table-column>
                    <template slot="empty">
                        <div class="null-data">
                            <div style="display: flex;flex-direction: column;align-items: center;">
                                <span>暂无数据</span>
                            </div>
                        </div>
                    </template>
                </el-table>
            </div>
            <div class="title-info">
                <div class="optimize-title-top">
                    <div class="optimize-title01">备件库退货地址管理 </div>
                    <el-button type="primary" @click="scoringOperationBtn(2)">添加得分操作</el-button>
                </div>
                <el-table :data="spareList" style="width: 100%; flex: 1" size="medium" border
                          :header-cell-style="{fontWeight: 'normal', color: '#666', background: '#F6F6F6',fontSize: '16px',height:'60px'}"
                          :cell-style="{fontSize: '12px',color: '#333',height: '70px'}">
                    <el-table-column prop="where_address" label="退货地址"></el-table-column>
                    <el-table-column prop="detailed_address" label="详细地址" align="center"></el-table-column>
                    <el-table-column prop="postcode" label="邮编 " align="center">
                        <template slot-scope="scope">
                              <span>{{scope.row.postcode?scope.row.postcode:'未填写'}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="phone" label="联系电话 " align="center"></el-table-column>
                    <el-table-column prop="ship_name" label="联系人姓名 " align="center"></el-table-column>
                    <el-table-column prop="score" label="分值 " align="center"></el-table-column>
                    <el-table-column label="操作" align="center" width="220px">
                        <template slot-scope="scope">
                            <div class="link-list">
                                <el-link type="success" :underline="false" @click="editAddress(scope.row,scope.$index, 2)">编辑</el-link>
                                <el-link type="danger" :underline="false" @click="deleteAddress(scope.$index)">删除</el-link>
                            </div>
                        </template>
                    </el-table-column>
                    <template slot="empty">
                        <div class="null-data">
                            <div style="display: flex;flex-direction: column;align-items: center;">
                                <span>暂无数据</span>
                            </div>
                        </div>
                    </template>
                </el-table>
            </div>
            <div class="title-info">
                <div class="optimize-title-top">
                    <div class="optimize-title01">自主售后退货地址管理 </div>
                    <el-button type="primary" @click="scoringOperationBtn(3)">添加得分操作</el-button>
                </div>
                <el-table :data="saleList" style="width: 100%; flex: 1" size="medium" border
                          :header-cell-style="{fontWeight: 'normal', color: '#666', background: '#F6F6F6',fontSize: '16px',height:'60px'}"
                          :cell-style="{fontSize: '12px',color: '#333',height: '70px'}">
                    <el-table-column prop="where_address" label="退货地址"></el-table-column>
                    <el-table-column prop="detailed_address" label="详细地址" align="center"></el-table-column>
                    <el-table-column prop="postcode" label="邮编 " align="center">
                        <template slot-scope="scope">
                              <span>{{scope.row.postcode?scope.row.postcode:'未填写'}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="phone" label="联系电话 " align="center"></el-table-column>
                    <el-table-column prop="ship_name" label="联系人姓名 " align="center"></el-table-column>
                    <el-table-column prop="defualt" label="默认 " align="center">
                        <template slot-scope="scope">
                             <span>{{scope.row.defualt===1?'是':'否'}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="score" label="分值 " align="center"></el-table-column>
                    <el-table-column label="操作" align="center" width="220px">
                        <template slot-scope="scope">
                            <div class="link-list">
                                <el-link type="success" :underline="false" @click="editAddress(scope.row,scope.$index, 3)">编辑</el-link>
                                <el-link type="danger" :underline="false" @click="deleteAddress(scope.$index)">删除</el-link>
                            </div>
                        </template>
                    </el-table-column>
                    <template slot="empty">
                        <div class="null-data">
                            <div style="display: flex;flex-direction: column;align-items: center;">
                                <span>暂无数据</span>
                            </div>
                        </div>
                    </template>
                </el-table>
            </div>
            <!--S 运费模板-->
            <div class="title-info">
                <div class="optimize-title">运费模板</div>
                <div class="item">
                    <span class="text">模板名称</span>
                    <el-input v-model="goodsReleaseForm.freight_template_title" placeholder="请输入模板名称" style="width: 550px;"></el-input>
                </div>
                <div class="item">
                    <span class="text">是否包邮</span>
                    <el-radio-group v-model="goodsReleaseForm.free_radio" @change="changeFreeRadio()">
                        <el-radio :label="0">买家承担运费</el-radio>
                        <el-radio :label="1">卖家承担运费</el-radio>
                    </el-radio-group>
                </div>
                <div class="item">
                    <span class="text">计费规则</span>
                    <el-radio-group v-model="goodsReleaseForm.charging_radio" @change="chargingRadio()">
                        <el-radio :label="0">按件数</el-radio>
                        <el-radio :label="1">按重量</el-radio>
                    </el-radio-group>
                </div>
                <div class="item">
                    <div class="freight-title">运费设置</div>
                    <div class="freight-box">
                        <div class="freight-top">
                            <span>国内默认运费</span>
                            <el-input-number v-model="goodsReleaseForm.piece" :min="0" :precision="0" :controls="false" style="width: 100px;margin:0 10px"></el-input-number>
                            {{goodsReleaseForm.ruleUnit}}内，
                            <el-input-number v-model="goodsReleaseForm.element" :min="0" :precision="0" :controls="false" style="width: 100px;margin:0 10px"></el-input-number>
                            元，
                            <span>每增加</span>
                            <el-input-number v-model="goodsReleaseForm.add_num" :min="0" :precision="0" :controls="false" style="width: 100px;margin:0 10px"></el-input-number>
                            {{goodsReleaseForm.ruleUnit}}，
                            <span>增加</span>
                            <el-input-number v-model="goodsReleaseForm.add_price" :min="0" :precision="0" :controls="false" style="width: 100px;margin:0 10px"></el-input-number>
                            元
                        </div>
                        <el-table :data="freightTable" style="width: 100%; flex: 1" size="medium" border
                                  :header-cell-style="{fontWeight: 'normal', color: '#666', background: '#F6F6F6',fontSize: '16px',height:'60px'}"
                                  :cell-style="{fontSize: '12px',color: '#333',height: '70px'}"
                                  class="customTable">
                            <el-table-column prop="transport" label="运送到" width="520px">
                                <template slot-scope="scope">
                                    <div class="region-item">
                                        <div style="flex: 1">{{scope.row.city}}</div>
                                        <span @click="editRegion(scope.row.city,scope.$index)">编辑</span>
                                    </div>
                                </template>
                            </el-table-column>
                            <el-table-column prop="first_thing" label="（首价）个" align="center">
                                <template slot-scope="scope">
                                    <el-input-number v-model="scope.row.first_thing" :min="0" :precision="0" :controls="false" style="width: 80px;"></el-input-number>
                                </template>
                            </el-table-column>
                            <el-table-column prop="first_cost" label="（首费）元" align="center">
                                <template slot-scope="scope">
                                    <el-input-number v-model="scope.row.first_cost" :min="0" :precision="0" :controls="false" style="width: 80px;"></el-input-number>
                                </template>
                            </el-table-column>
                            <el-table-column prop="add_think" label="（续件）个" align="center">
                                <template slot-scope="scope">
                                    <el-input-number v-model="scope.row.add_think" :min="0" :precision="0" :controls="false" style="width: 80px;"></el-input-number>
                                </template>
                            </el-table-column>
                            <el-table-column prop="add_cost" label="（续费）元" align="center">
                                <template slot-scope="scope">
                                    <el-input-number v-model="scope.row.add_cost" :min="0" :precision="0" :controls="false" style="width: 80px;"></el-input-number>
                                </template>
                            </el-table-column>
                            <el-table-column label="操作" align="center">
                                <template slot-scope="scope">
                                    <div class="link-list">
                                        <el-link type="danger" :underline="false" @click="deleteFreight(scope.$index)">删除</el-link>
                                    </div>
                                </template>
                            </el-table-column>
                        </el-table>
                        <div class="add-freight" @click="addRegion()">+为指定地区城市设置运费</div>
                    </div>
                    <div class="freight-score">
                        <span>运送设置分值</span>
                        <el-input-number v-model="goodsReleaseForm.freight_score" :min="0" :precision="0" :controls="false" style="width: 100px; margin: 0 10px"></el-input-number>
                        分
                    </div>
                </div>
            </div>
            <!--E 运费模板-->
        </div>
        <div slot="footer" class="dialog-footer">
            <el-button size="medium" @click="cancel">取消</el-button>
            <el-button size="medium" type="primary" @click="saveDistributionManagement()">保存</el-button>
        </div>
        <el-dialog :close-on-click-modal="false" :close-on-press-escape="false" :title="addressTitle"
                   custom-class="green-dialog02" :visible.sync="addressDialogVisible" width="500px" @close="resetForm()">
            <el-form :model="addressForm" ref="addressForm" label-position="right" :rules="addressRules"
                     :hide-required-asterisk="true" label-width="112px">
                <el-form-item :label="titleRegion">
                    <div style="display: flex;">
                        <el-select v-model="addressForm.province" filterable placeholder="请选择省" style="width: 150px;margin-right: 10px;" @change="selectProvince">
                            <el-option
                                    v-for="item in provinceList"
                                    :key="item.area_id"
                                    :label="item.area_name"
                                    :value="item.area_name">
                            </el-option>
                        </el-select>
                        <el-select v-model="addressForm.city" filterable placeholder="请选择市" style="width: 150px;margin-right: 10px;" @change="selectCity">
                            <el-option
                                    v-for="item in cityList"
                                    :key="item.area_id"
                                    :label="item.area_name"
                                    :value="item.area_name">
                            </el-option>
                        </el-select>
                        <el-select v-model="addressForm.area" filterable placeholder="请选择区" style="width: 150px;margin-right: 10px;">
                            <el-option
                                    v-for="item in areaList"
                                    :key="item.area_id"
                                    :label="item.area_name"
                                    :value="item.area_name">
                            </el-option>
                        </el-select>
                    </div>
                </el-form-item>
                <el-form-item label="详细地址" prop="detailed_address">
                    <el-input v-model="addressForm.detailed_address"  autocomplete="off" style="width:340px"
                              placeholder="请输入详细地址" maxlength="100" show-word-limit ></el-input>
                </el-form-item>
                <el-form-item label="邮编"  prop="postcode">
                    <el-input v-model.number="addressForm.postcode" placeholder="请输入邮编" maxlength="6" clearable style="width: 340px"></el-input>
                </el-form-item>
                <el-form-item label="联系电话"  prop="phone">
                    <el-input v-model="addressForm.phone" placeholder="请输入联系电话" style="width:340px"></el-input>
                </el-form-item>
                <el-form-item label="发货人姓名"  prop="ship_name" v-if="tableType===1">
                    <el-input v-model="addressForm.ship_name" placeholder="请输入发货人姓名" style="width:340px" maxlength="5" show-word-limit></el-input>
                </el-form-item>
                <el-form-item label="联系人"  prop="ship_name" v-if="tableType===2||tableType===3">
                    <el-input v-model="addressForm.ship_name" placeholder="请输入联系人" style="width:340px" maxlength="5" show-word-limit></el-input>
                </el-form-item>
                <el-form-item label="是否默认"  prop="defualt" v-if="tableType===1||tableType===3">
                    <el-checkbox v-model="addressForm.defualt"></el-checkbox>
                </el-form-item>
                <el-form-item label="分值"  prop="score">
                    <el-input-number v-model="addressForm.score" :min="0.1" :max="100" :precision="1" :controls="false"  placeholder="请输入分值" style="width:340px"></el-input-number>
                </el-form-item>
            </el-form>
            <div class="dialog-footer">
                <el-button @click="addressDialogVisible = false">取 消</el-button>
                <el-button style="margin-left: 30px" type="primary" @click="addressBtn('addressForm')">确 定</el-button>
            </div>
        </el-dialog>
        <!--地区弹窗-->
        <el-dialog :close-on-click-modal="false" :close-on-press-escape="false" :title="addRegionTitle"
                   custom-class="green-dialog" :visible.sync="dialogVisible" width="650px">
            <el-scrollbar class="pop-up">
                <div class="area-box">
                    <el-checkbox v-model="checkedAllChina" @change="checkedAllChinaBtn">国内</el-checkbox>
                    <div class="area-list">
                        <div v-for="(item,index) in areaArr" :key="index" class="checkbox-label">
                            <el-checkbox v-model="item.checked" @change="checked=>areaChange(checked,index)">
                                {{item.name}}
                            </el-checkbox>
                        </div>
                    </div>
                </div>
            </el-scrollbar>
            <div class="dialog-footer">
                <el-button style="margin-left: 30px" type="primary" @click="addCityBtn()">确 定</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
    import {transactionGetArea,tranDistributionAdminDistributionCreate,operationProblemOpDetail} from '@/utils/apis'
    import { areaList } from '@/utils/area'
    export default {
        name: "DistributionManagement",
        props: ['practiceForm'],
        data(){
            //手机号验证
            let checkPhone = (rule, value, callback) => {
                if (!value) {
                    return callback(new Error('手机号不能为空'));
                } else {
                    const reg = /^1[3|4|5|6|7|8|9][0-9]\d{8}$/
                    if (reg.test(value)) {
                        callback();
                    } else {
                        return callback(new Error('请输入正确的手机号'));
                    }
                }
            };
            let checkPostcode = (rule, value, callback) => {
                if (value) {
                    const reg = /[1-9]\d{5}(?!\d)/
                    if (reg.test(value)) {
                        callback();
                    } else {
                        return callback(new Error('邮编格式不正确'));
                    }
                } else {
                    callback();
                }
            };
            return{
                //发货地址列表
                addressList:[],
                // 备件库退货地址
                spareList: [],
                // 自主售后退货地址
                saleList:[],
                addressTitle:'',
                addressDialogVisible:false,
                addressForm:{
                    where_address:'',
                    province:'',
                    city:'',
                    area:'',
                    detailed_address:'',
                    postcode:'',
                    phone:'',
                    defualt:'',
                    score:void 0,
                    ship_name:'',
                },
                provinceList:[],
                cityList:[],//市
                areaList:[],//区
                addressRules: {
                    where_address:[
                        {required: true, message: '请选择所在地区', trigger: ['blur', 'change']},
                    ],
                    detailed_address:[
                        {required: true, message: '请输入详细地址', trigger: 'blur'},
                        { min: 2, max: 100, message: '长度在 2 到 100 个字符', trigger: 'blur' }
                    ],
                    phone: {validator: checkPhone, trigger: ['blur', 'change']},
                    ship_name:[
                        {required: true, message: '请输入发货人姓名', trigger: 'blur'},
                        { min: 2, max: 5, message: '长度在 2 到 5 个字符', trigger: 'blur' }
                    ],
                    postcode: [{validator: checkPostcode, trigger: ['blur']},],
                    score: [{required: true, message: '请输入分值', trigger: ['blur']}]
                },
                //运费
                goodsReleaseForm: {
                    freight_template_title: '',//模板名称
                    free_radio: 0,//是否包邮
                    charging_radio: 0,//计费规则
                    piece: void 0,//
                    element: void 0,
                    add_num: void 0,//
                    add_price: void 0,
                    freight_score: void 0,
                    ruleUnit: '件'//规则单位
                },
                //运费设置
                freightTable: [],
                freightTableIndex:-1,
                // 地区
                addRegionTitle: '',//地区弹窗标题
                dialogVisible: false,
                checkAll: false,
                checkedCities: [],
                // cityOptions: [],
                areaArr: [],
                isIndeterminate: true,//区显示隐藏
                checkedAllChina: false,//国内变量
                tableType:'',//表格类型
                titleRegion:'',//弹窗地区名称
            }
        },
        mounted() {
            this.getAreaList();
            //循环地区列表
            for (let i in areaList) {
                let ob = {
                    checked: false,
                    name: areaList[i].name
                }
                this.areaArr.push(ob)
            }
            if (this.$route.query.id){
                this.getOperationProblemOpDetail()
            }
        },
        methods:{
            //获取详情
            getOperationProblemOpDetail(){
                let param = {
                    id: this.$route.query.id
                }
                operationProblemOpDetail(param).then((res) => {
                    this.addressList=res.data.deliver_address;
                    this.spareList.push(res.data.sales_return_address);
                    this.saleList=res.data.after_sales_return_address;
                    this.goodsReleaseForm.freight_template_title=res.data.freight_template_title;
                    this.goodsReleaseForm.free_radio=res.data.is_exempt_postage;
                    this.goodsReleaseForm.charging_radio=res.data.accounting_rules;
                    this.goodsReleaseForm.piece=res.data.default_num;
                    this.goodsReleaseForm.element=res.data.default_price;
                    this.goodsReleaseForm.add_num=res.data.add_num;
                    this.goodsReleaseForm.add_price=res.data.add_price;
                    this.goodsReleaseForm.freight_score=res.data.freight_score;
                    if (res.data.freight_setting == undefined) {
                        this.freightTable=[];
                    } else {
                        this.freightTable=res.data.freight_setting;
                    };
                    
                }).catch((err) => {
                    console.log(err);
                })
            },
            //获取地区列表
            getAreaList() {
                transactionGetArea().then((res) => {
                    this.provinceList = res.data;
                }).catch((err)  => {
                    console.log(err);
                })
            },
            //根据省选择市
            selectProvince(val) {
                if (val) {
                    this.provinceList.forEach(item => {
                        if (item.area_name === val) {
                            this.cityList = item.children_city;
                        }
                    })
                }
                this.addressForm.city = this.cityList[0].area_name
                this.selectCity(this.addressForm.city)
            },
            //根据市选择区
            selectCity(val){
                if (val) {
                    this.cityList.forEach(item => {
                        if (item.area_name === val) {
                            this.areaList = item.children_region;
                        }
                    })
                }
                this.addressForm.area = this.areaList[0].area_name
            },
            //添加得分操作
            scoringOperationBtn(type){
                this.tableType = type;
                if(type===2 && this.spareList.length>0){
                    return  this.$message.warning({message: ('备件退货地址最多只能一条数据'),
                    })
                };
                if(type===1){
                    this.titleRegion='所在地区'
                }else if(type===2||type===3){
                    this.titleRegion='退货地址'
                };
                this.addressDialogVisible = true;
                this.addressTitle='添加得分操作';
                this.addressTableIndex = -1;
            },
            // 编辑
            editAddress(row,index, type){
                this.tableType = type;
                if(type===1){
                    this.titleRegion='所在地区'
                }else if(type===2||type===3){
                    this.titleRegion='退货地址'
                };
                this.addressTitle='编辑得分操作';
                this.addressDialogVisible=true
                if (this.tableType===1){
                    this.addressForm.address= row.where_address;
                    this.addressForm.province= row.where_address.split(' ')[0];
                    this.addressForm.city= row.where_address.split(' ')[1];
                    this.addressForm.area= row.where_address.split(' ')[2];
                    this.addressForm.detailed_address= row.detailed_address;
                    this.addressForm.postcode= row.postcode;
                    this.addressForm.phone= row.phone;
                    this.addressForm.ship_name= row.ship_name;
                    this.addressForm.defualt= row.defualt===1;
                    this.addressForm.score= row.score;
                }else if(this.tableType===2){
                    this.addressForm.address= row.where_address;
                    this.addressForm.province= row.where_address.split(' ')[0];
                    this.addressForm.city= row.where_address.split(' ')[1];
                    this.addressForm.area= row.where_address.split(' ')[2];
                    this.addressForm.detailed_address= row.detailed_address;
                    this.addressForm.postcode= row.postcode;
                    this.addressForm.phone= row.phone;
                    this.addressForm.ship_name= row.ship_name;
                    this.addressForm.score= row.score;
                }else {
                    this.addressForm.address= row.where_address;
                    this.addressForm.province= row.where_address.split(' ')[0];
                    this.addressForm.city= row.where_address.split(' ')[1];
                    this.addressForm.area= row.where_address.split(' ')[2];
                    this.addressForm.detailed_address= row.detailed_address;
                    this.addressForm.postcode= row.postcode;
                    this.addressForm.phone= row.phone;
                    this.addressForm.ship_name= row.ship_name;
                    this.addressForm.defualt= row.defualt===1;
                    this.addressForm.score= row.score;
                }
                this.addressTableIndex = index;
            },
            //确定
            addressBtn(formName){
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        let where_address = this.addressForm.province + " " + this.addressForm.city + " " + this.addressForm.area
                        if(this.tableType===1){
                            let ob = {
                                where_address:where_address,
                                detailed_address:this.addressForm.detailed_address,
                                postcode:this.addressForm.postcode,
                                phone:this.addressForm.phone,
                                ship_name:this.addressForm.ship_name,
                                defualt:this.addressForm.defualt?1:0,
                                score:this.addressForm.score,
                            }
                            if (this.addressTableIndex>-1){
                                //    编辑
                                // this.addressList[this.addressTableIndex] = ob;
                                this.$set(this.addressList, this.addressTableIndex, ob);
                            }else{
                                //    新增
                                this.addressList.push(ob);
                            }
                            let defaultIndex = -1
                            for(let i = 0; i < this.addressList.length; i++){
                                if(this.addressList[i].defualt===1){
                                    defaultIndex = i;
                                    break;
                                }
                            }
                            if(defaultIndex > -1){
                                this.addressList.forEach((item,index)=>{
                                    if(index !==defaultIndex){
                                        item.defualt = 0
                                    }
                                });
                            }
                        }else if(this.tableType===2){
                            let ob = {
                                where_address:where_address,
                                detailed_address:this.addressForm.detailed_address,
                                postcode:this.addressForm.postcode,
                                phone:this.addressForm.phone,
                                ship_name:this.addressForm.ship_name,
                                score:this.addressForm.score,
                            }
                            if (this.addressTableIndex>-1){
                                //    编辑
                                // this.spareList[this.addressTableIndex] = ob;
                                this.$set(this.spareList, this.addressTableIndex, ob);
                            }else{
                                //    新增
                                this.spareList.push(ob);
                            }
                        }else{
                            let ob = {
                                where_address:where_address,
                                detailed_address:this.addressForm.detailed_address,
                                postcode:this.addressForm.postcode,
                                phone:this.addressForm.phone,
                                ship_name:this.addressForm.ship_name,
                                defualt:this.addressForm.defualt?1:0,
                                score:this.addressForm.score,
                            }
                            if (this.addressTableIndex>-1){
                                //    编辑
                                // this.saleList[this.addressTableIndex] = ob;
                                this.$set(this.saleList, this.addressTableIndex, ob);
                            }else{
                                //    新增
                                this.saleList.push(ob);
                            }
                        }
                        this.resetForm()
                        this.addressDialogVisible=false;
                    } else {
                        return false;
                    }
                });
            },
            //删除
            deleteAddress(index){
                this.addressList.splice(index, 1);
            },
            //保存
            saveDistributionManagement(){
                let param = {
                    op_name: this.practiceForm.name,
                    exam_module_id: this.practiceForm.module_level_three_value,
                    category_id: this.practiceForm.classValue[this.practiceForm.classValue.length - 1],
                    assess_time: this.practiceForm.time,
                    task_goal: this.practiceForm.taskList,
                    task_background: this.practiceForm.taskBackground,
                    task_analysis: this.practiceForm.taskAnalysis,
                    task_operation: this.practiceForm.taskOperation,
                    scoring_criteria:this.practiceForm.scoreList,
                    deliver_address: this.addressList,
                    sales_return_address:this.spareList[0],
                    after_sales_return_address:this.saleList,
                    freight_template_title:this.goodsReleaseForm.freight_template_title,
                    is_exempt_postage: this.goodsReleaseForm.free_radio,
                    accounting_rules: this.goodsReleaseForm.charging_radio,
                    default_num: this.goodsReleaseForm.piece,
                    default_price: this.goodsReleaseForm.element,
                    add_num: this.goodsReleaseForm.add_num,
                    add_price: this.goodsReleaseForm.add_price,
                    freight_setting:this.freightTable,
                    freight_score: this.goodsReleaseForm.freight_score,
                }
                if (this.$route.query.id) {
                    param.id = this.$route.query.id;
                }
                tranDistributionAdminDistributionCreate(param).then((res) => {
                    this.$message.success(res.msg)
                    this.$router.push('/practice/index')
                }).catch((err) => {
                    console.error('err', err);
                });
            },
            //地区选择
            addRegion() {
                this.addRegionTitle = '选择地区'
                this.dialogVisible = true
            },
            //是否包邮点击事件
            changeFreeRadio() {
                if (this.goodsReleaseForm.free_radio === 1) {
                    this.$confirm(`选择“卖家承担运费”后，所有区域的运费将设置为0元 且原运费设置无法恢复`, '提示消息', {
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                        customClass: 'green-message-box',
                    }).then(() => {
                        this.$message({
                            message: ('切换成功'),
                            type: 'success',
                        })
                    }).catch((cancel) => {
                        this.goodsReleaseForm.free_radio = 0;
                        this.$message({
                            type: 'info',
                            message: '已取消'
                        });
                    });
                }
            },
            //计费规则
            chargingRadio() {
                this.$confirm(`确定切换计费规则吗？，切换后，当前所设置的运费信息将被清空`, '提示消息', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    customClass: 'green-message-box',
                }).then(() => {
                    this.$message({
                        message: ('切换成功'),
                        type: 'success',
                    })
                    if (this.goodsReleaseForm.charging_radio===0){
                        this.goodsReleaseForm.ruleUnit = '件'
                    }else {
                        this.goodsReleaseForm.ruleUnit = 'kg'
                    }
                }).catch(() => {
                    if (this.goodsReleaseForm.charging_radio===0){
                        this.goodsReleaseForm.charging_radio = 1;
                    }else {
                        this.goodsReleaseForm.charging_radio = 0;
                    }
                    this.$message({
                        type: 'info',
                        message: '已取消'
                    });
                });
            },
            // 编辑地区
            editRegion(city, index) {
                this.freightTableIndex = index;
                this.addRegionTitle = '编辑地区'
                this.addressDialogVisible = true;
                let arr = city.split(" ");
                for (let i in this.areaArr) {
                    for (let k in arr) {
                        if (arr[k] === this.areaArr[i].name) {
                            this.areaArr[i].checked = true;
                        }
                    }
                }
                let isAll = true;
                for(let i in this.areaArr){
                    if(!this.areaArr[i].checked){
                        isAll =false;
                        break;
                    }
                }
                this.checkedAllChina = isAll
            },
            // 全国
            checkedAllChinaBtn(val) {
                this.checkedAllChina = val;
                for (let i in this.areaArr) {
                    this.areaArr[i].checked = this.checkedAllChina;
                }
            },
            areaChange(e, index) {
                this.areaArr[index].checked = e;
                let isAll = true;
                for (let i in this.areaArr) {
                    if (!this.areaArr[i].checked) {
                        isAll = false;
                        break;
                    }
                }
                this.checkedAllChina = isAll;
            },
            // 地区确定事件
            addCityBtn() {
                let arr = [];
                for (let i in this.areaArr) {
                    if (this.areaArr[i].checked) {
                        arr = arr.concat(this.areaArr[i].name);
                    }
                }
                console.log(this.freightTable);
                if (this.freightTableIndex > -1) {
                    this.freightTable[this.freightTableIndex].city = arr.join(' ');
                } else {
                    let ob = {
                        city: arr.join(' '),
                        first_thing: void 0,
                        first_cost: void 0,
                        add_think: void 0,
                        add_cost: void 0,
                    }
                    if (arr != '') {
                        this.freightTable.push(ob)
                    }
                    
                }
                this.dialogVisible = false;
                for (let i in this.areaArr) {
                    this.areaArr[i].checked = false;
                }
                this.checkedAllChina = false;
                this.freightTableIndex = -1;
            },
            //运单删除
            deleteFreight(index) {
                this.freightTable.splice(index,1);
            },
            // 重置表单
            resetForm(){
                this.$refs.addressForm.resetFields();
                this.addressForm = this.$options.data().addressForm;
            },
            //取消
            cancel(){
                this.$router.push({
                    path: '/practice/index'
                })
            },
        }
    }
</script>

<style scoped lang="scss">
    .distribution-management{
        display: flex;
        flex-direction: column;
        height: 100%;
        margin-top: 30px;
        .title-info {
            margin-top: 34px;
            .optimize-title {
                font-size: 16px;
                color: #333;
                position: relative;
                padding: 0 0 0 16px;
                margin-bottom: 20px;
                &:before {
                    content: '';
                    position: absolute;
                    width: 4px;
                    height: 16px;
                    background: #2DC079;
                    left: 0;
                    top: 4px;
                }
            }
            .optimize-title-top {
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin-bottom: 10px;
                .optimize-title01 {
                    font-size: 16px;
                    color: #333;
                    position: relative;
                    padding-left: 16px;
                    &:before {
                        content: '';
                        position: absolute;
                        width: 4px;
                        height: 16px;
                        background: #2DC079;
                        left: 0;
                        top: 4px;
                    }
                }
            }
            .item {
                margin-bottom: 16px;
                .text {
                    padding-right: 12px;
                    display: inline-block;
                    min-width: 82px;
                }
            }
            .item {
                margin-bottom: 16px;
                .text {
                    padding-right: 12px;
                    display: inline-block;
                    min-width: 82px;
                }
                .freight-box {
                    border: 1px solid #eee;
                    .freight-top {
                        background: #F6F6F6;
                        padding: 20px 47px;
                    }
                    .region-item {
                        display: flex;
                        span {
                            display: inline-block;
                            width: 40px;
                            color: #2461EF;
                            padding-left: 20px;
                            align-self: center;
                            cursor: pointer;
                        }
                    }
                    .add-freight {
                        cursor: pointer;
                        padding: 20px;
                        color: #2461EF;
                    }
                }
                .freight-title {
                    margin-bottom: 15px;
                }
                .freight-score {
                    margin-top: 12px;
                }
            }
        }
        .pop-up{
            height:300px;
            ::v-deep .el-scrollbar__wrap {
                overflow-x: hidden;
            }
            .area-box {
                display: flex;
                .area-list {
                    display: flex;
                    flex-wrap: wrap;
                    padding-left: 45px;
                    .checkbox-label {
                        width: 104px;
                        margin-bottom: 10px;
                        i {
                            cursor: pointer;
                        }
                    }
                }
                .checkbox-group {
                    margin: 10px 0;
                    ::v-deep .el-checkbox-group {
                        display: flex;
                        flex-wrap: wrap;
                        /*width: 100px;*/
                        /*background-color: #fff;*/
                        /*box-shadow: 2px 2px 2px 3px #eee;*/
                        padding-left: 10px;
                    }
                    /*::v-deep .el-checkbox {*/
                    /*    width: 58px;*/
                    /*}*/
                }
            }
        }
        .dialog-footer {
            margin-top: 20px;
            text-align: center;
        }
        ::v-deep .el-input-number {
            .el-input__inner {
                text-align: left;
            }
        }
    }

</style>