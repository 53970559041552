<template>
    <div>
        <div class="line-item" style="margin-top: 30px">
            <div class="left">客户信息</div>
            <div class="right">
                <el-select v-model="ruleForm.customer_id" placeholder="请选择客户信息" style="width: 360px" @change="clientInfoChange">
                    <el-option
                        v-for="(item, index) in customerList"
                        :key="`customerList_${index}`"
                        :label="item.name"
                        :value="item.customer_info_group_id"
                    ></el-option>
                </el-select>
            </div>
        </div>
        <div class="client-info-table" v-if="clientInfoTableData">
            <div class="create-content-table">
                <!-- <el-scrollbar> -->
                <el-table
                    :data="clientInfoTableData"
                    style="width: 100%; flex: 1"
                    size="medium"
                    :tree-props="{ children: 'order_data', hasChildren: 'hasChildren' }"
                    :header-cell-style="{
                        fontWeight: 'normal',
                        color: '#666',
                        background: '#eee',
                        fontSize: '16px',
                        height: '60px',
                    }"
                    class="customTable"
                    :cell-style="{ fontSize: '12px', color: '#333', height: '70px' }"
                >
                    <el-table-column prop="nick_name" label="客户名称"></el-table-column>
                    <!-- <el-table-column prop="name" label="客户姓名" align="center"></el-table-column> -->
                    <el-table-column prop="price" label="客单价（元）" align="center"></el-table-column>
                    <el-table-column prop="buy_time" label="购买时间" align="center">
                        <!-- <template slot-scope="scope" v-if="scope.row.buy_time != ''">{{
                                timeFormat(scope.row.buy_time)
                            }}</template> -->
                        <template slot-scope="scope" v-if="scope.row.buy_time != ''">
                            {{ scope.row.buy_time }}
                        </template>
                    </el-table-column>
                    <el-table-column prop="heart" label="评价" align="center" style="width: 146px">
                        <template slot-scope="scope">
                            <div class="evaluate-box">
                                <el-rate v-model="scope.row.heart" disabled text-color="#ff9900"> </el-rate>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="order_num" label="交易笔数" align="center" style="width: 146px"></el-table-column>
                    <el-table-column prop="change_num" label="订单改价次数" align="center"></el-table-column>
                    <el-table-column label="分值" align="center" width="220px">
                        <template slot-scope="scope">
                            <div class="score">
                                <input
                                    class="client-score"
                                    type="text"
                                    maxlength="3"
                                    v-model="clientInfoScoreList[scope.$index].score"
                                    @keyup="(e) => (e.target.value = e.target.value.replace(/[^0-9]+/, ''))"
                                />
                                <!-- <el-link
                                        type="primary"
                                        v-if="'order_data' in scope.row"
                                        :underline="false"
                                        @click="addPurchase(scope.row, scope.$index)"
                                        >增加交易笔数
                                    </el-link>
                                    <el-link type="primary" v-else :underline="false" style="visibility: hidden">增加交易笔数</el-link> -->
                                <!-- <el-link type="success" :underline="false" @click="editPurchase(scope.row, scope.$index)">
                                        编辑
                                    </el-link>
                                    <el-link type="danger" :underline="false" @click="deletePurchase(scope.row, scope.$index)"
                                        >删除</el-link
                                    > -->
                            </div>
                        </template>
                    </el-table-column>
                </el-table>
                <!-- </el-scrollbar> -->
            </div>
        </div>
        <!-- <div class="second-title"><span>客户信息收集</span></div>
        <div class="line-item">
            <div class="left">交易笔数</div>
            <div class="right">
                <el-input-number
                    class="number-input"
                    v-model="ruleForm.transaction_num"
                    :precision="0"
                    :controls="false"
                    :min="1"
                    style="width: 360px"
                    placeholder="请输入需要收集多少次交易的客户信息"
                ></el-input-number>
            </div>
        </div>
        <div class="line-item">
            <div class="left">分值</div>
            <div class="right">
                <el-input-number
                    class="number-input"
                    v-model="ruleForm.transaction_num_score"
                    :precision="1"
                    :controls="false"
                    :min="0.1"
                    :max="100.0"
                    style="width: 250px"
                ></el-input-number>
                <span class="prompt-text">分</span>
            </div>
        </div> -->

        <div class="second-title">
            <span>商品榜单</span>
            <el-button type="primary" @click="addGoods">添加商品</el-button>
        </div>
        <el-table :data="ruleForm.goodsList" class="customTable" style="width: 100%">
            <el-table-column label="商品信息" min-width="150">
                <template slot-scope="scope">
                    <div class="goods-wrapper">
                        <div class="goods-cover">
                            <img :src="scope.row.goods_img" alt="" />
                        </div>
                        <div class="text-overflow-2 goods-name" :title="scope.row.goods_name">{{ scope.row.goods_name }}</div>
                    </div>
                </template>
            </el-table-column>
            <el-table-column label="成交金额指数" align="center">
                <template slot-scope="scope">{{ scope.row.turnover_index.toFixed(2) }}</template>
            </el-table-column>
            <el-table-column label="成交单量指数" align="center">
                <template slot-scope="scope">{{ scope.row.transaction_volume_index.toFixed(2) }}</template>
            </el-table-column>
            <el-table-column label="关注人数" align="center">
                <template slot-scope="scope">{{ scope.row.follow_num_total.toFixed(0) }}</template>
            </el-table-column>
            <el-table-column label="访客指数" align="center">
                <template slot-scope="scope">{{ scope.row.visitor_index.toFixed(2) }}</template>
            </el-table-column>
            <el-table-column label="搜索点击指数" align="center">
                <template slot-scope="scope">{{ scope.row.search_click_index.toFixed(2) }}</template>
            </el-table-column>
            <el-table-column label="操作" align="center">
                <template slot-scope="scope">
                    <el-link type="success" :underline="false" @click="editGoodBtn(scope.row.id)">编辑</el-link>
                    <el-link type="danger" :underline="false" @click="delGoodBtn(scope.row.id, scope.$index)">删除</el-link>
                </template>
            </el-table-column>
        </el-table>
        <div class="flex">
            <div class="line-item" style="margin-top: 40px">
                <div class="left">采购商品</div>
                <div class="right">
                    <el-select
                        v-model="ruleForm.odc_goods_id"
                        placeholder="请选择商品"
                        style="width: 360px"
                        :disabled="ruleForm.goodsList.length === 0"
                    >
                        <el-option
                            v-for="(item, index) in ruleForm.goodsList"
                            :key="`customerList_${index}`"
                            :label="item.goods_name"
                            :value="item.id"
                        ></el-option>
                    </el-select>
                </div>
            </div>
            <div class="line-item" style="margin-top: 40px">
                <div class="left">分值</div>
                <div class="right">
                    <!-- <el-input-number
                        class="number-input"
                        v-model="ruleForm.odc_goods_score"
                        :precision="1"
                        :controls="false"
                        :min="0.1"
                        :max="100.0"
                        style="width: 120px"
                    ></el-input-number> -->
                    <input
                        class="client-score"
                        type="text"
                        maxlength="3"
                        v-model="ruleForm.odc_goods_score"
                        @keyup="(e) => (e.target.value = e.target.value.replace(/[^0-9]+/, ''))"
                    />
                    <span class="prompt-text">分</span>
                </div>
            </div>
        </div>
        <div class="line-item" style="margin: 45px 0 22px; justify-content: center">
            <el-button type="primary" @click="submitForm">保存</el-button>
            <el-button @click="resetForm">取消</el-button>
        </div>

        <el-dialog
            title="添加商品"
            :visible.sync="dialogVisible"
            width="500px"
            custom-class="custom-dialog"
            :close-on-click-modal="false"
            @opened="openedDialog"
            @close="closeDialog"
        >
            <el-form
                :model="addGoodsForm"
                :rules="rules"
                ref="addGoodsForm"
                label-width="100px"
                class="demo-ruleForm"
                :hide-required-asterisk="true"
            >
                <el-form-item label="商品类目" prop="good_category_id">
                    <el-cascader
                        filterable
                        v-model="addGoodsForm.good_category_id"
                        :props="keyObj"
                        style="width: 100%"
                        :options="goodClassList"
                        @change="changeClass"
                        clearable
                        placeholder="请选择商品类目"
                    ></el-cascader>
                </el-form-item>
                <el-form-item label="商品" prop="goods_id">
                    <el-select
                        filterable
                        v-model="addGoodsForm.goods_id"
                        placeholder="请选择商品"
                        style="width: 100%"
                        :disabled="goodsListForm.length === 0"
                    >
                        <el-option
                            v-for="(item, index) in goodsListForm"
                            :key="`goodsList_${index}`"
                            :label="item.good_title"
                            :value="item.id"
                        ></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="搜索人数" prop="search_number">
                    <el-input-number
                        class="number-input"
                        v-model="addGoodsForm.search_number"
                        :precision="0"
                        :controls="false"
                        :min="1"
                        style="width: 100%"
                    ></el-input-number>
                </el-form-item>
                <div class="flex">
                    <el-form-item label="波动范围(%)"></el-form-item>
                    <el-form-item prop="search_number_wave_lower" label-width="0">
                        <el-input-number
                            class="number-input"
                            v-model="addGoodsForm.search_number_wave_lower"
                            :precision="0"
                            :controls="false"
                            :min="-99"
                            :max="addGoodsForm.search_number_wave_upper ? addGoodsForm.search_number_wave_upper - 1 : 99"
                            style="width: 100%"
                        ></el-input-number>
                    </el-form-item>
                    <span style="margin: 0 12px; line-height: 40px">~</span>
                    <el-form-item prop="search_number_wave_upper" label-width="0">
                        <el-input-number
                            class="number-input"
                            v-model="addGoodsForm.search_number_wave_upper"
                            :precision="0"
                            :controls="false"
                            :min="addGoodsForm.search_number_wave_lower ? addGoodsForm.search_number_wave_lower + 1 : -98"
                            :max="100"
                            style="width: 100%"
                        ></el-input-number>
                    </el-form-item>
                </div>

                <el-form-item label="关注人数" prop="follow_num">
                    <el-input-number
                        class="number-input"
                        v-model="addGoodsForm.follow_num"
                        :precision="0"
                        :controls="false"
                        :min="1"
                        style="width: 100%"
                    ></el-input-number>
                </el-form-item>
                <div class="flex">
                    <el-form-item label="波动范围(%)"></el-form-item>
                    <el-form-item prop="follow_num_wave_lower" label-width="0">
                        <el-input-number
                            class="number-input"
                            v-model="addGoodsForm.follow_num_wave_lower"
                            :precision="0"
                            :controls="false"
                            :min="-99"
                            :max="addGoodsForm.follow_num_wave_upper ? addGoodsForm.follow_num_wave_upper - 1 : 99"
                            style="width: 100%"
                        ></el-input-number>
                    </el-form-item>
                    <span style="margin: 0 12px; line-height: 40px">~</span>
                    <el-form-item prop="follow_num_wave_upper" label-width="0">
                        <el-input-number
                            class="number-input"
                            v-model="addGoodsForm.follow_num_wave_upper"
                            :precision="0"
                            :controls="false"
                            :min="addGoodsForm.follow_num_wave_lower ? addGoodsForm.follow_num_wave_lower + 1 : -98"
                            :max="100"
                            style="width: 100%"
                        ></el-input-number>
                    </el-form-item>
                </div>

                <el-form-item label="点击率(%)" prop="click_rate">
                    <el-input-number
                        class="number-input"
                        v-model="addGoodsForm.click_rate"
                        :precision="2"
                        :controls="false"
                        :min="0.01"
                        :max="100"
                        style="width: 100%"
                    ></el-input-number>
                </el-form-item>
                <div class="flex">
                    <el-form-item label="波动范围(%)"></el-form-item>
                    <el-form-item prop="click_rate_wave_lower" label-width="0">
                        <el-input-number
                            class="number-input"
                            v-model="addGoodsForm.click_rate_wave_lower"
                            :precision="0"
                            :controls="false"
                            :min="-99"
                            :max="addGoodsForm.click_rate_wave_upper ? addGoodsForm.click_rate_wave_upper - 1 : 99"
                            style="width: 100%"
                        ></el-input-number>
                    </el-form-item>
                    <span style="margin: 0 12px; line-height: 40px">~</span>
                    <el-form-item prop="click_rate_wave_upper" label-width="0">
                        <el-input-number
                            class="number-input"
                            v-model="addGoodsForm.click_rate_wave_upper"
                            :precision="0"
                            :controls="false"
                            :min="addGoodsForm.click_rate_wave_lower ? addGoodsForm.click_rate_wave_lower + 1 : -98"
                            :max="100"
                            style="width: 100%"
                        ></el-input-number>
                    </el-form-item>
                </div>

                <el-form-item label="转化率(%)" prop="conversion_rate">
                    <el-input-number
                        class="number-input"
                        v-model="addGoodsForm.conversion_rate"
                        :precision="2"
                        :controls="false"
                        :min="0.01"
                        :max="100"
                        style="width: 100%"
                    ></el-input-number>
                </el-form-item>
                <div class="flex">
                    <el-form-item label="波动范围(%)" style="margin-bottom: 0"></el-form-item>
                    <el-form-item prop="conversion_rate_wave_lower" label-width="0" style="margin-bottom: 0">
                        <el-input-number
                            class="number-input"
                            v-model="addGoodsForm.conversion_rate_wave_lower"
                            :precision="0"
                            :controls="false"
                            :min="-99"
                            :max="addGoodsForm.conversion_rate_wave_upper ? addGoodsForm.conversion_rate_wave_upper - 1 : 99"
                            style="width: 100%"
                        ></el-input-number>
                    </el-form-item>
                    <span style="margin: 0 12px; line-height: 40px">~</span>
                    <el-form-item prop="conversion_rate_wave_upper" label-width="0" style="margin-bottom: 0">
                        <el-input-number
                            class="number-input"
                            v-model="addGoodsForm.conversion_rate_wave_upper"
                            :precision="0"
                            :controls="false"
                            :min="addGoodsForm.conversion_rate_wave_lower ? addGoodsForm.conversion_rate_wave_lower + 1 : -98"
                            :max="100"
                            style="width: 100%"
                        ></el-input-number>
                    </el-form-item>
                </div>
            </el-form>
            <div slot="footer" style="text-align: center">
                <el-button @click="dialogVisible = false">取消</el-button>
                <el-button type="primary" @click="submitGoodsForm('addGoodsForm')" style="margin-left: 30px">保存</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import {
    customerInfoGetDetail,
    adminGoodGetCategory,
    adminGoodListGood,
    operationProblemSaveDataCollect,
    customerInfoGetModuleList,
    operationProblemOpDetail,
    operationProblemSaveDataCollectGoods,
    operationProblemDataCollectGoodsDetail,
    operationProblemDataCollectDelGoods,
} from "@/utils/apis.js";
export default {
    props: {
        practiceForm: {
            type: Object,
            default: () => {},
        },
    },
    data() {
        return {
            clientInfoTableData: null,
            clientInfoScoreList: [],
            ruleForm: {
                customer_id: null,
                transaction_num: void 0,
                transaction_num_score: void 0,
                goodsList: [],
                odc_goods_id: null,
                odc_goods_score: void 0,
            },
            customerList: [], // 客户信息列表
            // S 弹窗
            dialogVisible: false,
            addGoodsForm: {
                id: null,
                good_category_id: [],
                goods_id: null,
                search_number: void 0, // 搜索人数
                search_number_wave_lower: void 0,
                search_number_wave_upper: void 0,
                follow_num: void 0, // 关注人数
                follow_num_wave_lower: void 0,
                follow_num_wave_upper: void 0,
                click_rate: void 0, // 点击率
                click_rate_wave_lower: void 0,
                click_rate_wave_upper: void 0,
                conversion_rate: void 0, // 转化率
                conversion_rate_wave_lower: void 0,
                conversion_rate_wave_upper: void 0,
            },

            rules: {
                good_category_id: [{ required: true, message: "请选择商品类目", trigger: "change" }],
                goods_id: { required: true, message: "请选择商品", trigger: "change" },
                search_number: { required: true, message: "请输入搜索人数", trigger: "blur" },
                search_number_wave_lower: { required: true, message: "请输入波动范围低值", trigger: "blur" },
                search_number_wave_upper: { required: true, message: "请输入波动范围高值", trigger: "blur" },
                follow_num: { required: true, message: "请输入关注人数", trigger: "blur" },
                follow_num_wave_lower: { required: true, message: "请输入波动范围低值", trigger: "blur" },
                follow_num_wave_upper: { required: true, message: "请输入波动范围高值", trigger: "blur" },
                click_rate: { required: true, message: "请输入点击率", trigger: "blur" },
                click_rate_wave_lower: { required: true, message: "请输入波动范围低值", trigger: "blur" },
                click_rate_wave_upper: { required: true, message: "请输入波动范围高值", trigger: "blur" },
                conversion_rate: { required: true, message: "请输入转化率", trigger: "blur" },
                conversion_rate_wave_lower: { required: true, message: "请输入波动范围低值", trigger: "blur" },
                conversion_rate_wave_upper: { required: true, message: "请输入波动范围高值", trigger: "blur" },
            },
            keyObj: {
                label: "name",
                value: "id",
                children: "children",
            },
            goodClassList: [], // 商品类目
            goodsListForm: [], // 商品列表
            isEdit: null,
            // E 弹窗
        };
    },

    watch: {
        "addGoodsForm.search_number_wave_lower"(val) {
            if (this.addGoodsForm.search_number_wave_upper <= val) {
                this.addGoodsForm.search_number_wave_upper = val + 1;
            }
        },
        "addGoodsForm.follow_num_wave_lower"(val) {
            if (this.addGoodsForm.follow_num_wave_upper <= val) {
                this.addGoodsForm.follow_num_wave_upper = val + 1;
            }
        },
        "addGoodsForm.click_rate_wave_lower"(val) {
            if (this.addGoodsForm.click_rate_wave_upper <= val) {
                this.addGoodsForm.click_rate_wave_upper = val + 1;
            }
        },
        "addGoodsForm.conversion_rate_wave_lower"(val) {
            if (this.addGoodsForm.conversion_rate_wave_upper <= val) {
                this.addGoodsForm.conversion_rate_wave_upper = val + 1;
            }
        },
    },
    mounted() {
        // this.getGoodsCategory()
        this.getCustomerList();
        if (this.$route.query.id) {
            this.initDetail();
        }
    },
    methods: {
        async clientInfoChange(value) {
            const res = await customerInfoGetDetail({ customer_info_group_id: value });
            if (res.code != 200) {
                this.$message.error(res.msg);
                return void 0;
            }
            this.clientInfoScoreList = res.data.list.map((item) => {
                const target = this.clientInfoScoreList.find(
                    (subItem) => subItem.customer_info_order_id == item.customer_info_order_id
                );
                return {
                    customer_info_order_id: item.customer_info_order_id,
                    score: target ? target.score : "",
                };
            });
            // res.data.list.forEach((item) => {
            //     const isExist = this.clientInfoScoreList.find(
            //         (subItem) => subItem.customer_info_order_id == item.customer_info_order_id
            //     );
            //     // isExist || this.clientInfoScoreList.push({ customer_info_order_id: item.customer_info_order_id, score: "" });

            // });
            this.clientInfoTableData = res.data.list;
        },
        // 编辑获取详情
        initDetail() {
            operationProblemOpDetail({ id: this.$route.query.id }).then((res) => {
                let tmp = [];
                this.clientInfoScoreList = JSON.parse(res.data.customer_data);
                if (res.data.goods_data.length > 0) {
                    tmp = res.data.goods_data.map((item) => {
                        return {
                            id: item.id,
                            goods_id: item.goods_id,
                            goods_name: item.good_title,
                            goods_img: item.goods_img,
                            turnover_index: item.turnover_index,
                            transaction_volume_index: item.transaction_volume_index,
                            follow_num_total: item.follow_num,
                            visitor_index: item.visitor_index,
                            search_click_index: item.search_click_index,
                        };
                    });
                }
                this.ruleForm = {
                    customer_id: res.data.customer_id,
                    transaction_num: res.data.transaction_num,
                    transaction_num_score: res.data.transaction_num_score,
                    goodsList: tmp,
                    odc_goods_id: res.data.odc_goods_id,
                    odc_goods_score: res.data.odc_goods_score,
                };
                this.clientInfoChange(this.ruleForm.customer_id);
            });
        },
        // 获取客户信息列表
        getCustomerList() {
            let params = {
                paging: 0,
            };
            customerInfoGetModuleList(params).then((res) => {
                this.customerList = res.data.list;
            });
        },
        // 获取商品类目
        getGoodsCategory() {
            adminGoodGetCategory().then((res) => {
                this.goodClassList = res.data;
            });
        },
        // 选择商品类目筛选商品
        changeClass(val) {
            this.addGoodsForm.goods_id = null;
            this.getGoodsList();
        },
        // 获取弹窗商品列表
        getGoodsList() {
            let params = {
                category_id: this.addGoodsForm.good_category_id[this.addGoodsForm.good_category_id.length - 1],
            };
            adminGoodListGood(params).then((res) => {
                // 将添加的商品过滤掉
                if (this.ruleForm.goodsList.length > 0 && res.data.length > 0) {
                    this.ruleForm.goodsList.forEach((item, index) => {
                        res.data.forEach((item1, index1) => {
                            if (item.goods_id === item1.id && item.goods_id !== this.isEdit) {
                                res.data.splice(index1, 1);
                            }
                        });
                    });
                }
                this.goodsListForm = res.data;
            });
        },
        // 编辑商品
        editGoodBtn(id) {
            let params = {
                id: id,
            };
            operationProblemDataCollectGoodsDetail(params).then((res) => {
                this.dialogVisible = true;
                this.addGoodsForm = res.data;
                this.getGoodsList();
                // this.isEdit = true
                this.isEdit = res.data.goods_id;
                console.log("isEdit", this.isEdit);
            });
        },
        // 删除商品
        delGoodBtn(id, index) {
            this.$confirm("是否删除该商品，删除完将无法恢复", "提示消息", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                customClass: "green-message-box",
            })
                .then(() => {
                    let params = {
                        id: id,
                    };
                    operationProblemDataCollectDelGoods(params).then((res) => {
                        this.$message.success(res.msg);
                        this.ruleForm.goodsList.splice(index, 1);
                    });
                })
                .catch(() => {
                    this.$message.info("已取消删除");
                });
        },
        //时间处理
        timeFormat(timestamp) {
            if (timestamp.toString().length === 10) {
                timestamp = timestamp * 1000;
            }
            let date = new Date(timestamp);
            let y = date.getFullYear();
            let m = date.getMonth() > 8 ? date.getMonth() + 1 : "0" + (date.getMonth() + 1);
            let d = date.getDate() > 9 ? date.getDate() : "0" + date.getDate();
            return y + "-" + m + "-" + d;
        },
        // 添加商品按钮
        addGoods() {
            this.dialogVisible = true;
        },
        // 打开弹窗
        openedDialog() {
            this.getGoodsCategory();
        },
        // 关闭弹窗
        closeDialog() {
            this.resetGoodsForm();
            this.goodsListForm = [];
            this.isEdit = null;
        },
        // 保存商品
        submitGoodsForm(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    let params = {
                        good_category_id: this.addGoodsForm.good_category_id[this.addGoodsForm.good_category_id.length - 1],
                        goods_id: this.addGoodsForm.goods_id,
                        search_number: this.addGoodsForm.search_number,
                        search_number_wave_lower: this.addGoodsForm.search_number_wave_lower,
                        search_number_wave_upper: this.addGoodsForm.search_number_wave_upper,
                        follow_num: this.addGoodsForm.follow_num,
                        follow_num_wave_lower: this.addGoodsForm.follow_num_wave_lower,
                        follow_num_wave_upper: this.addGoodsForm.follow_num_wave_upper,
                        click_rate: this.addGoodsForm.click_rate,
                        click_rate_wave_lower: this.addGoodsForm.click_rate_wave_lower,
                        click_rate_wave_upper: this.addGoodsForm.click_rate_wave_upper,
                        conversion_rate: this.addGoodsForm.conversion_rate,
                        conversion_rate_wave_lower: this.addGoodsForm.conversion_rate_wave_lower,
                        conversion_rate_wave_upper: this.addGoodsForm.conversion_rate_wave_upper,
                    };
                    if (this.addGoodsForm.id) {
                        params.id = this.addGoodsForm.id;
                    }
                    operationProblemSaveDataCollectGoods(params).then((res) => {
                        let findIndexTmp = this.ruleForm.goodsList.findIndex((item) => {
                            return Number(item.id) === Number(res.data.id);
                        });
                        if (findIndexTmp > -1) {
                            this.ruleForm.goodsList.splice(findIndexTmp, 1, res.data);
                        } else {
                            this.ruleForm.goodsList.push(res.data);
                        }
                        this.$message.success(res.msg);
                        this.dialogVisible = false;
                    });
                } else {
                    return false;
                }
            });
        },
        // 重置商品
        resetGoodsForm() {
            this.$refs.addGoodsForm.resetFields();
            this.addGoodsForm = {
                id: null,
                good_category_id: [],
                goods_id: null,
                search_number: void 0, // 搜索人数
                search_number_wave_lower: void 0,
                search_number_wave_upper: void 0,
                follow_num: void 0, // 关注人数
                follow_num_wave_lower: void 0,
                follow_num_wave_upper: void 0,
                click_rate: void 0, // 点击率
                click_rate_wave_lower: void 0,
                click_rate_wave_upper: void 0,
                conversion_rate: void 0, // 转化率
                conversion_rate_wave_lower: void 0,
                conversion_rate_wave_upper: void 0,
            };
        },
        // 保存试题
        submitForm() {
            if (!this.practiceForm.name) {
                return this.$message.warning("请填写名称！");
            }
            if (!this.practiceForm.time) {
                return this.$message.warning("请输入考核时间");
            }
            if (this.practiceForm.classValue.length === 0) {
                return this.$message.warning("请选择分类！");
            }
            // if (this.practiceForm.taskList.length === 0) {
            //     return this.$message.warning("请添加任务目标！");
            // }
            // if (!this.practiceForm.taskBackground) {
            //     return this.$message.warning("请填写任务背景！");
            // }
            if (!this.practiceForm.taskBackground) {
                return this.$message.warning("请填写操作要求！");
            }
            // if (!this.practiceForm.taskAnalysis) {
            //     return this.$message.warning("请填写任务分析！");
            // }
            // if (!this.practiceForm.taskOperation) {
            //     return this.$message.warning("请填写任务操作！");
            // }
            if (this.practiceForm.scoreList.length === 0) {
                return this.$message.warning("请添加评分标准！");
            }
            if (!this.ruleForm.customer_id) {
                return this.$message.warning("请选择客户信息！");
            }
            // if (!this.ruleForm.transaction_num) {
            //     return this.$message.warning("请输入交易笔数！");
            // }
            // if (!this.ruleForm.transaction_num_score) {
            //     return this.$message.warning("请输入交易笔数分值！");
            // }
            if (this.ruleForm.goodsList.length === 0) {
                return this.$message.warning("请添加商品！");
            }
            if (!this.ruleForm.odc_goods_id) {
                return this.$message.warning("请采购商品！");
            }
            if (!this.ruleForm.odc_goods_score) {
                return this.$message.warning("请输入商品分值！");
            }
            if (this.clientInfoScoreList.some((item) => !item.score || item.score > 100)) {
                return this.$message.warning("分值不能小于0或大于100");
            }
            let arrTmp = this.ruleForm.goodsList.map((item) => {
                return Number(item.id);
            });
            let params = {
                op_name: this.practiceForm.name,
                exam_module_id: this.practiceForm.module_level_three_value,
                category_id: this.practiceForm.classValue[this.practiceForm.classValue.length - 1],
                assess_time: this.practiceForm.time,
                task_goal: this.practiceForm.taskList,
                task_background: this.practiceForm.taskBackground,
                task_analysis: this.practiceForm.taskAnalysis,
                task_operation: this.practiceForm.taskOperation,
                scoring_criteria: this.practiceForm.scoreList,
                customer_id: this.ruleForm.customer_id,
                // transaction_num: this.ruleForm.transaction_num,
                // transaction_num_score: this.ruleForm.transaction_num_score,
                odc_goods_id: this.ruleForm.odc_goods_id,
                odc_goods_score: this.ruleForm.odc_goods_score,
                odc_goods_list: arrTmp,
                customer_data: this.clientInfoScoreList.map((item) => ({ ...item, score: item.score * 1 })),
            };
            if (this.$route.query.id) {
                params.id = Number(this.$route.query.id);
            }
            operationProblemSaveDataCollect(params).then((res) => {
                if(res.code==200){
                    this.$message.success(res.msg);
                    this.$router.push("/practice/index");
                }
            });
        },
        // 重置试题
        resetForm() {
            this.ruleForm = {
                customer_id: null,
                transaction_num: void 0,
                transaction_num_score: void 0,
                goodsList: [],
                odc_goods_id: null,
                odc_goods_score: void 0,
            };
            this.$emit("update:practiceForm", {
                ...this.practiceForm,
                name: "",
                time: "",
                module_level_one_value: "",
                module_level_tow_value: "",
                module_level_three_value: "",
                classValue: [],
                taskList: [],
                taskBackground: "",
                taskAnalysis: "",
                taskOperation: "",
                scoreList: [],
            });
            this.$router.push({
                path: "/practice/index",
            });
        },
    },
};
</script>

<style scoped lang="scss">
.client-info-table {
    margin-top: 25px;
}
.client-score {
    box-sizing: border-box;
    width: 100px;
    height: 40px;
    text-align: center;
    border: 1px solid #c0c4cc;
    border-radius: 4px;
    outline: none;
}
.line-item {
    margin-top: 16px;
    display: flex;
    align-items: center;
    .left {
        width: 66px;
        margin-right: 10px;
        color: #333;
        font-size: 16px;
        text-align: right;
    }
}
.second-title {
    margin-top: 40px;
    margin-bottom: 20px;
    font-size: 16px;
    padding-left: 14px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    &:before {
        content: "";
        width: 4px;
        height: 16px;
        background: #2dc079;
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        border-radius: 2px;
    }
}
.number-input {
    ::v-deep .el-input__inner {
        text-align: left;
    }
}
.goods-wrapper {
    display: flex;
    align-items: center;
    .goods-cover {
        width: 80px;
        height: 80px;
        display: flex;
        background: #f6f6f6;
        img {
            max-width: 100%;
            max-height: 100%;
            margin: auto;
        }
    }
    .goods-name {
        width: 1%;
        flex: 1;
        margin-left: 10px;
        font-size: 16px;
    }
}
.prompt-text {
    margin-left: 10px;
    font-size: 16px;
}
.demo-ruleForm {
    .el-form-item {
        margin-bottom: 16px;
    }
    ::v-deep .el-form-item__label {
        font-size: 16px;
        color: #666;
    }
    ::v-deep .el-form-item__error {
        padding-top: 2px;
    }
}
</style>
