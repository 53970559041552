<template>
    <div class="search-content">
        <div class="content-item">
            <div class="item-content">
                <div class="item-title">运营推广名称</div>
                <el-select v-model="formData.operation_promotion_id" class="item-input max" ref="operation_promotion_id"
                           placeholder="请选择运营推广名称">
                    <el-option
                            v-for="item in options"
                            :key="item.id"
                            :label="item.title"
                            :value="item.id">
                    </el-option>
                </el-select>
            </div>
            <div class="item-content">
                <div class="item-title">满分点击数</div>
                <el-input class="item-input max" ref="click_num" v-model="formData.click_num"
                          placeholder="请输入满分点击数"></el-input>
            </div>
            <div class="item-content">
                <div class="item-title">设置分值</div>
                <el-input-number class="item-input max" ref="score"
                                 v-model="formData.score"
                                 :min="0"
                                 :max="9999999"
                                 :controls="false"
                                 :precision="0"></el-input-number>
            </div>
        </div>
        <div class="footer-wrapper">
            <el-button type="primary" @click="submit">保存</el-button>
            <el-button @click="reset">取消</el-button>
        </div>
    </div>
</template>

<script>
    import {operationPromote_getList, search_engine_problem, operationProblemOpDetail} from '@/utils/apis';

    export default {
        name: "SearchEnginePromotion",
        // props: ['practiceForm'],
        props: {
            practiceForm: {
                type: Object,
            },
            typeId: {
                type: Number,
                default: 1,
            }
        },
        data() {
            return {
                formData: {
                    operation_promotion_id:'',
                    click_num:'',
                    score:void 0,
                },
                options: [],
                id: "",
            }
        },
        created() {
            this.getList();
            this.id = this.$route.query.id;
            if (this.id !== undefined) {
                this.getDetail()
            }
        },
        methods: {
            getList() {
                operationPromote_getList({module_id: this.typeId, paging: 0}).then(res => {
                    this.options = res.data.list
                }).catch(err => {
                    console.error(err)
                })
            },
            getDetail() {
                let param = {
                    id: this.id
                }
                operationProblemOpDetail(param).then((res) => {
                    this.formData.operation_promotion_id = res.data.operation_promotion_id;
                    this.formData.click_num = res.data.click_num;
                    this.formData.score = res.data.score;
                }).catch((err) => {
                    console.log(err);
                })
            },
            submit() {
                let isErrNum = 0;
                if (!this.practiceForm.name) {
                    return this.$message.warning('请填写名称！');
                }
                if (!this.practiceForm.time) {
                    return this.$message.warning('请输入考核时间')
                }
                if (this.practiceForm.classValue.length === 0) {
                    return this.$message.warning('请选择分类！');
                }
                if (this.practiceForm.taskList.length === 0) {
                    return this.$message.warning('请添加任务目标！');
                }
                if (!this.practiceForm.taskBackground) {
                    return this.$message.warning('请填写操作要求！');
                }
                if (!this.practiceForm.taskAnalysis) {
                    return this.$message.warning('请填写任务分析！');
                }
                if (!this.practiceForm.taskOperation) {
                    return this.$message.warning('请填写任务操作！');
                }
                if (this.practiceForm.scoreList.length === 0) {
                    return this.$message.warning('请添加评分标准！');
                }
                if (!this.formData.operation_promotion_id) {
                    this.$refs.operation_promotion_id.$el.classList.add('isError');
                    isErrNum = 1;
                } else {
                    this.$refs.operation_promotion_id.$el.classList.remove('isError');
                }
                if (!this.formData.click_num) {
                    this.$refs.click_num.$el.classList.add('isError');
                    isErrNum = 1;
                } else {
                    this.$refs.click_num.$el.classList.remove('isError');
                }
                if (!this.formData.score) {
                    this.$refs.score.$el.classList.add('isError');
                    isErrNum = 1;
                } else {
                    this.$refs.score.$el.classList.remove('isError');
                }
                if (isErrNum === 0) {
                    this.formData.op_name = this.practiceForm.name
                    this.formData.exam_module_id = this.practiceForm.module_level_three_value
                    this.formData.assess_time = this.practiceForm.time
                    this.formData.task_goal = JSON.stringify(this.practiceForm.taskList)
                    this.formData.task_background = this.practiceForm.taskBackground
                    this.formData.task_analysis = this.practiceForm.taskAnalysis
                    this.formData.task_operation = this.practiceForm.taskOperation
                    this.formData.scoring_criteria = JSON.stringify(this.practiceForm.scoreList)
                    this.formData.category_id = this.practiceForm.classValue[this.practiceForm.classValue.length - 1];
                    if (this.practiceForm.id != null) {
                        this.formData.id = this.practiceForm.id
                    }
                    search_engine_problem(this.formData).then((res) => {
                        this.$message.success(res.msg);
                        this.reset();
                    }).catch((err) => {
                        console.log('err', err)
                    })
                } else {
                    this.$message.warning('每项为必填！')
                }
            },
            reset() {
                this.$router.push({
                    path: '/practice/index'
                })
            }
        }
    }
</script>

<style scoped lang="scss">
    .search-content {
        margin-top: 20px;
        font-size: 16px;
        color: #333333;
    }

    ::v-deep .el-input-number .el-input__inner {
        text-align: left;
    }

    .item-input {
        &.max {
            width: 320px;
        }
    }

    .content-item {

        .content-title {
            position: relative;
            padding-left: 10px;
            margin-bottom: 22px;

            &:before {
                content: '';
                width: 4px;
                height: 16px;
                background-color: #2DC079;
                border-radius: 2px;
                position: absolute;
                left: 0;
                top: 3px;
            }
        }

        .item-content {
            display: flex;
            align-items: center;
            margin-bottom: 16px;

            .item-title {
                width: 120px;
                text-align: right;
                margin-right: 10px;
            }

            .divide {
                width: 24px;
                height: 2px;
                background: #D2D2D2;
                margin: 0 18px;
            }

            .file-upload-btn {
                display: none;
            }

            .upload-text {
                margin-left: 11px;
                font-size: 14px;
                color: #999999;
            }

            .upload-file-list {
                display: flex;
                flex-wrap: wrap;
                margin-top: 20px;

                .file-item {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    height: 40px;
                    padding: 10px;
                    box-sizing: border-box;
                    color: #333333;
                    font-size: 14px;
                    margin: 0 20px 15px 0;

                    &:hover {
                        cursor: pointer;
                        background-color: #F5F7FA;

                        .del-icon {
                            display: block;
                        }

                        .finish-icon {
                            display: none;
                        }
                    }

                    .file-name {
                        display: inline-block;
                        width: 1%;
                        flex: 1;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                    }

                    .el-progress {
                        margin-left: 20px;
                        width: 160px;
                    }

                    i {
                        color: #2461EF;
                        margin: 0 10px 0 0;
                    }

                    .finish-icon {
                        color: #2DC079;
                        margin: 0 0 0 10px;
                    }

                    .del-icon {
                        display: none;
                        color: #FF0000;
                        margin: 0 0 0 10px;
                    }
                }
            }
        }

    }

    .footer-wrapper {
        text-align: center;
        margin-top: 50px;
    }

</style>
