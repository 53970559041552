<template>
    <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" :hide-required-asterisk="true" class="demo-ruleForm" style="margin-top: 30px">
        <el-form-item label="行业分类" prop="goods_category_id">
            <el-cascader filterable v-model="ruleForm.goods_category_id" :props="keyObj" style="width: 360px"
                         :options="goodClassList" @change="changeClass" clearable placeholder="请选择行业分类"></el-cascader>
        </el-form-item>
        <el-form-item label="客服名称" prop="kefu_id">
            <el-select filterable v-model="ruleForm.kefu_id" placeholder="请选择客服服务" style="width: 360px" @change="changeCustomer" :disabled="customerList.length === 0">
                <el-option v-for="(item, index) in customerList" :key="`customerList_${index}`" :label="item.name" :value="item.kefu_id"></el-option>
            </el-select>
            <el-input v-model="ruleForm.score" style="width: 80px; margin-left: 10px" disabled></el-input>
            <span style="font-size: 16px; color: #333; margin-left: 10px">分</span>
        </el-form-item>
        <el-form-item style="text-align: center; margin-top: 40px">
            <el-button type="primary" @click="submitForm('ruleForm')">保存</el-button>
            <el-button @click="resetForm('ruleForm')">取消</el-button>
        </el-form-item>
    </el-form>
</template>

<script>
    import { kefuListKefuData, operationProblemSaveCustomerService, adminGoodGetCategory, operationProblemOpDetail } from '@/utils/apis.js'
    export default {
        props: {
            practiceForm: {
                type: Object,
                default:() => {}
            }
        },
        data() {
            return {
                ruleForm: {
                    goods_category_id: null,
                    kefu_id: null,
                    score: null,
                },
                // ？？？是不是要注释，组长加这个干吗
                kefu_id: null,
                rules: {
                    // goods_category_id: [
                    //     { required: true, message: '请选择行业分类', trigger: 'change' }
                    // ],
                    // kefu_id: [
                    //     { required: true, message: '请选择客服名称', trigger: 'change' }
                    // ],
                },
                customerList: [],
                keyObj: {
                    label: 'name',
                    value: 'id',
                    children: 'children'
                },
                goodClassList: [], // 商品类目
            }
        },
        mounted() {
            this.getGoodsCategory();
            if (this.$route.query.id) {
                this.initDetail();
            }
        },
        methods: {
            initDetail() {
                operationProblemOpDetail({id: this.$route.query.id}).then(res => {
                    if (res.code === 200) {
                        this.ruleForm.kefu_id = res.data.kefu_id;
                        this.ruleForm.score = res.data.score;
                        this.$set(this.ruleForm, 'goods_category_id', res.data.goods_category_id);
                        this.getCustomer();
                    }
                })
            },
            // 获取行业分类
            getGoodsCategory() {
                adminGoodGetCategory().then((res) => {
                    this.goodClassList = res.data
                })
            },
            // 选择商品类目筛选客服
            changeClass(val) {
                this.ruleForm.kefu_id = null
                this.customerList = []
                this.ruleForm.score = null
                this.getCustomer()
            },
            // 获取客服列表
            getCustomer() {
                let params = {
                    category_id: this.ruleForm.goods_category_id[this.ruleForm.goods_category_id.length - 1],
                }
                kefuListKefuData(params).then((res) => {
                    this.customerList = res.data;
                })
            },
            // 修改客服选择
            changeCustomer(val) {
                let tmp = this.customerList.find(item => {
                    return Number(val) === Number(item.kefu_id)
                })
                if (tmp) {
                    this.ruleForm.score = tmp.score
                }
            },
            // 保存试题
            submitForm(formName) {
                if (!this.practiceForm.name) {
                    return this.$message.warning('请填写名称！');
                }
                if (!this.practiceForm.time) {
                    return this.$message.warning('请输入考核时间')
                }
                if (this.practiceForm.classValue.length === 0) {
                    return this.$message.warning('请选择分类！');
                }
                if (this.practiceForm.taskList.length === 0) {
                    return this.$message.warning('请添加任务目标！');
                }
                if (!this.practiceForm.taskBackground) {
                    return this.$message.warning('请填写任务背景！');
                }
                if (!this.practiceForm.taskAnalysis) {
                    return this.$message.warning('请填写任务分析！');
                }
                if (!this.practiceForm.taskOperation) {
                    return this.$message.warning('请填写任务操作！');
                }
                if (this.practiceForm.scoreList.length === 0) {
                    return this.$message.warning('请添加评分标准！');
                }
                if (!this.ruleForm.goods_category_id) {
                    return this.$message.warning('请选择行业分类！');
                }
                if (!this.ruleForm.kefu_id) {
                    return this.$message.warning('请选择客服名称！');
                }
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        let params = {
                            op_name: this.practiceForm.name,
                            exam_module_id: this.practiceForm.module_level_three_value,
                            category_id: this.practiceForm.classValue[this.practiceForm.classValue.length - 1],
                            assess_time: this.practiceForm.time,
                            task_goal: this.practiceForm.taskList,
                            task_background: this.practiceForm.taskBackground,
                            task_analysis: this.practiceForm.taskAnalysis,
                            task_operation: this.practiceForm.taskOperation,
                            scoring_criteria: this.practiceForm.scoreList,
                            goods_category_id: this.ruleForm.goods_category_id[this.ruleForm.goods_category_id.length - 1],
                            kefu_id: this.ruleForm.kefu_id,
                            score: this.ruleForm.score,
                        }
                        if (this.$route.query.id) {
                            params.id = Number(this.$route.query.id);
                        }
                        operationProblemSaveCustomerService(params).then((res) => {
                           if(res.code==200){
                            this.$message.success(res.msg)
                            this.$router.push('/practice/index')
                           }
                        }).catch((err) => {})
                    } else {
                        return false;
                    }
                });
            },
            // 重置试题
            resetForm(formName) {
                this.$refs[formName].resetFields();
                this.$emit('update:practiceForm', {
                    ...this.practiceForm,
                    name: '',
                    time: '',
                    module_level_one_value: '',
                    module_level_tow_value: '',
                    module_level_three_value: '',
                    classValue: [],
                    taskList: [],
                    taskBackground: '',
                    taskAnalysis: '',
                    taskOperation: '',
                    scoreList: [],
                })
                this.$router.push({
                    path: '/practice/index'
                })
            }
        }
    }
</script>

<style scoped lang="scss">
    .demo-ruleForm {
        ::v-deep {
            .el-form-item__label {
                color: #333;
                font-size: 16px;
            }
        }
    }
</style>