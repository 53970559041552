<template>
    <el-dialog :title="!isCreate?'编辑抽奖':'创建抽奖'" :visible.sync="addLotteryDialog" width="500px"
               :before-close="closeDialog"
               custom-class="green-dialog add-task-dialog">
        <div class="upload-file-content">
            <el-form :model="lotteryForm" :rules="rules" ref="lotteryForm" label-width="110px" class="demo-ruleForm">
                <el-form-item label="抽奖形式" prop="points">
                    <div class="radio-box">
                        <div class="radio-item">
                            <el-radio v-model="lotteryForm.type" :label="0">评论抽奖</el-radio>
                            <div class="radio-text">从参与评论活动的观众中抽取幸运观众</div>
                        </div>
                        <div class="radio-item">
                            <el-radio v-model="lotteryForm.type" :label="1">点赞抽奖</el-radio>
                            <div class="radio-text">从参与点赞活动的观众中抽取幸运观众</div>
                        </div>
                    </div>
                </el-form-item>
                <el-form-item label="抽奖名称" prop="points">
                    <el-input v-model="lotteryForm.name" maxlength="14" show-word-limit></el-input>
                </el-form-item>
                <el-form-item label="抽取人数" prop="people_num">
                    <el-input v-model.number="lotteryForm.people_num">
                        <span slot="suffix">人</span>
                    </el-input>
                    <div class="input-describe">最多抽取20人</div>
                </el-form-item>
                <el-form-item label="活动时长" prop="activity_time">
                    <el-input v-model.number="lotteryForm.activity_time">
                        <span slot="suffix">分钟</span>
                    </el-input>
                    <div class="input-describe">评论收集时长最多30分钟</div>
                </el-form-item>
                <el-form-item label="用户兑换形势" prop="points" class="inner-label">
                    <div class="radio-box">
                        <div class="radio-item">
                            <el-radio v-model="lotteryForm.cash_prize_terrain" :label="0">兑换码+主动提交邮寄地址</el-radio>
                            <div class="radio-text">从参与评论活动的观众中抽取幸运观众</div>
                        </div>
                        <div class="radio-item">
                            <el-radio v-model="lotteryForm.cash_prize_terrain" :label="1">仅凭兑换码</el-radio>
                            <div class="radio-text">从参与点赞活动的观众中抽取幸运观众</div>
                        </div>
                    </div>
                </el-form-item>
                <el-form-item label="得分操作">
                    <el-select v-model.number="lotteryForm.scoring_operation" placeholder="请选择" style="width: 100%">
                        <el-option
                                v-for="item in optionList"
                                :key="item.id"
                                :label="item.title"
                                :value="item.id">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="备注">
                    <el-input type="textarea" :row="4" resize="none" v-model="lotteryForm.remarks" maxlength="32"
                              show-word-limit placeholder="非必填项"></el-input>
                </el-form-item>
                <el-form-item label="分值">
                    <el-input v-model="lotteryForm.score" v-one-number>
                        <span slot="suffix">分</span>
                    </el-input>
                </el-form-item>
            </el-form>
        </div>
        <span slot="footer">
		        <el-button @click="closeDialog">取 消</el-button>
		        <el-button style="margin-left: 30px;" type="primary" @click="save('lotteryForm')">确 定</el-button>
		    </span>
    </el-dialog>
</template>

<script>
    import oneNumber from "../../../../directive/index";

    export default {
        name: "addLotteryDialog",
        props: ['addLotteryDialog', 'editLottery','isCreate'],
        directives: {
            oneNumber
        },
        data() {
            return {
                lotteryForm: {
                    type: '',//抽奖方式  0评论抽奖  1点赞抽奖
                    name: "",//抽奖名称
                    people_num: '',//抽奖人数
                    activity_time: '',//活动时长
                    cash_prize_terrain: '',//兑奖形式 0兑奖码+主动提交地址  1仅凭兑奖码
                    scoring_operation: '',//得分操作  0不推送  1推送
                    remarks: "",//备注
                    score: '' //分数
                },
                optionList: [
                    {
                        id: 0,
                        title: '不推送'
                    },
                    {
                        id: 1,
                        title: '推送'
                    },
                ],
                rules: {
                    people_num: {type: 'number', min: 1, max: 20, message: '不可输入非数字，且不能超过20'},
                    activity_time: {type: 'number', min: 1, max: 30, message: '不可输入非数字，且不能超过30'}
                }
            }
        },
        created() {
            if (this.editLottery.index != undefined && !this.isCreate) {
         
                this.lotteryForm = JSON.parse(JSON.stringify(this.editLottery))
            }
        },
        methods: {
            closeDialog() {
                this.lotteryForm = {
                    type: '',
                    name: "",
                    people_num: '',
                    activity_time: '',
                    cash_prize_terrain: '',
                    scoring_operation: '',
                    remarks: "",
                    score: ''
                }
                this.$emit('closeDialog', false)
            },
            save(formName) {
                let isErrNum = 0;
                if (this.lotteryForm.type === '') {
                    isErrNum = 1;
                }
                if (this.lotteryForm.name === '') {
                    isErrNum = 1;
                }
                if (this.lotteryForm.people_num === '') {
                    isErrNum = 1;
                }
                if (this.lotteryForm.activity_time === '') {
                    isErrNum = 1;
                }
                if (this.lotteryForm.cash_prize_terrain === '') {
                    isErrNum = 1;
                }
                if (this.lotteryForm.scoring_operation === '') {
                    isErrNum = 1;
                }
                if (this.lotteryForm.score === '') {
                    isErrNum = 1;
                }
                if(isErrNum == 1){
                    this.$message.warning('除备注外每项为必填')
                    return
                }
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        if (isErrNum === 0) {
                            this.$emit('saveLottery', this.lotteryForm);
                            this.closeDialog()
                        } else {
                            this.$message.warning('除备注外，每项为必填')
                        }
                    } else {
                        return false;
                    }
                })
            }
        }
    }
</script>

<style scoped lang="scss">
    .el-form-item {
        display: flex;
        margin-bottom: 15px;

        ::v-deep .el-form-item__label {
            margin-bottom: 0;
        }

        ::v-deep .el-form-item__content {
            width: 1%;
            flex: 1;
            margin-left: 10px !important;

            .el-input-number {
                width: 100%;
            }

            .el-input-number.is-without-controls .el-input__inner {
                text-align: left;
            }

            .el-form-item__error {
                margin-top: 5px;
            }
        }
    }

    .radio-box {
        ::v-deep .el-radio__label {
            font-size: 16px;
            color: #333333;
        }

        .radio-item {
            .radio-text {
                font-size: 12px;
                color: #999999;
                line-height: 14px;
            }

            &:first-child {
                margin-bottom: 18px;
            }
        }
    }

    .input-describe {
        font-size: 12px;
        color: #999999;
        height: 10px;
        line-height: 24px;
    }
</style>