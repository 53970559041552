<template>
    <el-scrollbar class="content-body">
        <div class="header">
            <el-breadcrumb separator-class="el-icon-arrow-right" class="genera-breadcrumb">
                <el-breadcrumb-item :to="{ path: '/practice/index' }">实操题</el-breadcrumb-item>
                <el-breadcrumb-item>添加试题</el-breadcrumb-item>
            </el-breadcrumb>
        </div>
        <div class="practice-content">
            <div class="content-item">
                <div class="item">
                    <span class="text">名称</span>
                    <el-input ref="practiceFormName" v-model="practiceForm.name" class="name-input" maxlength="50" show-word-limit @change="handleInput"></el-input>
                </div>
                <!--                <div class="item">-->
                <!--                    <span class="text">模块</span>-->
                <!--                    <el-select v-model="practiceForm.module_level_one_value" filterable placeholder="请选择" class="practice-select" @change="selectModuleLevelOneValue">-->
                <!--                        <el-option-->
                <!--                                v-for="item in practiceForm.module_level_one"-->
                <!--                                :key="item.id"-->
                <!--                                :label="item.name"-->
                <!--                                :value="item.id">-->
                <!--                        </el-option>-->
                <!--                    </el-select>-->
                <!--                    <el-select v-model="practiceForm.module_level_tow_value" filterable placeholder="请选择" class="practice-select" @change="selectModuleLevelTowValue">-->
                <!--                        <el-option-->
                <!--                                v-for="item in practiceForm.module_level_tow"-->
                <!--                                :key="item.id"-->
                <!--                                :label="item.name"-->
                <!--                                :value="item.id">-->
                <!--                        </el-option>-->
                <!--                    </el-select>-->
                <!--                    <el-select v-model="practiceForm.module_level_three_value" filterable placeholder="请选择" class="practice-select" @change="selectModuleLevelThreeValue">-->
                <!--                        <el-option-->
                <!--                                v-for="item in practiceForm.module_level_three"-->
                <!--                                :key="item.id"-->
                <!--                                :label="item.name"-->
                <!--                                :value="item.id">-->
                <!--                        </el-option>-->
                <!--                    </el-select>-->
                <!--                </div>-->
                <div class="item">
                    <span class="text">分类</span>
                    <el-cascader
                            ref="moduleList"
                            v-model="practiceForm.classValue"
                            filterable
                            :options="practiceForm.classOptions"
                            :props="keyObj"
                            :disabled="cascaderStatus"
                            class="practice-cascader"
                            @change="selectModule">
                    </el-cascader>
                </div>
                <div class="item">
                    <span class="text">考核时间</span>
                    <el-input-number
                            v-model="practiceForm.time"
                            :min="1"
                            :max="99999"
                            :controls="false"
                            :precision="0"
                            placeholder="请输入考核时间"
                            class="name-input">
                    </el-input-number>
                    <span style="margin-left: 10px;">分钟</span>
                </div>
            </div>
            <!-- <div class="content-item">
                <div class="title">
                    <div style="display: flex;align-items: center;">
                        <span class="left-icon"></span>
                        <span class="text">任务目标</span>
                    </div>
                    <el-button type="primary" @click="addTask">添加任务目标</el-button>
                </div>
                <el-table :data="practiceForm.taskList" size="medium"
                          class="content-table"
                          style="border: 1px solid #E5E5E5;border-bottom: none;"
                          :header-cell-style="{fontWeight: 'normal', height: '60px', color: '#666666', background: '#F6F6F6',fontSize: '16px'}"
                          :cell-style="{fontSize: '16px',color: '#333333',height: '70px'}">
                    <el-table-column prop="index" align="center" width="150" label="序号" sortable>
                        <template slot-scope="scope">
                            <span class="text">{{scope.row.index}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="target" align="center" label="目标"></el-table-column>
                    <el-table-column align="center" label="操作" width="200">
                        <template slot-scope="scope">
                            <div class="link-box">
                                <el-link type="success" :underline="false" @click="editTask(scope.row)">编辑</el-link>
                                <el-link type="danger" :underline="false" @click="delTask(scope.row)">删除</el-link>
                            </div>
                        </template>
                    </el-table-column>
                </el-table>
            </div> -->
            <div class="content-item" style="margin: 20px 0;">
                <div class="item" style="display: flex;">
                    <span class="text">操作要求</span>
                    <vue-tinymce ref="taskBackground" :setting="setting" v-model="practiceForm.taskBackground"></vue-tinymce>
                </div>
                <!-- <div class="item" style="display: flex;">
                    <span class="text">任务分析</span>
                    <vue-tinymce ref="taskAnalysis" :setting="setting" v-model="practiceForm.taskAnalysis"></vue-tinymce>
                </div>
                <div class="item" style="display: flex;">
                    <span class="text">任务操作</span>
                    <vue-tinymce ref="taskOperation" :setting="setting" v-model="practiceForm.taskOperation"></vue-tinymce>
                </div> -->
            </div>
            <div class="content-item">
                <div class="title">
                    <div style="display: flex;align-items: center;">
                        <span class="left-icon"></span>
                        <span class="text">评分标准</span>
                    </div>
                    <el-button type="primary" @click="addScore">添加评分标准</el-button>
                </div>
                <el-table :data="practiceForm.scoreList" size="medium"
                          class="content-table"
                          style="border: 1px solid #E5E5E5;border-bottom: none;"
                          :header-cell-style="{fontWeight: 'normal', height: '60px', color: '#666666', background: '#F6F6F6',fontSize: '16px'}"
                          :cell-style="{fontSize: '16px',color: '#333333',height: '70px'}">
                    <el-table-column prop="index" align="center" width="150" label="序号" sortable>
                        <template slot-scope="scope">
                            <span class="text">{{scope.row.index}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="content" align="center" label="考核内容"></el-table-column>
                    <el-table-column prop="require" align="center" label="考核要点"></el-table-column>
                    <el-table-column prop="standard" align="center" label="评分标准"></el-table-column>
                    <el-table-column prop="score" align="center" label="配分"></el-table-column>
                    <el-table-column align="center" label="操作" width="200">
                        <template slot-scope="scope">
                            <div class="link-box">
                                <el-link type="success" :underline="false" @click="editScore(scope.row)">编辑</el-link>
                                <el-link type="danger" :underline="false" @click="delScore(scope.row)">删除</el-link>
                            </div>
                        </template>
                    </el-table-column>
                </el-table>
            </div>

          <!--三级跨境电子商务-->
          <OrderManage module_id="91" v-if="practiceForm.module_level_three_value === 91" :practiceForm="practiceForm"
                       :editData="editData"/>
          <!-- 产品及服务组合信息管理 -->
          <ProductService v-if="practiceForm.module_level_three_value === 82" :practiceForm="practiceForm"></ProductService>

          <PageSet v-if="practiceForm.module_level_three_value === 85" :practiceForm="practiceForm"
                   :editData="editData"/>

          <HandleCustomerProblem v-if="practiceForm.module_level_three_value === 94" :practiceForm.sync="practiceForm"/>

          <ECommerceDataReport v-if="practiceForm.module_level_three_value === 97" :practiceForm="practiceForm"/>
          <!-- <ECommerceDataReportAnalyze v-if="practiceForm.module_level_three_value === 97" :practiceForm="practiceForm"></ECommerceDataReportAnalyze> -->
          <!-- 营销推广 信息流推广 -->
          <InfoFlowPromotion v-if="practiceForm.module_level_three_value === 88" :practiceForm="practiceForm" :type-id="88"/>
          <!-- 营销推广  搜索引擎推广 -->
          <SearchEnginePromotion v-if="practiceForm.module_level_three_value === 87" :practiceForm="practiceForm" :type-id="3"
                                   :editData="editData"/>
          <!--四级跨境电子商务-->
          <PageSet v-if="practiceForm.module_level_three_value === 59" :practiceForm="practiceForm"
                   :editData="editData"/>
          <softPromote v-if="practiceForm.module_level_three_value === 64" :practiceForm="practiceForm"
                       :editData="editData" module_id="64"/>
          <OrderManage module_id="67" v-if="practiceForm.module_level_three_value === 67" :practiceForm="practiceForm"
                       :editData="editData"/>
          <HandleCustomerProblem
              v-if="practiceForm.module_level_three_value === 69 || practiceForm.module_level_three_value === 70 || practiceForm.module_level_three_value === 71"
              :practiceForm.sync="practiceForm"/>
          <ECommerceCollect v-if="practiceForm.module_level_three_value === 72" :practiceForm.sync="practiceForm"/>
          <ECommerceClean v-if="practiceForm.module_level_three_value === 73" :practiceForm.sync="practiceForm"/>
          <GoodsText v-if="practiceForm.module_level_three_value === 58" :practiceForm="practiceForm"
                     :editData="editData"/>

            <!--四级-->
            <!--商品信息采集与处理-->
            <GoodsText v-if="practiceForm.module_level_three_value === 8" :practiceForm="practiceForm"
                       :editData="editData"/>
            <GoodsShoot v-if="practiceForm.module_level_three_value === 9" :practiceForm="practiceForm"
                        :editData="editData"/>
            <GoodsVideoShoot v-if="practiceForm.module_level_three_value === 10" :practiceForm="practiceForm"
                             :editData="editData"/>
            <!--网店管理-->
            <GoodsRelease v-if="practiceForm.module_level_three_value === 15" :practiceForm="practiceForm"
                          :editData="editData"/>
            <MobilePlatform v-if="practiceForm.module_level_three_value === 16" :practiceForm="practiceForm"
                            :editData="editData"/>
            <OrderManage v-if="practiceForm.module_level_three_value === 18" :practiceForm="practiceForm"
                         :editData="editData"/>
            <!--软文推广-->
            <softPromote v-if="practiceForm.module_level_three_value === 19" :practiceForm="practiceForm"
                         :editData="editData"/>
            <livePromote v-if="practiceForm.module_level_three_value === 20" :practiceForm="practiceForm"
                         :editData="editData"/>
            <!--四级网课客服问题处理设置、三级社会化客户服务-->
            <HandleCustomerProblem
                    v-if="practiceForm.module_level_three_value === 21 || practiceForm.module_level_three_value === 43"
                    :practiceForm.sync="practiceForm"/>
            <!--电子商务数据收集设置-->
            <ECommerceCollect v-if="practiceForm.module_level_three_value === 22" :practiceForm.sync="practiceForm"/>
            <ECommerceClean v-if="practiceForm.module_level_three_value === 23" :practiceForm.sync="practiceForm"/>
            <!--网店装修-->
            <ShopRegisterSet v-if="practiceForm.module_level_three_value === 11" :practiceForm="practiceForm"
                             :editData="editData"/>
            <PageSet v-if="practiceForm.module_level_three_value === 12 || practiceForm.module_level_three_value === 30" :practiceForm="practiceForm"
                      :editData="editData"/>
<!--            <HomeMake v-if="practiceForm.module_level_three_value === 12" :practiceForm="practiceForm"-->
<!--                      :editData="editData"/>-->
            <DetailPagesMake v-if="practiceForm.module_level_three_value === 13" :practiceForm="practiceForm"
                             :editData="editData"/>
            <CustomPageMake v-if="practiceForm.module_level_three_value === 14" :practiceForm="practiceForm"
                            :editData="editData"/>

            <!--三级-->
            <!--网店视觉设计-->
<!--            <OnlineShopStyleDesign v-if="practiceForm.module_level_three_value === 30" :practiceForm="practiceForm"-->
<!--                                   :editData="editData"/>-->
            <PageDesign v-if="practiceForm.module_level_three_value === 31" :practiceForm="practiceForm"
                        :editData="editData"/>
            <GoodsVideoDesign v-if="practiceForm.module_level_three_value === 32" :practiceForm="practiceForm"
                              :editData="editData"/>
            <!--网络营销-->
            <SearchEnginePromotion v-if="practiceForm.module_level_three_value === 33" :practiceForm="practiceForm"
                                   :editData="editData"/>
            <InfoFlowPromotion v-if="practiceForm.module_level_three_value === 34" :practiceForm="practiceForm"/>
            <CommunityPromotion v-if="practiceForm.module_level_three_value === 35" :practiceForm="practiceForm"
                                :editData="editData"/>
            <SocialECommerce v-if="practiceForm.module_level_three_value === 36" :practiceForm="practiceForm"
                             :editData="editData"/>
            <MobileMarketingAndOperations v-if="practiceForm.module_level_three_value === 37"
                                          :practiceForm="practiceForm" :editData="editData"/>
            <CommodityPricing v-if="practiceForm.module_level_three_value === 38" :practiceForm="practiceForm"/>
            <!--网上交易管理-->
            <ProcurementManagement v-if="practiceForm.module_level_three_value === 39" :practiceForm="practiceForm"
                                   :editData="editData"/>
            <!-- 库存管理            -->
            <InventoryManagement v-if="practiceForm.module_level_three_value === 40" :practiceForm="practiceForm"
                                 :editData="editData"/>
            <DistributionManagement v-if="practiceForm.module_level_three_value === 41" :practiceForm="practiceForm"
                                    :editData="editData"/>
            <!--网络客户服务-->
            <CustomerRelationshipManagement v-if="practiceForm.module_level_three_value === 42"
                                            :practiceForm="practiceForm" :editData="editData"/>
            <!--            <SocialCustomerService v-if="practiceForm.module_level_three_value === 43" :practiceForm="practiceForm" :editData="editData"/>-->
            <!--电子商务数据分析与应用-->
            <ECommerceCollectionAndHandle v-if="practiceForm.module_level_three_value === 44"
                                          :practiceForm="practiceForm"/>
            <ECommerceBasicData v-if="practiceForm.module_level_three_value === 45" :practiceForm="practiceForm"/>
            <ECommerceDataReportAnalyze v-if="practiceForm.module_level_three_value === 46" :practiceForm="practiceForm"/>
            <ECommerceDataReport v-if="practiceForm.module_level_three_value === 50" :practiceForm="practiceForm"/>
            <!-- 产品及服务组合信息管理 -->
            <ProductService v-if="practiceForm.module_level_three_value === 49" :practiceForm="practiceForm"></ProductService>
        </div>
        <el-dialog :title="addTaskTitle" :close-on-click-modal="false" :visible.sync="addTaskDialog" width="600px"
                   @close="handleCloseTaskDialog" custom-class="green-dialog add-task-dialog">
            <div class="upload-file-content">
                <el-form :model="addTaskForm" ref="addTaskForm" label-width="100px" class="demo-ruleForm">
                    <el-form-item label="序号" prop="index">
                        <el-input-number v-model="addTaskForm.index"
                                         :min="1"
                                         :max="99999"
                                         :controls="false"
                                         :precision="0"
                                         class="input-number">
                        </el-input-number>
                    </el-form-item>
                    <el-form-item label="任务目标" prop="target">
                        <el-input type="textarea" v-model="addTaskForm.target" :rows="4" resize="none"></el-input>
                    </el-form-item>
                </el-form>
            </div>
            <span slot="footer" class="dialog-footer" style="text-align: center;margin-bottom: 20px;">
		        <el-button @click="handleCloseTaskDialog">取 消</el-button>
		        <el-button type="primary" @click="saveAddTask">确 定</el-button>
		    </span>
        </el-dialog>
        <el-dialog :title="addScoreTitle" :close-on-click-modal="false" :visible.sync="addScoreDialog" width="600px"
                   @close="handleCloseScoreDialog" custom-class="green-dialog add-task-dialog">
            <div class="upload-file-content">
                <el-form :model="addScoreForm" ref="addScoreForm" label-width="100px" class="demo-ruleForm">
                    <el-form-item label="序号" prop="index">
                        <el-input-number v-model="addScoreForm.index"
                                         :min="1"
                                         :max="99999"
                                         :controls="false"
                                         :precision="0"
                                         class="input-number">
                        </el-input-number>
                    </el-form-item>
                    <el-form-item label="考核内容" prop="content">
                        <el-input v-model="addScoreForm.content"></el-input>
                    </el-form-item>
                    <el-form-item label="考核要点" prop="points">
                        <el-input v-model="addScoreForm.require"></el-input>
                    </el-form-item>
                    <el-form-item label="评分标准" prop="standard">
                        <el-input type="textarea" v-model="addScoreForm.standard" :rows="4" resize="none"></el-input>
                    </el-form-item>
                    <el-form-item label="配分" prop="score">
                        <el-input-number v-model="addScoreForm.score"
                                         :min="0.1"
                                         :max="99999"
                                         :controls="false"
                                         :precision="1"
                                         class="input-number">
                        </el-input-number>
                    </el-form-item>
                </el-form>
            </div>
            <span slot="footer" class="dialog-footer" style="text-align: center;">
		        <el-button @click="handleCloseScoreDialog">取 消</el-button>
		        <el-button type="primary" @click="saveAddScore">确 定</el-button>
		    </span>
        </el-dialog>
    </el-scrollbar>
</template>

<script>
    import {operationProblemExamModuleList, getActualCateData, operationProblemOpDetail} from '@/utils/apis'
    import GoodsText
        from "../../../components/admin/practicalExercises/addTestContent/goodsInfoCollectionAndHandle/GoodsText";
    import GoodsShoot
        from "../../../components/admin/practicalExercises/addTestContent/goodsInfoCollectionAndHandle/GoodsShoot";
    import GoodsVideoShoot
        from "../../../components/admin/practicalExercises/addTestContent/goodsInfoCollectionAndHandle/GoodsVideoShoot";
    import softPromote from "../../../components/admin/practicalExercises/networkPromote/softPromote.vue";
    import livePromote from "../../../components/admin/practicalExercises/networkPromote/livePromote";
    import GoodsRelease from "../../../components/admin/practicalExercises/addTestContent/shopManage/GoodsRelease.vue";
    import MobilePlatform
        from "../../../components/admin/practicalExercises/addTestContent/shopManage/MobilePlatform.vue";
    import OrderManage from "../../../components/admin/practicalExercises/addTestContent/shopManage/OrderManage.vue";
    import HandleCustomerProblem
        from '../../../components/admin/practicalExercises/customerService/HandleCustomerProblem'
    import ECommerceCollect from '../../../components/admin/practicalExercises/ECommerceCollectClean/ECommerceCollect'
    import ECommerceClean from '../../../components/admin/practicalExercises/ECommerceCollectClean/ECommerceClean'
    import HomeMake from '../../../components/admin/practicalExercises/addTestContent/shopDecoration/HomeMake.vue'
    import PageSet from '../../../components/admin/practicalExercises/addTestContent/shopDecoration/PageSet.vue'
    import DetailPagesMake
        from '../../../components/admin/practicalExercises/addTestContent/shopDecoration/DetailPagesMake.vue'
    import CustomPageMake
        from '../../../components/admin/practicalExercises/addTestContent/shopDecoration/CustomPageMake.vue'
    import ShopRegisterSet
        from '../../../components/admin/practicalExercises/addTestContent/shopDecoration/ShopRegisterSet.vue'
    import OnlineShopStyleDesign
        from '../../../components/admin/practicalExercises/addTestContent/onlineShopVisualDesign/OnlineShopStyleDesign.vue'
    import PageDesign
        from '../../../components/admin/practicalExercises/addTestContent/onlineShopVisualDesign/PageDesign.vue'
    import GoodsVideoDesign
        from '../../../components/admin/practicalExercises/addTestContent/onlineShopVisualDesign/GoodsVideoDesign.vue'
    import SearchEnginePromotion
        from '../../../components/admin/practicalExercises/addTestContent/internetMarketing/SearchEnginePromotion.vue'
    import InfoFlowPromotion
        from '../../../components/admin/practicalExercises/addTestContent/internetMarketing/InfoFlowPromotion.vue'
    import CommunityPromotion
        from '../../../components/admin/practicalExercises/addTestContent/internetMarketing/CommunityPromotion.vue'
    import SocialECommerce
        from '../../../components/admin/practicalExercises/addTestContent/internetMarketing/SocialECommerce.vue'
    import MobileMarketingAndOperations
        from '../../../components/admin/practicalExercises/addTestContent/internetMarketing/MobileMarketingAndOperations.vue'
    import CommodityPricing
        from '../../../components/admin/practicalExercises/addTestContent/internetMarketing/CommodityPricing.vue'
    import ProcurementManagement
        from '../../../components/admin/practicalExercises/addTestContent/onlineTransaction/ProcurementManagement.vue'
    import InventoryManagement
        from '../../../components/admin/practicalExercises/addTestContent/onlineTransaction/InventoryManagement.vue'
    import DistributionManagement
        from '../../../components/admin/practicalExercises/addTestContent/onlineTransaction/DistributionManagement.vue'
    import CustomerRelationshipManagement
        from '../../../components/admin/practicalExercises/addTestContent/onlineCustomerService/CustomerRelationshipManagement.vue'
    import SocialCustomerService
        from '../../../components/admin/practicalExercises/addTestContent/onlineCustomerService/SocialCustomerService.vue'
    import ECommerceCollectionAndHandle
        from '../../../components/admin/practicalExercises/addTestContent/ECommerceDataAnalysisApplication/ECommerceCollectionAndHandle.vue'
    import ECommerceBasicData
        from '../../../components/admin/practicalExercises/addTestContent/ECommerceDataAnalysisApplication/ECommerceBasicData.vue'
    import ECommerceDataReport
        from '../../../components/admin/practicalExercises/addTestContent/ECommerceDataAnalysisApplication/ECommerceDataReport.vue'
    import ProductService from "../../../components/admin/practicalExercises/addTestContent/ProductService.vue"
    import ECommerceDataReportAnalyze
        from '../../../components/admin/practicalExercises/addTestContent/ECommerceDataAnalysisApplication/ECommerceDataReportAnalyze.vue'
    export default {
        name: "PracticeCreate",
        data() {
            return {
                //实操题表单
                practiceForm: {
                    id: null,
                    name: '',
                    time: 1,
                    module_level_one: [],
                    module_level_one_value: '',
                    module_level_tow: [],
                    module_level_tow_value: '',
                    module_level_three: [],
                    module_level_three_value: '',
                    classOptions: [],
                    classValue: [],
                    taskList: [{"id":1,"index":1,"target":"11"}],
                    taskBackground: '',
                    taskAnalysis: '分析',
                    taskOperation: '操作',
                    scoreList: [],
                },
                keyObj: {
                    label: 'name',
                    value: 'id',
                    children: 'children'
                },
                editClassValue:[],
                //添加任务目标弹窗
                addTaskDialog: false,
                addTaskTitle: '添加任务目标',
                //添加任务目标表单
                addTaskForm: {
                    id: null,
                    index: '',
                    target: ''
                },
                //添加评分标准弹窗
                addScoreDialog: false,
                addScoreTitle: '添加评分标准',
                //添加评分标准表单
                addScoreForm: {
                    id: null,
                    index: '',
                    content: '',
                    require: '',
                    standard: '',
                    score: null
                },
                setting: {
                    menubar: false,
                    plugins: "lists, advlist,emoticons,fullscreen,table,insertdatetime,wordcount,image",
                    toolbar: [
                        'undo redo | styleselect | bold italic underline strikethrough|fontselect fontsizeselect formatselect|alignleft aligncenter alignright|bullist numlist|',
                        'image emoticons forecolor backcolor insertdatetime wordcount|table tabledelete|fullscreen|'
                    ],
                    language: 'zh_CN', //本地化设置
                    height: 300,
                    //上传图片
                    images_upload_handler: function (blobInfo, success, failure, progress) {
                        var xhr, formData;
                        xhr = new XMLHttpRequest();
                        xhr.withCredentials = false;
                        xhr.open('POST', 'operationProblem/uploadTextImg');//后端给的上传图片接口
                        xhr.setRequestHeader("Authorization", localStorage.getItem('adminToken'));

                        xhr.upload.onprogress = function (e) {
                            progress(e.loaded / e.total * 100);
                        }

                        xhr.onload = function () {
                            var json;
                            if (xhr.status == 403) {
                                failure('HTTP Error: ' + xhr.status, {remove: true});
                                return;
                            }
                            if (xhr.status < 200 || xhr.status >= 300) {
                                failure('HTTP Error: ' + xhr.status);
                                return;
                            }
                            json = JSON.parse(xhr.responseText);
                            if (!json || typeof json.location != 'string') {
                                failure('Invalid JSON: ' + xhr.responseText);
                                return;
                            }
                            success(json.location);
                        };

                        xhr.onerror = function () {
                            failure('Image upload failed due to a XHR Transport error. Code: ' + xhr.status);
                        }

                        formData = new FormData();
                        formData.append('img', blobInfo.blob(), blobInfo.filename());
                        xhr.send(formData);
                    }
                },
                //当前选择的模块
                current_module_one: null,
                current_module_tow: null,
                current_module_three: null,
                //获取详情内容
                editData: {}
            }
        },
        computed: {
          cascaderStatus() {
              return this.$route.query.id ? true : false
          }
        },
        components: {
            GoodsText,
            GoodsShoot,
            GoodsVideoShoot,
            softPromote,
            livePromote,
            GoodsRelease,
            MobilePlatform,
            OrderManage,
            HandleCustomerProblem,
            ECommerceCollect,
            ECommerceClean,
            HomeMake,
            DetailPagesMake,
            CustomPageMake,
            ShopRegisterSet,
            OnlineShopStyleDesign,
            PageDesign,
            GoodsVideoDesign,
            SearchEnginePromotion,
            InfoFlowPromotion,
            CommunityPromotion,
            SocialECommerce,
            MobileMarketingAndOperations,
            CommodityPricing,
            ProcurementManagement,
            InventoryManagement,
            DistributionManagement,
            CustomerRelationshipManagement,
            SocialCustomerService,
            ECommerceCollectionAndHandle,
            ECommerceBasicData,
            ECommerceDataReport,
            PageSet,
            ProductService,
            ECommerceDataReportAnalyze
        },
        mounted() {
            this.getOperationProblemExamModuleList();
            this.getCateList();
            if (this.$route.query.id) {
                this.getPracticeDetail();
            }
        },
        methods: {
            //获取试题公共部分详情
            getPracticeDetail() {
                let param = {
                    id: this.$route.query.id
                }
                operationProblemOpDetail(param).then((res) => {
                    this.editData = res.data;
                    this.practiceForm.id = res.data.id;
                    this.practiceForm.name = res.data.op_name;
                    this.current_module_one = res.data.exam_module_id[0];
                    this.current_module_tow = res.data.exam_module_id[1];
                    this.current_module_three = res.data.exam_module_id[2];
                    if (res.data.exam_module_id[0]) {
                        this.practiceForm.module_level_one_value = res.data.exam_module_id[0];
                        this.selectModuleLevelOneValue();
                        if (res.data.exam_module_id[1]) {
                            this.practiceForm.module_level_tow_value = res.data.exam_module_id[1];
                            this.selectModuleLevelTowValue();
                            this.practiceForm.module_level_three_value = res.data.exam_module_id[2];
                        }
                    }
                    this.practiceForm.classValue = res.data.category_id;
                    this.editClassValue = res.data.category_id;
                    this.practiceForm.time = res.data.assess_time;
                    this.practiceForm.taskList = res.data.task_goal;
                    this.practiceForm.taskBackground = res.data.task_background;
                    this.practiceForm.taskAnalysis = res.data.task_analysis;
                    this.practiceForm.taskOperation = res.data.task_operation;
                    this.practiceForm.scoreList = res.data.scoring_criteria;
                }).catch((err) => {
                    console.log(err);
                })
            },
            //获取考试模块列表
            getOperationProblemExamModuleList() {
                operationProblemExamModuleList().then((res) => {
                    this.practiceForm.module_level_one = res.data;
                }).catch((err) => {
                    console.log(err);
                });
            },
            handleInput(val){
                let len = val.length
                if(len < 3){
                    this.$message.error('名称长度在 3 到 50 个字符')
                    this.practiceForm.name = ''
                    this.$refs.practiceFormName.$el.classList.add('isError')
                } else {
                    this.$refs.practiceFormName.$el.classList.remove('isError')
                }
            },
            selectModule(val) {
                let list = this.$refs.moduleList.getCheckedNodes()
                let moduleId = list[0].data.module_id
                console.log('moduleId', moduleId)
                if (this.current_module_three && this.current_module_three !== moduleId) {
                    this.$confirm(`确认切换模块吗？切换后，当前所设置的模板数据将被清空!`, '提示', {
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                        customClass: 'green-message-box',
                        type: 'warning'
                    }).then(() => {
                        this.clearPracticeForm();
                        this.practiceForm.module_level_three_value = moduleId
                        this.practiceForm.classValue = val
                    }).catch(() => {
                        this.$message({
                            type: 'info',
                            message: '已取消操作'
                        });
                        this.practiceForm.classValue = this.editClassValue
                    })
                } else {
                    this.practiceForm.module_level_three_value = moduleId
                }
              // console.log('moduleId:', this.practiceForm.module_level_three_value);
            },
            //选择考试模块一级
            selectModuleLevelOneValue() {
                if (this.current_module_one && this.current_module_one !== this.practiceForm.module_level_one_value) {
                    this.$confirm(`确认切换模块吗？切换后，当前所设置的模板数据将被清空!`, '提示', {
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                        customClass: 'green-message-box',
                        type: 'warning'
                    }).then(() => {
                        this.clearPracticeForm();
                        this.practiceForm.module_level_tow_value = '';
                        this.practiceForm.module_level_tow = [];
                        this.practiceForm.module_level_three_value = '';
                        this.practiceForm.module_level_three = [];
                    }).catch(() => {
                        this.$message({
                            type: 'info',
                            message: '已取消操作'
                        });
                        this.practiceForm.module_level_one_value = this.current_module_one;
                    })
                } else {
                    this.practiceForm.module_level_one.forEach(item => {

                        if (this.practiceForm.module_level_one_value === item.id) {
                            if (item.children) {
                                this.practiceForm.module_level_tow = item.children;
                            } else {
                                this.practiceForm.module_level_tow = [];
                            }
                        }
                    })
                    this.practiceForm.module_level_tow_value = '';
                    this.practiceForm.module_level_three_value = '';
                }

            },
            //选择考试模块二级
            selectModuleLevelTowValue() {
                if (this.current_module_tow && this.current_module_tow !== this.practiceForm.module_level_tow_value) {
                    this.$confirm(`确认切换模块吗？切换后，当前所设置的模板数据将被清空!`, '提示', {
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                        customClass: 'green-message-box',
                        type: 'warning'
                    }).then(() => {
                        this.clearPracticeForm();
                        this.practiceForm.module_level_three_value = '';
                    }).catch(() => {
                        this.$message({
                            type: 'info',
                            message: '已取消操作'
                        });
                        this.practiceForm.module_level_tow_value = this.current_module_tow;
                    })
                } else {
                    this.practiceForm.module_level_tow.forEach(towItem => {
                        if (towItem.id === this.practiceForm.module_level_tow_value) {
                            this.practiceForm.module_level_three = towItem.children;
                        }
                    })
                    this.practiceForm.module_level_three_value = '';
                }
            },
            //选择考试模块三级
            selectModuleLevelThreeValue(val) {
                if (this.current_module_three && this.current_module_three !== this.practiceForm.module_level_three_value) {
                    this.$confirm(`确认切换模块吗？切换后，当前所设置的模板数据将被清空!`, '提示', {
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                        customClass: 'green-message-box',
                        type: 'warning'
                    }).then(() => {
                        this.clearPracticeForm();
                    }).catch(() => {
                        this.$message({
                            type: 'info',
                            message: '已取消操作'
                        });
                        this.practiceForm.module_level_three_value = this.current_module_three;
                    })
                }
            },
            //清除表单
            clearPracticeForm() {
                this.practiceForm.name = '';
                this.practiceForm.time = 1;
                this.practiceForm.classValue = [];
                this.practiceForm.taskList = [];
                this.practiceForm.taskBackground = '';
                this.practiceForm.taskAnalysis = '';
                this.practiceForm.taskOperation = '';
                this.practiceForm.scoreList = [];
                this.$refs.taskBackground.setContent("");
                this.$refs.taskAnalysis.setContent("");
                this.$refs.taskOperation.setContent("");
            },
            //获取分类列表
            getCateList() {
                let param = {
                    // type: '4'
                    paging: "0"
                }
                getActualCateData(param).then((res) => {
                    // this.clearEmptyCate(res.data);
                    this.practiceForm.classOptions = res.data;
                }).catch((err) => {
                    console.log(err);
                });
            },
            clearEmptyCate(arr) {
                arr.forEach(item => {
                    item.children.length <= 0 ? this.$delete(item, 'children') : this.clearEmptyCate(item.children);
                })
            },
            //添加任务目标
            addTask() {
                this.addTaskDialog = true;
                this.addTaskTitle = '添加任务目标';
                if (this.practiceForm.taskList.length > 0) {
                    this.addTaskForm.index = this.practiceForm.taskList.length + 1;
                }
            },
            //编辑任务目标
            editTask(row) {
                this.addTaskTitle = '编辑任务目标';
                this.addTaskDialog = true;
                this.addTaskForm.id = row.id;
                this.addTaskForm.index = row.index;
                this.addTaskForm.target = row.target;
            },
            //删除任务目标
            delTask(row) {
                this.$confirm(`是否删除，删除将无法恢复`, '删除', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    customClass: 'green-message-box',
                    type: 'warning'
                }).then(() => {
                    this.practiceForm.taskList.forEach((item, index) => {
                        if (item.id === row.id) {
                            this.practiceForm.taskList.splice(index, 1)
                        }
                    })
                }).catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消删除'
                    });
                })
            },
            //关闭任务目标弹窗
            handleCloseTaskDialog() {
                this.addTaskDialog = false;
                this.addTaskForm.id = null;
                this.addTaskForm.index = '';
                this.addTaskForm.target = '';
            },
            //保存任务目标
            saveAddTask() {
                if (!this.addTaskForm.target) {
                    this.$message.warning('请填写任务目标！');
                    return;
                }
                if (this.addTaskForm.id) {
                    this.practiceForm.taskList.forEach(item => {
                        if (item.id === this.addTaskForm.id) {
                            item.id = this.addTaskForm.id;
                            item.index = this.addTaskForm.index;
                            item.target = this.addTaskForm.target;
                        }
                    })
                } else {
                    this.practiceForm.taskList.push({
                        id: this.practiceForm.taskList.length + 1,
                        index: this.addTaskForm.index,
                        target: this.addTaskForm.target
                    })
                }
                this.addTaskDialog = false;
            },
            //添加评分标准
            addScore() {
                this.addScoreDialog = true;
                this.addScoreTitle = '添加评分标准';
                if (this.practiceForm.scoreList.length > 0) {
                    this.addScoreForm.index = this.practiceForm.scoreList.length + 1;
                }
            },
            //编辑评分标准
            editScore(row) {
                this.addScoreTitle = '编辑评分标准';
                this.addScoreDialog = true;
                this.addScoreForm.id = row.id;
                this.addScoreForm.index = row.index;
                this.addScoreForm.content = row.content;
                this.addScoreForm.require = row.require;
                this.addScoreForm.standard = row.standard;
                this.addScoreForm.score = row.score;
            },
            //删除评分标准
            delScore(row) {
                this.$confirm(`是否删除，删除将无法恢复`, '删除', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    customClass: 'green-message-box',
                    type: 'warning'
                }).then(() => {
                    this.practiceForm.scoreList.forEach((item, index) => {
                        if (item.id === row.id) {
                            this.practiceForm.scoreList.splice(index, 1)
                        }
                    })
                }).catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消删除'
                    });
                })
            },
            //关闭评分标准弹窗
            handleCloseScoreDialog() {
                this.addScoreDialog = false;
                this.addScoreForm.id = null;
                this.addScoreForm.index = '';
                this.addScoreForm.content = '';
                this.addScoreForm.require = '';
                this.addScoreForm.standard = '';
                this.addScoreForm.score = null;
            },
            //保存评分标准
            saveAddScore() {
                if (!this.addScoreForm.content) {
                    this.$message.warning('请填写考核内容！');
                    return;
                }
                if (!this.addScoreForm.require) {
                    this.$message.warning('请填写考核要点！');
                    return;
                }
                if (!this.addScoreForm.standard) {
                    this.$message.warning('请填写评分标准！');
                    return;
                }
                if (this.addScoreForm.id) {
                    this.practiceForm.scoreList.forEach(item => {
                        if (item.id === this.addScoreForm.id) {
                            item.id = this.addScoreForm.id;
                            item.index = this.addScoreForm.index;
                            item.content = this.addScoreForm.content;
                            item.require = this.addScoreForm.require;
                            item.standard = this.addScoreForm.standard;
                            item.score = this.addScoreForm.score;
                        }
                    })
                } else {
                    this.practiceForm.scoreList.push({
                        id: this.practiceForm.scoreList.length + 1,
                        index: this.addScoreForm.index,
                        content: this.addScoreForm.content,
                        require: this.addScoreForm.require,
                        standard: this.addScoreForm.standard,
                        score: this.addScoreForm.score
                    })
                }
                this.addScoreDialog = false;
            },
        }
    }
</script>

<style scoped lang="scss">
    .content-body {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;

        ::v-deep .el-scrollbar__wrap {
            overflow-x: hidden;

            .el-scrollbar__view {
                height: 100%;
            }
        }

        .header {
            padding: 0 20px;
        }

        .practice-content {
            background-color: #fff;
            padding: 20px;

            .content-item {
                .item {
                    margin-bottom: 16px;

                    .text {
                        display: inline-block;
                        width: 66px;
                        text-align: right;
                        font-size: 16px;
                        color: #333333;
                        margin-right: 10px;
                    }

                    .name-input {
                        width: 420px;

                        ::v-deep .el-input__inner {
                            border-radius: 2px;
                            text-align: left;
                        }
                    }

                    .practice-select {
                        width: 200px;
                        margin-right: 10px;

                        ::v-deep .el-input__inner {
                            border-radius: 2px;
                        }
                    }

                    .practice-cascader {
                        width: 420px;

                        ::v-deep .el-input__inner {
                            border-radius: 2px;
                        }
                    }
                }

                .title {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    margin-bottom: 15px;
                    font-size: 16px;

                    .left-icon {
                        display: inline-block;
                        width: 4px;
                        height: 16px;
                        background-color: #2DC079;
                        margin-right: 6px;
                        border-radius: 2px;
                    }
                }
            }
        }

        .content-table {
            .text {
                display: inline-block;
                width: 30px;
                height: 30px;
                line-height: 30px;
                background: #E7F6EF;
                border-radius: 50%;
                text-align: center;
            }
        }
    }

    .add-task-dialog {
        .demo-ruleForm {
            .el-form-item {
                display: flex;
                align-items: center;

                ::v-deep .el-form-item__label {
                    margin-bottom: 0;
                }

                ::v-deep .el-form-item__content {
                    width: 1%;
                    flex: 1;
                    margin-left: 10px !important;

                    .el-input-number {
                        width: 100%;
                    }

                    .el-input-number.is-without-controls .el-input__inner {
                        text-align: left;
                    }
                }
            }
        }

        .dialog-footer {
            text-align: center;
        }
    }

    ::v-deep .tox-tinymce {
        flex: 1;
        width: 1%;
    }
</style>
