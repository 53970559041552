<template>
    <div>
        <div class="line-item" style="margin-top: 30px">
            <div class="left">商品类目</div>
            <div class="right">
                <el-cascader filterable v-model="ruleForm.goods_category_id" :props="keyObj" style="width: 360px"
                             :options="goodClassList" @change="changeClass" clearable placeholder="请选择"></el-cascader>
            </div>
        </div>
        <div class="line-item">
            <div class="left">在售商品</div>
            <div class="right">
                <el-button type="primary" @click="distributionGoods">分配商品</el-button>
            </div>
        </div>
        <div class="second-title">
            <span>商品管理</span>
            <el-button type="primary" @click="addGoods">添加商品</el-button>
        </div>
        <el-table :data="ruleForm.goods_data" class="customTable" style="width: 100%;">
            <el-table-column label="商品标题" min-width="150">
                <template slot-scope="scope">
                    <el-select filterable v-model="scope.row.goods_id" placeholder="请选择商品" style="width: 100%"
                               :ref="`goods_id_${scope.$index}`"
                               @change="changeGood($event, scope.row, scope.$index)">
                        <el-option v-for="(item, index) in goodManageList"
                                   :label="item.good_title" :value="item.id"
                                   :disabled="allSelectedGoods.includes(item.id)"
                                   :key="`goodManageList_${index}`"></el-option>
                    </el-select>
                </template>
            </el-table-column>
            <el-table-column label="30天销量" align="center">
                <template slot-scope="scope">
                    <el-input-number class="number-input" v-model="scope.row.month_sales" :precision="0" :controls="false"
                                     :ref="`month_sales_${scope.$index}`"
                                     :min="1" :max="99999999" style="width: 100%"></el-input-number>
                </template>
            </el-table-column>
            <el-table-column label="成本价" align="center">
                <template slot-scope="scope">
                    <el-input-number class="number-input" v-model="scope.row.cost_price" :precision="2" :controls="false"
                                     :ref="`cost_price_${scope.$index}`"
                                     :min="0.01" :max="scope.row.sales_price ? scope.row.sales_price : 99999999.99" style="width: 100%"></el-input-number>
                </template>
            </el-table-column>
            <el-table-column label="市场价" align="center">
                <template slot-scope="scope">
                    <el-input-number class="number-input" v-model="scope.row.market_price" :precision="2" :controls="false"
                                     :ref="`market_price_${scope.$index}`"
                                     :min="scope.row.sales_price ? scope.row.sales_price : 0.01" :max="99999999.99" style="width: 100%"></el-input-number>
                </template>
            </el-table-column>
            <el-table-column label="销售价" align="center">
                <template slot-scope="scope">
                    <el-input-number class="number-input" v-model="scope.row.sales_price" :precision="2" :controls="false"
                                     :ref="`sales_price_${scope.$index}`"
                                     :min="scope.row.cost_price ? scope.row.cost_price : 0.01" :max="scope.row.market_price" style="width: 100%"></el-input-number>
                </template>
            </el-table-column>
            <el-table-column label="得分价格" align="center" width="240">
                <template slot-scope="scope">
                    <el-input-number class="number-input" v-model="scope.row.min_price" :precision="2" :controls="false"
                                     :ref="`min_price_${scope.$index}`"
                                     :min="scope.row.cost_price ? scope.row.cost_price : 0.01"
                                     :max="scope.row.market_price ? scope.row.market_price : 99999999.99" style="width: 100px"></el-input-number>
                    -
                    <el-input-number class="number-input" v-model="scope.row.max_price" :precision="2" :controls="false"
                                     :ref="`max_price_${scope.$index}`"
                                     :min="Math.max(scope.row.cost_price ? scope.row.cost_price : 0.01, scope.row.min_price ? scope.row.min_price : 0.01)"
                                     :max="scope.row.market_price ? scope.row.market_price : 99999999.99" style="width: 100px"></el-input-number>
                </template>
            </el-table-column>
            <el-table-column label="分值" align="center">
                <template slot-scope="scope">
                    <el-input-number class="number-input" v-model="scope.row.score" :precision="1" :controls="false"
                                     :ref="`score_${scope.$index}`"
                                     :min="0.1" :max="100.0" style="width: 100%"></el-input-number>
                </template>
            </el-table-column>
            <el-table-column label="操作" align="center">
                <template slot-scope="scope">
                    <el-link type="danger" :underline="false" @click="delGoodBtn(scope.$index)">删除</el-link>
                </template>
            </el-table-column>
        </el-table>

        <div class="line-item" style="margin: 45px 0 22px; justify-content: center">
            <el-button type="primary" @click="submitForm">保存</el-button>
            <el-button @click="resetForm">取消</el-button>
        </div>

        <el-dialog title="分配商品" :visible.sync="dialogVisible" width="650px" custom-class="custom-dialog"
                   :close-on-click-modal="false" @opened="openedDialog" @close="closeDialog">
            <el-scrollbar class="good-box">
                <el-checkbox-group v-model="ruleForm.goods_id" class="good-checkbox-group">
                    <el-checkbox :label="item.id" v-for="item in productOnSaleList" :disabled="selectedGoodsCheck.includes(item.id)"
                                 :key="`productOnSaleList_${item.id}`">{{item.good_title}}</el-checkbox>
                </el-checkbox-group>
            </el-scrollbar>
            <div slot="footer" style="text-align: center">
                <el-button @click="dialogVisible = false">取消</el-button>
                <el-button type="primary" @click="saveGood" style="margin-left: 30px">确定</el-button>
            </div>
        </el-dialog>

    </div>
</template>

<script>
    import { adminGoodGetCategory, adminGoodListGood, pricing_managementSetting, pricing_managementGetPricing } from '@/utils/apis.js'
    export default {
        props: {
            practiceForm: {
                type: Object,
                default:() => {}
            }
        },
        data() {
            return {
                ruleForm: {
                    goods_category_id: [], // 类目id
                    goods_id: [], // 已选商品id
                    goods_data: [] // 商品列表
                },
                keyObj: {
                    label: 'name',
                    value: 'id',
                    children: 'children'
                },
                goodClassList: [], // 商品类目
                // S 弹窗
                dialogVisible: false,
                productOnSaleList: [], // 根据类目筛选出来的在售商品列表
                // E 弹窗
                allGoodList: [], // 所有商品，得到商品管理——商品标题列表
                goodManageList: [], // 商品管理下拉列表的商品
            }
        },
        computed: {
            allSelectedGoods() {
                return this.ruleForm.goods_data.filter(item => item.goods_id).map(item => item.goods_id)
            },
            selectedGoodsCheck() {
                if (this.productOnSaleList.length > 0 && this.ruleForm.goods_data.length > 0) {
                    let onSaleArr = this.productOnSaleList.map(item => item.id)
                    return this.ruleForm.goods_data.filter(item => onSaleArr.includes(item.goods_id)).map(item => item.goods_id)
                } else {
                    return []
                }
            }
        },
        mounted() {
            this.promiseAll()
        },
        methods: {
            promiseAll() {
                let p1 = this.getGoodsCategory()
                let p2 = this.getAllGoodList()
                Promise.all([p1, p2]).then((response) => {
                    this.goodClassList = response[0].data
                    this.allGoodList = response[1].data
                    if (this.$route.query.id) {
                        this.initDetail()
                    }
                })
            },
            // 编辑获取详情
            initDetail() {
                pricing_managementGetPricing({op_id: this.$route.query.id}).then(res => {
                    this.ruleForm = {
                        goods_category_id: res.data.goods_category_id,
                        goods_id: res.data.goods_id.split(',').map(item => Number(item)),
                        goods_data: JSON.parse(res.data.goods_data),
                    }
                    if (this.ruleForm.goods_category_id.length > 0) {
                        this.getProductOnSaleList()
                    }
                    if (this.ruleForm.goods_id.length > 0 && this.allGoodList.length > 0) {
                        this.goodManageList = this.allGoodList.filter(item => {
                            return this.ruleForm.goods_id.includes(item.id)
                        })
                    }
                }).catch((err) => {})
            },
            // 获取行业分类
            getGoodsCategory() {
                return new Promise((resolve, reject) => {
                    adminGoodGetCategory().then((res) => {
                        resolve(res)
                    }).catch((err) => {
                        reject(err)
                    })
                })
            },
            // 获取所有商品
            getAllGoodList() {
                return new Promise((resolve, reject) => {
                    adminGoodListGood().then((res) => {
                        resolve(res)
                    }).catch((err) => {
                        reject(err)
                    })
                })
            },
            // 选择商品类目筛选在售商品
            changeClass(val) {
                this.ruleForm.goods_id = []
                this.ruleForm.goods_data = []
                this.productOnSaleList = []
                this.getProductOnSaleList()
            },
            // 获取在售商品
            getProductOnSaleList() {
                let params = {
                    category_id: this.ruleForm.goods_category_id[this.ruleForm.goods_category_id.length - 1]
                }
                adminGoodListGood(params).then((res) => {
                    this.productOnSaleList = res.data
                }).catch((err) => {})
            },
            // 分配商品
            distributionGoods() {
                if (this.ruleForm.goods_category_id.length === 0) {
                    return this.$message.warning('请先选择商品类目')
                } else {
                    this.dialogVisible = true
                }
            },
            // 打开弹窗
            openedDialog() {

            },
            // 关闭弹窗
            closeDialog() {
                this.goodsListForm = []
                this.isEdit = null
            },
            // 保存选择的商品
            saveGood() {
                if (this.ruleForm.goods_id.length === 0) {
                    return this.$message.warning('请先分配商品')
                } else {
                    this.goodManageList = this.allGoodList.filter(item => {
                        return this.ruleForm.goods_id.includes(item.id)
                    })
                    this.$message.success('分配商品成功')
                    this.dialogVisible = false
                }
            },
            // 添加商品
            addGoods() {
                if (this.ruleForm.goods_id.length === 0) {
                    return this.$message.warning('请先分配商品')
                } else if (this.ruleForm.goods_id.length === this.ruleForm.goods_data.length) {
                    return this.$message.warning('当前分配的商品数量已经等于商品管理列表数量，请继续分配商品或修改')
                } else {
                    let tmp = {
                        goods_id: null,
                        good_title: '',
                        month_sales: void 0,
                        cost_price: void 0,
                        market_price: void 0,
                        sales_price: void 0,
                        min_price: void 0,
                        max_price: void 0,
                        score: void 0,
                    }
                    this.ruleForm.goods_data.push(tmp)
                }
            },
            // 筛选商品管理的商品
            changeGood(event, row, index) {
                let currentItem = this.goodManageList.find(item => {
                    return item.id === event
                })

                this.$set(this.ruleForm.goods_data, index, {
                    goods_id: currentItem.id,
                    good_title: currentItem.good_title,
                    month_sales: void 0,
                    cost_price: void 0,
                    market_price: Number(currentItem.market_price),
                    sales_price: Number(currentItem.sales_price),
                    min_price: void 0,
                    max_price: void 0,
                    score: void 0,
                })
            },
            // 删除商品
            delGoodBtn(index) {
                this.$confirm('是否删除该商品，删除完将无法恢复', '提示消息', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    customClass: 'green-message-box'
                }).then(() => {
                    this.ruleForm.goods_data.splice(index, 1)
                    this.$message.success('删除成功')
                }).catch(() => {
                    this.$message.info('已取消删除');
                })
            },
            // 保存试题
            submitForm() {
                if (!this.practiceForm.name) {
                    return this.$message.warning('请填写名称！');
                }
                if (!this.practiceForm.time) {
                    return this.$message.warning('请输入考核时间')
                }
                if (this.practiceForm.classValue.length === 0) {
                    return this.$message.warning('请选择分类！');
                }
                if (this.practiceForm.taskList.length === 0) {
                    return this.$message.warning('请添加任务目标！');
                }
                if (!this.practiceForm.taskBackground) {
                    return this.$message.warning('请填写任务背景！');
                }
                if (!this.practiceForm.taskAnalysis) {
                    return this.$message.warning('请填写任务分析！');
                }
                if (!this.practiceForm.taskOperation) {
                    return this.$message.warning('请填写任务操作！');
                }
                if (this.practiceForm.scoreList.length === 0) {
                    return this.$message.warning('请添加评分标准！');
                }

                if (this.ruleForm.goods_category_id.length === 0) {
                    return this.$message.warning('请选择商品类目！');
                }
                if (this.ruleForm.goods_id.length === 0) {
                    return this.$message.warning('请分配商品！');
                }
                if (this.ruleForm.goods_data.length === 0) {
                    return this.$message.warning('请添加商品！');
                }

                this.ruleForm.goods_data.forEach((item, index) => {
                    for (const key in item) {
                        if (key !== 'good_title') {
                            if (!item[key]) {
                                this.$refs[`${key}_${index}`].$el.classList.add('isError')
                            } else {
                                this.$refs[`${key}_${index}`].$el.classList.remove('isError')
                                if (item.min_price < item.cost_price || item.min_price > item.market_price) {
                                    this.$refs[`min_price_${index}`].$el.classList.add('isError')
                                } else {
                                    this.$refs[`min_price_${index}`].$el.classList.remove('isError')
                                }
                                if (item.max_price < item.cost_price || item.max_price > item.market_price) {
                                    this.$refs[`max_price_${index}`].$el.classList.add('isError')
                                } else {
                                    this.$refs[`max_price_${index}`].$el.classList.remove('isError')
                                }
                            }
                        }
                    }
                })
                for (let i = 0; i < this.ruleForm.goods_data.length; i++) {
                    let item = this.ruleForm.goods_data[i]
                    let obj = {
                        goods_id: '商品标题',
                        month_sales: '30天销量',
                        cost_price: '成本价',
                        market_price: '市场价',
                        sales_price: '销售价',
                        min_price: '得分价格最小值',
                        max_price: '得分价格最大值',
                        score: '分值',
                    }
                    for (const key in item) {
                        if (key !== 'good_title') {
                            if (!item[key]) {
                                return this.$message.warning(`${obj[key]}不能为空`)
                            } else {
                                if (item.min_price < item.cost_price || item.min_price > item.market_price) {
                                    return this.$message.warning('成本价<=得分价格最小值<=市场价')
                                }
                                if (item.max_price < item.cost_price || item.max_price > item.market_price) {
                                    return this.$message.warning('成本价<=得分价格最大值<=市场价')
                                }
                            }
                        }
                    }
                }

                let params = {
                    op_name: this.practiceForm.name,
                    exam_module_id: this.practiceForm.module_level_three_value,
                    category_id: this.practiceForm.classValue[this.practiceForm.classValue.length - 1],
                    assess_time: this.practiceForm.time,
                    task_goal: this.practiceForm.taskList,
                    task_background: this.practiceForm.taskBackground,
                    task_analysis: this.practiceForm.taskAnalysis,
                    task_operation: this.practiceForm.taskOperation,
                    scoring_criteria: this.practiceForm.scoreList,

                    goods_category_id: this.ruleForm.goods_category_id[this.ruleForm.goods_category_id.length - 1],
                    // goods_id: this.ruleForm.goods_id,
                    goods_id: this.ruleForm.goods_id.join(','),
                    goods_data: this.ruleForm.goods_data,
                }
                if (this.$route.query.id) {
                    params.id = Number(this.$route.query.id);
                }
                pricing_managementSetting(params).then((res) => {
                    this.$message.success(res.msg)
                    this.$router.push('/practice/index')
                }).catch((err) => {})
            },
            // 重置试题
            resetForm() {
                this.ruleForm = {
                    goods_category_id: [],
                    goods_id: [],
                    goods_data: [],
                }
                this.$emit('update:practiceForm', {
                    ...this.practiceForm,
                    name: '',
                    time: '',
                    module_level_one_value: '',
                    module_level_tow_value: '',
                    module_level_three_value: '',
                    classValue: [],
                    taskList: [],
                    taskBackground: '',
                    taskAnalysis: '',
                    taskOperation: '',
                    scoreList: [],
                })
            },
        }
    }
</script>

<style scoped lang="scss">
    .line-item {
        margin-top: 16px;
        display: flex;
        align-items: center;
        .left {
            width: 66px;
            margin-right: 10px;
            color: #333;
            font-size: 16px;
            text-align: right;
        }
    }
    .second-title {
        margin-top: 40px;
        margin-bottom: 20px;
        font-size: 16px;
        padding-left: 14px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: relative;
        &:before {
            content: '';
            width: 4px;
            height: 16px;
            background: #2DC079;
            position: absolute;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
            border-radius: 2px;
        }
    }
    .good-box {
        height: 285px;
        ::v-deep > .el-scrollbar__wrap {
            overflow-x: hidden;
        }
        .good-checkbox-group {
            height: 100%;
            border: 1px solid #E5E5E5;
            padding: 0 20px 20px 0;
            box-sizing: border-box;
            .el-checkbox {
                margin: 20px 0 0 20px;
                width: calc(33.333333% - 20px);
                ::v-deep {
                    .el-checkbox__label {
                        position: absolute;
                        width: 158px;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                    }
                }
            }
        }
    }
</style>