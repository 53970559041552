<template>
    <div v-if="status">
        <el-button size="mini" @click="handleDownload">下载</el-button>
    </div>
</template>

<script>
export default {
    name: "downloadBtn",
    props: {
        status: Boolean,
        list: Array
    },
    methods: {
        handleDownload() {
            let url = this.list[0].url
            window.open(localStorage.getItem('hosturl') + url)
        }
    }
}
</script>

<style scoped>

</style>